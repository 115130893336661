import React from "react";
import { Box } from "@mui/material";
import TableauDash from "../components/commonComponents/TableauDashboard/TableauDash";
import { useParams } from "react-router-dom";

const TestingNew = () => {
  const { id } = useParams();
  return (
    <Box component="div" sx={{ display: "flex" }}>
      <TableauDash
        vizId="generalKpiViz"
        vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/Monitoring/StoreMonitoring"}
        applyFilter={false}
        // filterConfig={{
        //   id_campaign: {
        //     name: "Id Campaign",
        //     values: [id],
        //   },
        // }}
      />
    </Box>
  );
};

export default TestingNew;
