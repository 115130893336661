import React from "react";
import { Box } from "@mui/material";
import TableauDash from "../components/commonComponents/TableauDashboard/TableauDash";
import { useParams } from "react-router-dom";
import { refreshData } from "../api_calls/TableauVisuals";
import SingleSelectStoreFilter from "../components/commonComponents/Filters/SingleSelectStoreFilter";

const TestingOne = ({ refreshInterval }) => {
  const { id } = useParams();
  React.useEffect(() => {
    // setTimeout(() => {
    //   setLoadCheckoutDash(true);
    // }, 2000);

    const intervalId = setInterval(() => {
      refreshData("liveTabViz");
    }, refreshInterval);
    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [refreshInterval]);
  return (
    <>
      <Box width={300}>
        <SingleSelectStoreFilter fieldName={"Id Store"} usedFor={"retail"} vizId={"liveTabViz"} />
      </Box>
      <Box height={10} />
      <Box component="div" sx={{ display: "flex" }}>
        <TableauDash
          vizId="liveTabViz"
          vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/76_monitoring_qa/Live_monitoring_Impressions"}
          applyFilter={false}
          // filterConfig={{
          //   id_campaign: {
          //     name: "Id Campaign",
          //     values: [id],
          //   },
          // }}
        />
      </Box>
    </>
  );
};

export default TestingOne;
