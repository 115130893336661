import React from "react";
import { Box, Chip, FormControlLabel, FormGroup, IconButton, InputAdornment, Stack, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { CustomTextInput } from "../../../components/commonComponents/TextInputs/style";
import { HtmlTooltip, StyledCheckbox, StyledInfoBox } from "../../../components/commonComponents/StyledComponents/styled";
import { Cancel, Clear, Close, Save } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { assignRoleToUsers, checkUserDetailsFromCookie, checkUserPermissions, fetchAssignableUsers } from "../../../api_calls/userManagement";
import { toast } from "react-toastify";

const AssignUser = ({ onClose, roleInfo }) => {
  const [searchVal, setSearchVal] = React.useState({ value: "" });
  const [assignUserError, setAssignUserError] = React.useState({ error_message: "" });

  let listOfSelectedUsers = roleInfo.assigned_users;
  const [selectedUsers, setSelectedUsers] = React.useState(listOfSelectedUsers);
  const checkUserDetails = checkUserDetailsFromCookie();
  const allowedAccessRights = checkUserPermissions();
  const permissionScope = allowedAccessRights.allowed_access;
  const currentStateData = useSelector((state) => state.userSelections);

  const customerList = typeof currentStateData.assignable_users_list !== "undefined" && roleInfo.deactivated === false ? currentStateData.assignable_users_list : [];
  const cyreenList =
    checkUserDetails.user_detail.user_type === "cyreen" &&
      permissionScope.user_list_can_edit_cyreen === true &&
      permissionScope.user_list_can_delete_cyreen === true &&
      typeof currentStateData.assignable_cyreen_users !== "undefined"
      ? currentStateData.assignable_cyreen_users
      : [];
  let userOptions = [...customerList, ...cyreenList];

  const applyFilter = () => {
    if (userOptions.length !== 0) {
      return userOptions
        .filter((user) => {
          return (
            user.username.toLowerCase().includes(searchVal.value.toLowerCase().toString()) ||
            user.email.toLowerCase().toString().includes(searchVal.value.toLowerCase().toString())
          );
        })
        .map((user) => {
          return {
            id: user.id,
            username: user.username,
            email: user.email,
            workspace_id: user.assigned_company.id,
            roles: user.roles,
          };
        });
    } else {
      return [];
    }
  };

  let userListitems = typeof searchVal.value !== "undefined" && searchVal.value !== "" ? applyFilter() : [];

  // Function to remove selected user when clicking the cross button
  const handleRemoveUser = (user) => {
    if (user.roles.length === 1) {
      setAssignUserError((prevState) => {
        return {
          ...prevState,
          error_message: (
            <>
              Role cannot be removed for <strong>{user.username}</strong>. A user needs to have at least one role. You may
              remove the user in the User List.
            </>
          ),
        };
      });
    } else {
      setSelectedUsers((prev) => prev.filter((u) => u.email !== user.email));
    }
  };

  // Function to clear search input
  const handleClearSearch = () => {
    setSearchVal({ value: "" }); // Clear search input
  };

  const assignSelectedRoleToUsers = async () => {
    assignRoleToUsers(selectedUsers, roleInfo.id).then((info) => {
      if (info.success === true) {
        toast("Role Assigned to Selected Users", { type: "success" });
        onClose();
      }
    });
  };

  React.useEffect(() => {
    setSelectedUsers(listOfSelectedUsers);
    setAssignUserError({ error_message: "" });
    handleClearSearch();
    fetchAssignableUsers(roleInfo.id);
  }, [listOfSelectedUsers]);

  return (
    <>
      <Box height={670}>
        {/* --------- list of selected users ------- */}
        {assignUserError.error_message !== "" && (
          <>
            <StyledInfoBox>
              <Typography variant="body2">{assignUserError.error_message}</Typography>
            </StyledInfoBox>
            <Box height={20} />
          </>
        )}

        {/* ------  information text for brand role type */}
        {roleInfo.role_name === "Brand" && (
          <>
            <StyledInfoBox>
              <Typography variant="body2">Brand users can only be managed in the User List section.</Typography>
            </StyledInfoBox>
            <Box height={20} />
          </>
        )}

        {roleInfo.role_name !== "Brand" && (
          <Box width={350}>
            <CustomTextInput
              fullWidth
              name="assign_search"
              variant="standard"
              placeholder="Search"
              label="Assign User(s)"
              autoComplete="off"
              type={"text"}
              value={searchVal.value}
              onChange={(e) => {
                setSearchVal({ value: e.target.value });
              }}
              InputProps={{
                endAdornment: searchVal && (
                  <InputAdornment position="end">
                    {searchVal.value !== "" && (
                      <IconButton onClick={handleClearSearch} edge="end" size="small">
                        <Clear fontSize="small" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        {/* ============== users listing ============ */}
        <Box height={2} />
        {typeof userListitems !== "undefined" && userListitems.length !== 0 ? (
          <FormGroup sx={{ background: "#f0f0f0", padding: "5px" }}>
            {userListitems
              .filter((item) => {
                return !selectedUsers.some((user) => user.email === item.email);
              })
              .map((opt) => {
                return (
                  <FormControlLabel
                    key={opt.username + "_userItem"}
                    control={
                      <StyledCheckbox
                        sx={{ marginRight: "5px" }}
                        size="small"
                        id={"selectionCheckBoxFor_" + opt.username.replace(/\s+/g, "")}
                        checked={selectedUsers.length !== 0 ? selectedUsers.some((u) => u.email === opt.email) : false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedUsers((prev) => [...prev, opt]); // Add user to selectedUsers
                          } else {
                            setSelectedUsers((prev) => prev.filter((u) => u.email !== opt.email)); // Remove user from selectedUsers
                          }
                        }}
                      />
                    }
                    label={opt.username + " - " + opt.email}
                    sx={{ padding: "5px" }}
                  />
                );
              })}
          </FormGroup>
        ) : <> {
          searchVal.value !== "" && (
            <FormGroup sx={{ background: "#f0f0f0", padding: "5px" }}>
              <Typography variant="body2">No users found</Typography>
            </FormGroup>
          )
        } </>}

        <Box height={10} />
        <Box
          mt={2}
          display="flex"
          flexWrap="wrap"
          gap={1}
          sx={{
            maxHeight: assignUserError.error_message !== "" ? "400px" : "550px", // Limit the height to avoid overflow
            overflowY: "auto", // Enable scrolling when needed
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {selectedUsers.map((user) =>
            roleInfo.role_name === "Brand" ? (
              <Chip
                key={user.email}
                label={user.username + " - " + user.email}
                sx={{
                  backgroundColor: "#f0f0f0",
                  fontSize: "14px",
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                }}
              // size="small"
              />
            ) : (
              <Chip
                key={user.email}
                label={user.username + " - " + user.email}
                onDelete={() => handleRemoveUser(user)}
                deleteIcon={<Cancel aria-label={"delete assigned for " + user.username} />}
                sx={{
                  backgroundColor: "#f0f0f0",
                  fontSize: "14px",
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                }}
              // size="small"
              />
            )
          )}
        </Box>
      </Box>
      {/* -------- save and cancel ------- */}
      {/* <Box height={100} /> */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt="auto"
        sx={{ position: "fixed", bottom: 10 }}
        width={350}
      >
        <Stack direction={"row"} width={"100%"} spacing={10} justifyContent="space-between">
          <CloseButton onClick={onClose} label="Cancel" icon={<Close />} />
          {roleInfo.role_name !== "Brand" && (
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                assignSelectedRoleToUsers();
              }}
              label={"Save"}
              icon={<Save />}
            />
          )}
        </Stack>
      </Box>
    </>
  );
};

export default AssignUser;
