import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { AddShoppingCart, Discount, Edit, IosShare, Download, DisplaySettings } from "@mui/icons-material";
// import { StyledTableCell, StyledStack, IconContainer, HtmlTooltip, HtmlTooltipDisabled } from './StyledComponents';
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import {
  HtmlTooltip,
  HtmlTooltipDisabled,
  IconContainer,
  StyledDarkRedInformationTypography,
  StyledStack,
  ThumbnailText,
  ThumbnailWithText,
} from "../../../components/commonComponents/StyledComponents/styled";
import { checkUserPermissions } from "../../../api_calls/userManagement";

const ActionIcon = ({ condition, availableTitle, notAvailableTitle, icon, onClick, ariaLabelText }) =>
  condition ? (
    <HtmlTooltip title={availableTitle}>
      <IconContainer>
        <a href="javascript:void(0)" onClick={onClick} aria-label={ariaLabelText}>
          {React.cloneElement(icon, { sx: { color: "#AF3241" } })}
        </a>
      </IconContainer>
    </HtmlTooltip>
  ) : availableTitle !== "Report Settings" ? (
    <HtmlTooltipDisabled title={notAvailableTitle}>
      <IconContainer>{React.cloneElement(icon, { sx: { color: "#E6E6E6" } })}</IconContainer>
    </HtmlTooltipDisabled>
  ) : (
    ""
  );

const CampaignActions = ({
  campaign,
  checkEditAccess,
  campaignDetails,
  userInfo,
  userRole,
  checkRequestAccess,
  handlePopoverOpen,
  setCampaignInfo,
  setOpenModal,
  fetchCampaign,
  setDownloadOptions,
  downloadDataOptions,
}) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const allowedAccessRights = checkUserPermissions();
  const permissionScope = allowedAccessRights.allowed_access;

  const isAvailableToList =
    userInfo.current_workspace.name === campaign.retailer_company_name || userInfo.user_type === "cyreen";
  const isEditAccessible = checkEditAccess.success === true && isAvailableToList;
  const isNotScheduledOrCompleted = ![2, 4].includes(campaign.campaign_status_code);
  const isUserAuthorized =
    isEditAccessible &&
    typeof userInfo.current_workspace !== "undefined" &&
    (userInfo.current_workspace.id === campaign.retailer_company_id || userInfo.user_type === "cyreen");
  const isReportShareable =
    isEditAccessible && campaign.campaign_status !== "Scheduled" && campaign.campaign_status_code === 4;
  const isDataDownloadable =
    checkRequestAccess.success === true &&
    isAvailableToList &&
    (campaign.campaign_status !== "Scheduled" || campaign.impression_report_status !== 0);
  const isAdminUser = userInfo.user_type === "cyreen";

  return (
    <StyledTableCell component="td" scope="row" className="text-wrapper">
      <StyledStack direction="row" spacing={2} sx={{ marginTop: "10px" }}>
        {/* ------- manage products (if allowed) -------- */}
        {
          permissionScope.campaign_products_can_edit === true && (
            <ActionIcon
              condition={isEditAccessible}
              availableTitle="Manage analytics products"
              notAvailableTitle="Not Available"
              ariaLabelText={"Manage analytics products for " + campaign.campaign_name}
              icon={<AddShoppingCart />}
              onClick={(e) => {
                e.preventDefault();
                setOpenModal((prevState) => ({ ...prevState, products: true }));
                const productActionItems = isNotScheduledOrCompleted ? ["Add new product", "List products"] : ["List products"];
                // handlePopoverOpen(e, productActionItems);
                setCampaignInfo(campaign);
              }}
            />)
        }

        {/* ------- manage activities (if allowed) -------- */}
        {
          permissionScope.campaign_activities_can_edit === true && (
            <ActionIcon
              condition={isEditAccessible}
              availableTitle="Manage marketing activities"
              notAvailableTitle="Not Available"
              ariaLabelText={"Manage marketing activities for " + campaign.campaign_name}
              icon={<Discount />}
              onClick={(e) => {
                e.preventDefault();
                const activityActionItems = isNotScheduledOrCompleted
                  ? ["Add new activity", "List activities"]
                  : ["List activities"];
                handlePopoverOpen(e, activityActionItems);
                setCampaignInfo(campaign);
              }}
            />
          )
        }

        {/* ------- edit campaign (if allowed) ------- */}
        {
          permissionScope.campaign_list_can_edit === true && (
            <ActionIcon
              condition={isUserAuthorized}
              availableTitle={<Typography variant="body4">Edit</Typography>}
              notAvailableTitle="Not Available"
              ariaLabelText={"edit campaign for " + campaign.campaign_name}
              icon={<Edit />}
              onClick={(e) => {
                e.preventDefault();
                setOpenModal((prevState) => ({ ...prevState, edit_campaign: true }));
                setCampaignInfo(campaign);
              }}
            />
          )
        }

        {/* --------- share campaign (if allowed) -------- */}
        {
          permissionScope.campaign_can_share === true && (
            <ActionIcon
              condition={isReportShareable}
              availableTitle="Share report"
              notAvailableTitle="Not Available"
              icon={<IosShare />}
              onClick={(e) => {
                e.preventDefault();
                fetchCampaign(campaign.campaign_id, "all").then(() => {
                  setOpenModal((prevState) => ({ ...prevState, share_report: true }));
                  setCampaignInfo(campaign);
                });
              }}
            />
          )
        }

        {/* ----------- download data (if allowed) --------- */}
        {
          permissionScope.campaign_can_download === true && (
            <ActionIcon
              condition={isDataDownloadable}
              availableTitle="Download Campaign Data"
              notAvailableTitle="Not Available"
              icon={<Download />}
              onClick={(e) => {
                e.preventDefault();
                setDownloadOptions(true);
                handlePopoverOpen(e, Object.keys(downloadDataOptions));
                setCampaignInfo(campaign);
              }}
            />
          )
        }

        {/* ----------- Report settings (if allowed) -------- */}
        {
          permissionScope.campaign_reports_settings_can_view === true && (
            <ActionIcon
              condition={isAdminUser}
              availableTitle="Report Settings"
              notAvailableTitle="Not Available"
              ariaLabelText={"report settings for " + campaign.campaign_name}
              icon={<DisplaySettings />}
              onClick={(e) => {
                e.preventDefault();
                setOpenModal((prevState) => ({ ...prevState, report_settings: true }));
                setCampaignInfo(campaign);
              }}
            />
          )
        }

      </StyledStack>
    </StyledTableCell>
  );
};

export default CampaignActions;
