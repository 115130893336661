import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { StyledInfoBox, StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";

import { permissionConfig } from "./permissionScopes";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";

import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { Close, Save } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import {
  checkUserDetailsFromCookie,
  checkUserPermissions,
  checkWorkSpaceFromCookies,
  fetchRoles,
  saveUserInformation,
} from "../../../api_calls/userManagement";
import { fetchCompanies } from "../../../api_calls/companyRequests";
import { toast } from "react-toastify";
import { loadCampaigns } from "../../../api_calls/campaignRequests";
import SingleSelect from "../../../components/commonComponents/Selectors/SingleSelect";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";
import GroupedMultiSelect from "../../../components/commonComponents/Selectors/GroupedMultiSelect";

const AddUser = ({ open, onClose, size, usedFor, userInfo, closeModal, cyreenBoolean }) => {
  const restrictedAreas = permissionConfig.scopes;
  const [selectedRolePermissions, setSelectedRolePermissions] = React.useState([]);
  const [userDetails, setUserDetails] = React.useState(userInfo);
  const adduserText = cyreenBoolean === true ? "Add New Cyreen User" : "Add New User";
  const updateuserText = cyreenBoolean === true ? "Update Cyreen User" : "Update User";
  const rolesEndpointIdentifier = cyreenBoolean === true ? "advance" : "standard";
  // fetch user detail from the cookies
  const checkUserDetail = checkUserDetailsFromCookie();
  let userDetailObj = {};
  if (checkUserDetail.success === true) {
    userDetailObj = checkUserDetail.user_detail;
  }
  const [userData, setUserData] = React.useState(userDetailObj);
  // this value is needed to select default company based on logged in user's company
  const checkCurrentWorkSpaces = checkWorkSpaceFromCookies();
  const allowedAccessRights = checkUserPermissions();
  const permissionScope = allowedAccessRights.allowed_access;
  const [selectedCompanyOption, setSelectedCompanyOption] = React.useState({});

  const [fieldError, setFieldError] = React.useState({});

  const currentStateData = useSelector((state) => state.userSelections);
  const roleAndPermissions = typeof currentStateData.role_list !== "undefined" ? currentStateData.role_list : [];

  // set selected company (case: when the company dropdown is not visible, we have select the company by default)
  const defaultCompanySelection =
    usedFor === "update"
      ? typeof userInfo.assigned_company !== "undefined"
        ? { id: userInfo.assigned_company.id, label: userInfo.assigned_company.name }
        : ""
      : checkCurrentWorkSpaces.success === true
        ? { id: checkCurrentWorkSpaces?.current_workspace?.id, label: checkCurrentWorkSpaces?.current_workspace?.name }
        : "";

  // roles list (based on logged in user's permissions)
  const rolesList =
    roleAndPermissions.length !== 0
      ? roleAndPermissions
        .map((roleObj) => {
          const cyreenRole = roleObj.role_cyreen === true ? "Cyreen Roles" : "Customer Roles";
          return { value: roleObj.role_name, label: roleObj.role_name, role_type: cyreenRole };
        })
        .filter((roleName) => {
          if (roleName.role_type === "Cyreen Roles" && permissionScope.user_list_can_edit_cyreen === true) {
            return true;
          } else {
            if (permissionScope.user_list_can_edit_external === true && roleName.label.toLowerCase() !== "brand") {
              // for existing user with brand role, assigning another role won't be possible
              if (usedFor === "update" && userInfo.roles.includes("Brand")) {
                return false;
              } else {
                return true;
              }
            }

            if (permissionScope.user_list_can_edit_brand === true && roleName.label.toLowerCase() === "brand") {
              // for existing user with another role, assigning brand role won't be possible
              if (usedFor === "update" && !userInfo.roles.includes("Brand")) {
                return false;
              } else {
                return true;
              }
            }
          }
        })
      : [];

  const dispatchAction = useDispatch();

  const handleFieldChange = (user_data) => {
    setUserDetails(user_data);
    dispatchAction(updateStateData(user_data));
  };

  React.useEffect(() => {
    fetchCompanies();
    loadCampaigns();
    if (defaultCompanySelection !== "") {

      if (cyreenBoolean === false) {
        setSelectedCompanyOption(defaultCompanySelection);
        const selectedCompanyId = usedFor === "update" ? userInfo.workspace_id : defaultCompanySelection.id
        handleFieldChange({
          ...userDetails,
          selected_company_id: selectedCompanyId,
          user_assigned_company: currentStateData.company_id_option_obj[defaultCompanySelection.id],
        });
        fetchRoles(false, rolesEndpointIdentifier + "/" + selectedCompanyId);
      } else {
        // setSelectedCompanyOption(defaultCompanySelection);
        handleFieldChange({
          ...userDetails,
          selected_company_id: userData.root_company_id,
        });

        fetchRoles(false, rolesEndpointIdentifier + "/" + userData.root_company_id);
      }

    }
    if (usedFor === "update") {
      setSelectedRolePermissions(
        userInfo.roles.map((roleName) => {
          return { value: roleName, label: roleName };
        })
      );
    } else {
      if (rolesList.length === 1) {
        setSelectedRolePermissions([rolesList[0]])
        handleFieldChange({
          ...userDetails,
          roles: [rolesList[0].label],
        });
      }
    }
    dispatchAction(updateStateData({ ...userInfo, cyreen_user: cyreenBoolean }));
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> {usedFor === "update" ? updateuserText : adduserText}</DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            {/* ------- username/first name/last name --------- */}
            <Stack direction={"row"} spacing={4}>
              {/* ------- user name -------- */}
              <TextInputField
                fieldRequired={true}
                readOnlyFlag={usedFor === "update" ? true : false}
                elemId="userName"
                label="User Name"
                fieldName="user_name"
                showlabel={true}
                defaultVal={typeof userDetails.username !== "undefined" ? userDetails.username : ""}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    ...userDetails,
                    username: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.user_name !== "undefined" && fieldError.user_name !== "" ? fieldError.user_name : ""
                }
              />
              {/* --------- email ---------- */}
              <TextInputField
                fieldRequired={true}
                elemId="email"
                readOnlyFlag={usedFor === "update" ? true : false}
                label="Email"
                fieldName="email"
                showlabel={true}
                defaultVal={typeof userDetails.email !== "undefined" ? userDetails.email : ""}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    ...userDetails,
                    email: e.target.value,
                  });
                }}
                fieldError={typeof fieldError.email !== "undefined" && fieldError.email !== "" ? fieldError.email : ""}
              />
              {/* --------- first name ---------- */}
              {/* <TextInputField
                fieldRequired={true}
                elemId="firstName"
                label="First Name"
                fieldName="first_name"
                showlabel={true}
                defaultVal={typeof userDetails.first_name !== "undefined" ? userDetails.first_name : ""}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    ...userDetails,
                    first_name: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.first_name !== "undefined" && fieldError.first_name !== "" ? fieldError.first_name : ""
                }
              /> */}
              {/* --------- last name ---------- */}
              {/* <TextInputField
                fieldRequired={true}
                elemId="lastName"
                label="Last Name"
                fieldName="last_name"
                showlabel={true}
                defaultVal={typeof userDetails.last_name !== "undefined" ? userDetails.last_name : ""}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    ...userDetails,
                    last_name: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.last_name !== "undefined" && fieldError.last_name !== "" ? fieldError.last_name : ""
                }
              /> */}
            </Stack>

            {/* ------------ company -------------- */}
            {cyreenBoolean === false && typeof userData.user_type !== "undefined" && ["cyreen", "branch"].includes(userData.user_type) && (
              <>
                <Box height={40} />
                <Stack direction={"row"} spacing={4}>
                  <SingleSelect
                    enableFullWidth={true}
                    label={"Company*"}
                    readOnlyFlag={typeof userData.user_type !== "undefined" && ["cyreen", "branch"].includes(userData.user_type) ? false : true}
                    selectionCheck={(option, value) => option.label === value.label}
                    sortOptions={false}
                    options={
                      typeof currentStateData.company_name_list !== "undefined" ? currentStateData.company_name_list : []
                    }
                    defaultVal={selectedCompanyOption}
                    onChangeCall={(selectedOption) => {
                      setSelectedCompanyOption(selectedOption);
                      let companyDetailToSave = {
                        ...userDetails,
                        user_assigned_company: currentStateData.company_id_option_obj[selectedOption.id],
                      };
                      // following data only has to be updated/saved when adding a user (NOT UPDATE)
                      if (usedFor !== "update") {
                        companyDetailToSave = {
                          ...userDetails,
                          selected_company_id: selectedOption.id,
                        };
                      }
                      handleFieldChange(companyDetailToSave);
                      fetchRoles(false, rolesEndpointIdentifier + "/" + selectedOption.id);
                    }}
                    fieldError={
                      typeof fieldError.user_company !== "undefined" && fieldError.user_company !== ""
                        ? fieldError.user_company
                        : ""
                    }
                  />
                </Stack>
              </>
            )}

            <Box height={40} />
            {/* ----------- roles ------------ */}
            <Stack direction={"row"} spacing={4}>
              {/* --------- Role --------- */}
              <GroupedMultiSelect
                enableFullWidth={true}
                elemId={"selectRoleDropdown"}
                label={"User role*"}
                disabledIfSelectedOption={["Brand"]}
                showGroupNames={cyreenBoolean === true ? true : false}
                options={
                  rolesList.length !== 0
                    ? (typeof userDetails.cyreen_user !== "undefined" && userDetails.cyreen_user === false) ||
                      cyreenBoolean === false
                      ? rolesList
                        .filter((role) => {
                          return role.role_type !== "Cyreen Roles";
                        })
                        .sort((a, b) => {
                          return a.label.localeCompare(b.label);
                        })
                      : rolesList.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                      })
                    : []
                }
                groupParam={"role_type"}
                selectionCheck={(option, value) => option.label === value.label}
                defaultVal={selectedRolePermissions}
                showSelectedValues={true}
                onChangeCall={(selectedOption) => {
                  setSelectedRolePermissions(selectedOption);
                  handleFieldChange({
                    ...userDetails,
                    roles: selectedOption.map((roleDtl) => {
                      return roleDtl.label;
                    }),
                  });
                }}
                fieldError={
                  typeof fieldError.user_role !== "undefined" && fieldError.user_role !== "" ? fieldError.user_role : ""
                }
              />
            </Stack>
            {/* ----------- selection for retailers and campaigns ---------- */}
            {typeof userDetails.roles !== "undefined" && userDetails.roles.includes("Brand") && (
              <>
                <Box height={40} />
                <Stack direction={"row"} spacing={4}>
                  {/* --------- Campaign Selection --------- */}
                  <MultiSelect
                    label="Select Campaign*"
                    enableFullWidth={true}
                    options={
                      typeof currentStateData.campaign_list !== "undefined" &&
                        typeof currentStateData.campaign_list.report_available_campaigns !== "undefined"
                        ? currentStateData.campaign_list.report_available_campaigns
                        : []
                    }
                    defaultVal={
                      typeof userDetails.brand_campaign_selection !== "undefined"
                        ? userDetails.brand_campaign_selection
                        : []
                    }
                    selectionCheck={(option, value) => option.id === value.id}
                    onChangeCall={(selectedOption) => {
                      let selectedCampaigns = [];
                      selectedOption.forEach((option) => {
                        selectedCampaigns.push(option.id);
                      });

                      handleFieldChange({
                        ...userDetails,
                        campaign_id: selectedCampaigns,
                        brand_campaign_selection: selectedOption,
                      });
                    }}
                  />
                </Stack>
              </>
            )}
            <Box height={40} />

            {/* --------- checkbox to mark new user as cyreen user ----- */}
            {typeof userData.user_type !== "undefined" && userData.user_type === "cyreen" && (
              <>
                <Box height={0} />
                <Stack direction={"row"} spacing={2}>
                  {/* <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={typeof userDetails.user_type !== "undefined" && userDetails.user_type === "cyreen" ? true : false}
                        onChange={(e) => {
                          handleFieldChange({
                            ...userDetails,
                            cyreen_user: e.target.checked,
                          });
                        }}
                      />
                    }
                    label="Cyreen User"
                  /> */}
                  {/* <FormControlLabel
                    control={
                      <StyledSwitch
                        checked={typeof userDetails.cyreen_user !== "undefined" ? userDetails.cyreen_user : cyreenBoolean}
                        name="Cyreen User"
                        onChange={(e) => {
                          handleFieldChange({
                            ...userDetails,
                            cyreen_user: e.target.checked,
                          });
                        }}
                      />
                    }
                    labelPlacement="right"
                    label={"Cyreen User"}
                    sx={{ fontSize: "12px", minWidth: 100 }}
                  /> */}
                </Stack>
              </>
            )}

            {cyreenBoolean === true && (
              <StyledInfoBox>
                <Typography variant="body2">
                  Important: Never add a customer as Cyreen user. This user type is reserved for employees of Cyreen.
                </Typography>
              </StyledInfoBox>
            )}
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton onClick={onClose} label="Cancel" icon={<Close />} />
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              setFieldError({});

              const userId = { id: typeof userDetails.id !== "undefined" ? userDetails.id : "" };
              saveUserInformation(usedFor, userId).then((info) => {
                if (info.success === true) {
                  const message = usedFor === "update" ? "User updated successfully" : "User added successfully";
                  toast(message, { type: "success" });
                  closeModal();
                } else {
                  setFieldError(info.errorMessages);
                  // toast("Sorry, something went wrong", { type: "error" });
                }
              });
            }}
            label={usedFor === "update" ? "Update" : "Save"}
            icon={<Save />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AddUser;
