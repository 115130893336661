// AccessControlHOC.js
import React, { useEffect, useState } from "react";
import { checkUserDetailsFromCookie, pageAccessAllowed } from "../../../api_calls/userManagement";
import { Box, Grid, Typography } from "@mui/material";
import SideNav from "../Sidebar/SideNav";
import { StyledMainBox } from "../StyledComponents/styled";
import forbiddenImage from "../../../images/forbidden/accessForbidden.png";
import { verifyBrowserName } from "../../../api_calls/utils";
import { toast } from "react-toastify";
import { createCookie, fetchBrowserNotificationCookie } from "../../../api_calls/requestConfig";

const AccessControl = (WrappedComponent, permissionsToCheck, allowedUserTypes, sectionCheck) => {
  return function AccessControl(props) {
    const [canAccess, setCanAccess] = useState("checking");
    const browserName = verifyBrowserName();
    const browserCookie = fetchBrowserNotificationCookie();
    const checkUserDetails = checkUserDetailsFromCookie();
    const userType = checkUserDetails.success === true ? checkUserDetails.user_detail.user_type : "";
    let deactivatedSections = checkUserDetails.success === true ? checkUserDetails.user_detail.deactivated_sections : [];

    useEffect(() => {
      const checkAccess = pageAccessAllowed(permissionsToCheck, allowedUserTypes);
      // sectionCheck is an array that needs to be checked for access against deactivatedSections (if any)
      const sectionAccess = typeof sectionCheck !== "undefined" ? userType === "cyreen" || sectionCheck.every(access_name => !deactivatedSections.includes(access_name)) : true;
      // Access control can only be passed if both checkAccess and sectionAccess are true
      if (checkAccess.success && sectionAccess) {
        setCanAccess(true);
      } else {
        setCanAccess(false);
      }
      // setCanAccess(checkAccess.success);

      if (browserName !== "Google Chrome" && browserCookie === "") {
        toast.dismiss();
        toast(
          "CAP Explorer is not optimized for the browser you are currently using, potentially limiting some functionalities and affecting visualizations. For an optimal user experience and performance, we recommend to use Google Chrome.",
          {
            type: "info",
            autoClose: false,
            theme: "colored",
            position: "top-center",
            onClose: () => {
              console.log("closed");
              createCookie("browserNotification", "shown");
            },
          }
        );
      }
    }, [permissionsToCheck]);

    return (
      <Box component="div" sx={{ display: "flex" }}>
        <SideNav />

        {canAccess === true ? (
          <WrappedComponent {...props} />
        ) : (
          <>
            {canAccess === false && (
              <StyledMainBox component="main">
                <Box height={300} />
                <Box height={100} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <img src={forbiddenImage} height={500} width={700} />
                  <Typography variant="h4" color="#AF3241" sx={{ marginBottom: 2 }}>
                    No permission
                  </Typography>
                  {/* <Typography variant="h5" color="#AF3241">
                  Access Denied: You do not have permission to view this page.
                </Typography> */}
                </Box>
              </StyledMainBox>
            )}
          </>
        )}
      </Box>
    );
  };
};

export default AccessControl;
