import React from "react";
import { useSelector } from "react-redux";
import { Box, Stack, FormLabel } from "@mui/material";
import SideNav from "../../../components/commonComponents/Sidebar/SideNav";
import {
  StyledMainBox,
  StickyTopRowStackSpaceBetween,
  StickyTopRowStack,
} from "../../../components/commonComponents/StyledComponents/styled";
import TableauDash from "../../../components/commonComponents/TableauDashboard/TableauDash";
import { checkUserDetailsFromCookie, checkWorkSpaceFromCookies } from "../../../api_calls/userManagement";
import Grid from "@mui/material/Unstable_Grid2";
import DateRangeFilter from "../../../components/commonComponents/Filters/DateRangeFilter";
import { filterRangeValue } from "../../../api_calls/TableauVisuals";
import {
  campaign_names_in_range,
  clearfilters,
  clip_names_in_range,
  fetchCampaign,
  loadCampaigns,
} from "../../../api_calls/campaignRequests";
import ClipNameFilter from "../../../components/commonComponents/Filters/ClipNameFilter";
import CampaignNameFilter from "../../../components/commonComponents/Filters/CampaignNameFilter";
import ShowHideSwitch from "../../../components/commonComponents/switches/ShowHideSwitch";
import ActionButton, { TextButton } from "../../../components/commonComponents/Buttons/ActionButton";
import { useNavigate, useParams } from "react-router-dom";
import { loadClips, resetClipsData } from "../../../api_calls/clipRequests";

import {
  dbFormatDate,
  formatToStandardDate,
  standardFormatToday,
  getFirstDayTwoMonthsAgo,
  findStoreIds,
  storeListOptionsForTableauFilter,
  independentStoreListOptions,
} from "../../../api_calls/utils";
import StoreFilter from "../../../components/commonComponents/Filters/StoreFilter";
import PictureNameFilter from "../../../components/commonComponents/Filters/PictureNameFilter";
import { loadPictures } from "../../../api_calls/pictureRequests";

const PictureOverview = () => {
  // Variables
  const userStateDate = useSelector((state) => state.userSelections);
  const [userInfo, setUserInfo] = React.useState({});

  const firstDayTwoMonthsAgo = formatToStandardDate(getFirstDayTwoMonthsAgo());
  // make it the actual first day of the month instead of the last day of the month before
  firstDayTwoMonthsAgo.setDate(firstDayTwoMonthsAgo.getDate() + 0);

  const today = standardFormatToday();
  const yesterday = formatToStandardDate(today);
  yesterday.setDate(today.getDate() - 1);

  const startDate = firstDayTwoMonthsAgo;
  const navigate = useNavigate();

  React.useEffect(() => {
    const checkUserDetails = checkUserDetailsFromCookie();

    let userDetailObj = {};
    if (checkUserDetails.success === true) {
      userDetailObj = checkUserDetails.user_detail;
    }
    setUserInfo(userDetailObj);

    const fetchData = async () => {
      if (typeof userStateDate.pictures_list === "undefined") {
        await loadPictures();
      }
    };
    fetchData();
    clearfilters();
  }, []);

  // Elements
  return (
    <Box sx={{ display: "flex" }}>
      <SideNav />
      {typeof userStateDate.pictures_list !== "undefined" && typeof userStateDate.pictures_list !== "undefined" ? (
        <StyledMainBox component="main">
          <StickyTopRowStackSpaceBetween direction={"row"} spacing={2}>
            {/* ------- Back to campaigns button ----------- */}
            <Box sx={{ alignContent: "center" }}>
              <TextButton
                onClick={(e) => {
                  // e.preventDefault();
                  navigate("/check-campaigns/pictures");
                }}
                label={"Back to Pictures"}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <Box sx={{ width: 250 }}>
                {/* ------- Clip Name/ clips boolean Filter ----------- */}
                {typeof userStateDate.pic_names_list !== "undefined" && (
                  <PictureNameFilter usedFor={"picture"} fieldName="id_picture" vizId={"pictureOverviewViz"} />
                )}
              </Box>
              <Box sx={{ width: 250 }}>
                {/* ------- Campaign Name Filter ----------- */}
                <StoreFilter
                  fieldName={"Id Store"}
                  usedFor={"overview"}
                  vizId={"pictureOverviewViz"}
                  fetchListFor="independent"
                />
              </Box>
            </Box>
          </StickyTopRowStackSpaceBetween>
          <Box height={1} />
          <Grid container spacing={2}>
            {typeof userInfo.root_company_id !== "undefined" && userInfo.root_company_id !== "" && typeof userStateDate.stores_list !== "undefined" ? (
              <TableauDash
                vizId="pictureOverviewViz"
                vizUrl={
                  "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
                  userInfo.root_company_id +
                  "_master_" +
                  process.env.REACT_APP_ENV_NAME +
                  "/PicturesLive"
                }
                applyFilter={true}
                filterConfig={{
                  store: {
                    name: "Id Store",
                    values: findStoreIds(independentStoreListOptions()),
                  },
                  // clipName: {
                  //   name: "name_clip",
                  //   values: clip_names_in_range().map((clipName) => {
                  //     return clipName.label;
                  //   }),
                  // },
                  // campaignName: {
                  //   name: "name_campaign",
                  //   values: campaign_names_in_range().map((campaignName) => {
                  //     return campaignName.label;
                  //   }),
                  // },
                }}
              />
            ) : (
              ""
            )}
          </Grid>
        </StyledMainBox>
      ) : (
        <Box>
          {typeof userStateDate.loading !== "undefined" && userStateDate.loading === true ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StyledMainBox component="main">
                <p>Please wait while we are loading results...</p>
              </StyledMainBox>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StyledMainBox component="main">
                {typeof userStateDate.clips_list !== "undefined" ? <p>No clips found...</p> : ""}
              </StyledMainBox>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default PictureOverview;
