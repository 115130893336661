import React from "react";
import { Box, Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import { Edit } from "@mui/icons-material";
import { HtmlTooltip, IconContainer, StyledStack } from "../StyledComponents/styled";

/**
 * This component is designed to create flexible and reusable card layouts
 * for rendering content in various React views. It consists of multiple components:
 *
 * - `Cards`: The main container for organizing card content.
 * - `SmallCardContent`: A higher-order component (HOC) for small card sections, suitable for compact information.
 * - `MediumCardContent`: Another HOC for medium-sized cards, ideal for displaying more extensive content.
 */

const styles = {
  mainBody: {
    flexGrow: "1",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "88px 16px",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    background: "rgba(240, 75, 85, 0.05)",
    paddingBottom: "40px",
    borderRadius: " 16px",
    textAlign: "left",
    color: "rgb(0, 75, 80)",
  },
  stackItem: {
    marginBottom: "8px",
    padding: "16px",
  },
  stackText: {
    margin: "0px",
    fontWeight: "600",
    lineHeight: "1.57143",
    fontSize: "0.875rem",
    opacity: "0.64",
  },
};

const Cards = (props) => {
  const { children, ...other } = props;

  return (
    <Grid container spacing={2} {...other}>
      {children}
    </Grid>
  );
};

export const SmallCardContent = (props) => {
  const { children, showEdit, editAction, editIcon, cardIcon, editTooltipText, ...other } = props;
  return (
    <Grid item xs={12} sm={6} md={6} {...other}>
      <Stack spacing={2} style={styles.gridItem}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={styles.stackItem}>{cardIcon}</div>
          {showEdit && (
            <StyledStack direction="row" spacing={2}>
              <HtmlTooltip title={"Edit details"}>
                <IconContainer>
                  <a href="#edit" onClick={editAction} aria-label={editTooltipText}>
                    <Edit sx={{ color: "#AF3241" }} />
                  </a>
                </IconContainer>
              </HtmlTooltip>
            </StyledStack>
          )}
        </div>

        {/* -------- main content for the card --------- */}
        <Box>{children}</Box>
      </Stack>
    </Grid>
  );
};

export const MediumCardContent = (props) => {
  const { children, showHeaderText, headerText, ...other } = props;
  return (
    <Grid item xs={4} {...other}>
      <Card sx={{ height: "60vh", width: "80vh" }} style={styles.card}>
        {/* ------ header text for card ------ */}
        {showHeaderText && (
          <CardHeader
            title={
              <>
                <Typography variant="h6"> {headerText} </Typography>
              </>
            }
          />
        )}
        {/* --------- card content ------- */}
        <CardContent>{children}</CardContent>
      </Card>
    </Grid>
  );
};

export default Cards;
