import { CustomTextInput, CustomTextInputCenterAligned } from "./style";
import {
  HtmlTooltip,
  IconContainer,
  StyledBlackInformationTypography,
  StyledDarkRedInformationTypography,
  StyledInfoBox,
  StyledStack,
} from "../StyledComponents/styled";
import { Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import { defineDevicesList, octetValidation, trackChanges, verifyStoreIP } from "../../../api_calls/infrastructure";
import { Error, Info, Lock, LockOpen } from "@mui/icons-material";
import React from "react";
import { useParams } from "react-router-dom";

const MaskedInput = ({ parentError, errorMessage }) => {
  const userStateDetails = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [fieldError, setFieldError] = React.useState({});
  const [isError, setIsError] = React.useState(false);
  // const lockedFlag = typeof userStateDetails.store_ip_info !== "undefined" ? true : false;
  // const [isLocked, setIsLocked] = React.useState(lockedFlag);
  const { id } = useParams();

  //   const inputRef = useMask({ mask: "10.___.___.0/24", replacement: "_", showMask: true });

  const validateField = async () => {
    setFieldError({});
    setIsError(false);
    const storeIpValidation = await verifyStoreIP(id);
    if (storeIpValidation.error === true) {
      setFieldError(storeIpValidation);
      setIsError(true);
    } else {
      if (typeof userStateDetails.db_store_ip !== "undefined") {
        trackChanges(true);
        defineDevicesList();
      }
    }
  };

  const handleFieldChange = (ipInfo) => {
    setIsError(false);
    setFieldError({});
    const storeIpInfo = typeof userStateDetails.store_ip_info !== "undefined" ? userStateDetails.store_ip_info : {};
    dispatchAction(
      updateStateData({
        store_ip_info: { ...storeIpInfo, ...ipInfo },
      })
    );
  };

  const setIsLocked = (lockedFlag) => {
    dispatchAction(
      updateStateData({
        locked_ip: lockedFlag,
      })
    );
  };

  React.useEffect(() => {
    if (typeof userStateDetails.db_store_ip !== "undefined") {
      if (
        (typeof userStateDetails.db_store_ip.second_octet !== "undefined" &&
          userStateDetails.db_store_ip.second_octet === "") ||
        (typeof userStateDetails.db_store_ip.third_octet !== "undefined" && userStateDetails.db_store_ip.third_octet === "")
      ) {
        setIsLocked(false);
      } else {
        setIsLocked(true);
      }
    }
  }, []);

  return (
    <>
      <Stack direction={"row"} spacing={2}>
        <StyledBlackInformationTypography>Store IP Address:</StyledBlackInformationTypography>
        {/* ------ masked input ------- */}
        <Box width={260}>
          <StyledStack direction={"row"} spacing={1}>
            <strong>10.</strong>{" "}
            <CustomTextInputCenterAligned
              variant="standard"
              name="second_ip_octet"
              type={"text"}
              disabled={typeof userStateDetails.locked_ip !== "undefined" ? userStateDetails.locked_ip : false}
              value={
                typeof userStateDetails.store_ip_info !== "undefined" &&
                typeof userStateDetails.store_ip_info.second_octet !== "undefined"
                  ? userStateDetails.store_ip_info.second_octet
                  : ""
              }
              onChange={(e) => {
                handleFieldChange({
                  second_octet: e.target.value,
                });
              }}
              onBlur={validateField}
            />{" "}
            <strong>.</strong>{" "}
            <CustomTextInputCenterAligned
              variant="standard"
              name="third_ip_octet"
              disabled={typeof userStateDetails.locked_ip !== "undefined" ? userStateDetails.locked_ip : false}
              type={"text"}
              value={
                typeof userStateDetails.store_ip_info !== "undefined" &&
                typeof userStateDetails.store_ip_info.third_octet !== "undefined"
                  ? userStateDetails.store_ip_info.third_octet
                  : ""
              }
              onChange={(e) => {
                handleFieldChange({
                  third_octet: e.target.value,
                });
              }}
              onBlur={validateField}
            />{" "}
            <strong>.0/24</strong>
            {/* ------- error messages --------- */}
            {isError === true && (
              <div style={{ marginTop: "10px" }}>
                <HtmlTooltip title={fieldError.error_message}>
                  <IconContainer>
                    <Error sx={{ color: "#AF3241" }} />
                  </IconContainer>
                </HtmlTooltip>
              </div>
            )}
            {isError === false && typeof parentError !== "undefined" && parentError === true && (
              <div style={{ marginTop: "10px" }}>
                <HtmlTooltip title={errorMessage}>
                  <IconContainer>
                    <Error sx={{ color: "#AF3241" }} />
                  </IconContainer>
                </HtmlTooltip>
              </div>
            )}
            {/* ---------- lock icon -------- */}{" "}
            <div style={{ marginTop: "10px" }}>
              {typeof userStateDetails.locked_ip !== "undefined" && userStateDetails.locked_ip === true ? (
                <IconContainer>
                  <a
                    href="javascript:void(0)"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLocked(!userStateDetails.locked_ip);
                    }}
                  >
                    <Lock sx={{ color: "#AF3241" }} />
                  </a>
                </IconContainer>
              ) : (
                <IconContainer>
                  <a
                    href="javascript:void(0)"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLocked(true);
                      handleFieldChange({
                        second_octet: userStateDetails?.db_store_ip?.second_octet,
                        third_octet: userStateDetails?.db_store_ip?.third_octet,
                      });
                    }}
                  >
                    <LockOpen sx={{ color: "#AF3241" }} />
                  </a>
                </IconContainer>
              )}
            </div>
          </StyledStack>
        </Box>
      </Stack>

      <Box height={5} />

      {typeof userStateDetails.locked_ip !== "undefined" &&
        userStateDetails.locked_ip === false &&
        typeof userStateDetails.db_store_ip !== "undefined" && (
          <StyledDarkRedInformationTypography variant="body2">
            Attention: You are changing the store's IP address. Incorrect modifications will disrupt the system's
            functionality.
          </StyledDarkRedInformationTypography>
        )}
      {/*  */}
      {typeof userStateDetails.db_store_ip === "undefined" && (
        <StyledDarkRedInformationTypography variant="body2">
          You need to specify an IP Address for this store before you can add devices. To successfully save the IP Address,
          ensure at least one device is added for this store.
        </StyledDarkRedInformationTypography>
      )}
    </>
  );
};

export default MaskedInput;
