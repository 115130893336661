import * as React from "react";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/material";

export const CustomTextInputLeftAligned = styled(TextField)({
  color: "#AF3241",
  fontSize: "14px",
  "& .MuiInput-input": {
    color: "#AF3241",
    textAlign: "left",
    fontSize: "14px",
    padding: "0px",
  },
  "& .MuiInputLabel-root": {
    color: "#AF3241",
    fontSize: "14px",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#AF3241",
  },
  "& .MuiInput-underline::before": {
    borderColor: "#AF3241",
  },
  "& .MuiInput-underline::after": {
    borderColor: "#AF3241",
  },
});

// center alinged text intput
export const CustomTextInputCenterAligned = styled(TextField)({
  color: "#AF3241",
  fontSize: "14px",
  "& .MuiInput-input": {
    color: "#AF3241",
    textAlign: "center",
    fontSize: "14px",
    padding: "0px",
  },
  "& .MuiInputLabel-root": {
    color: "#AF3241",
    fontSize: "14px",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#AF3241",
  },
  "& .MuiInput-underline::before": {
    borderColor: "#AF3241",
  },
  "& .MuiInput-underline::after": {
    borderColor: "#AF3241",
  },
});

// Create a styled component for the custom input
export const CustomTextInput = styled(TextField)({
  color: "#AF3241",
  fontSize: "13px",
  "& .MuiInput-input": {
    color: "#AF3241",
  },
  "& .MuiInputLabel-root": {
    color: "#AF3241",
    fontSize: "14px",
  },
  "& .MuiInputLabel-formControl": {
    color: "#AF3241",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#AF3241",
  },
  "& .MuiInput-underline::before": {
    borderColor: "#AF3241",
  },
  "& .MuiInput-underline::after": {
    borderColor: "#AF3241",
  },
});

// styled text area autosize input
export const CustomTextAreaInput = styled(TextareaAutosize)({
  color: "#AF3241",
  fontSize: "13px",
  "& .MuiInput-input": {
    color: "#AF3241",
  },
  "& .MuiInputLabel-root": {
    color: "#AF3241",
    fontSize: "14px",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#AF3241",
  },
  "& .MuiInput-underline::before": {
    borderColor: "#AF3241",
  },
  "& .MuiInput-underline::after": {
    borderColor: "#AF3241",
  },
});

export const CustomWhiteColoredInput = styled(TextField)({
  color: "#fff",
  "& .MuiInput-input": {
    color: "#fff",
  },
  "& .MuiInputLabel-root": {
    color: "#fff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline::before": {
    borderColor: "#fff",
  },
  "& .MuiInput-underline::after": {
    borderColor: "#fff",
  },
});
