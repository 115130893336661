import React, { useEffect } from 'react';
import { generateEmbeddableToken } from '../../api_calls/requestConfig';
import { StyledMainBox } from '../../components/commonComponents/StyledComponents/styled';

const EmbeddableTestDash = ({ embedDashboardId, filterConfig, variables }) => {
    const [token, setToken] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://api.eu.embeddable.com/js/v1/";
        script.type = "module";
        script.async = true;
        document.body.appendChild(script);

        generateEmbeddableToken({
            "embeddableId": embedDashboardId,
            "expiryInSeconds": 604800,
            "securityContext": filterConfig,
            "user": "rim.benabdeljelil@cyreen.de",
            "dashboardState": "team-cyreen"
        }, "POST").then((info) => {
            if (info.success === true) {
                setToken(info.response);
            }
        });


        return () => {
            document.body.removeChild(script);
        };

    }, []);

    return token !== "" ? (
        <StyledMainBox component="main">
            <em-beddable
                token={token}
                variables={JSON.stringify(variables)}
                // onVariablesChange={setVariables}
                onEmbeddableError={(e) => console.log(`${e.errorMessage} : ${e.errorDetail}`)}
                onComponentsLoad={() => setIsLoading(false)}
                clientContext={{ locale: ['en-US'], language: 'en', darkMode: false }} />
        </StyledMainBox>
    ) : (<></>);
}

export default EmbeddableTestDash;