import React from "react";
import { Box, Stack } from "@mui/material";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import { Add } from "@mui/icons-material";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AddNewClip from "./ModalContent/AddClip";
import mediaClip from "../../images/campaign/clip/REWE_Azhari_ZitronenimWasser.mp4";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";

import ClipsListing from "./listings/ClipsListing";
import StoreHierarchy from "./ModalContent/StoreHierarchy";
import { resetClipsData } from "../../api_calls/clipRequests";
import ClipListFilters from "./filters/ClipListFilters";
import { resetBrandReportView } from "../../api_calls/utils";
import { checkUserPermissions } from "../../api_calls/userManagement";

const Clips = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const allowedAccessRights = checkUserPermissions();
  const permissionScope = allowedAccessRights.allowed_access;

  const closeModal = () => {
    resetClipsData();
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  React.useEffect(() => {
    resetBrandReportView();
  }, []);

  return (
    <>
      <StyledMainBox component="main">
        {/* <Box height={30} /> */}
        <h1>Clips</h1>
        {/* ============ chose data fields button =========== */}
        {permissionScope.clip_list_can_edit === true && (
          <Stack direction="row" spacing={2}>
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
              label={"New Clip"}
              icon={<Add />}
            />
          </Stack>
        )}

        {/* --------------- filters ------------- */}
        <Box height={30} />
        <ClipListFilters />
        {/* ============== campaign listing ============ */}
        <Box height={30} />
        <ClipsListing />
      </StyledMainBox>
      {/* ============ modal popup to add clips ========== */}
      {isModalOpen && (
        <AddNewClip open={isModalOpen} onClose={closeModal} size={"md"} closeModal={closeModal} usedFor={"add"} />
      )}
    </>
  );
};

export default AccessControl(Clips, ["clip_list_can_view", "clip_list_can_edit"], [], ["campaigns_section", "clips"]);
