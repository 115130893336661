import React, { useState, useCallback } from "react";
import { Box, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  HtmlTooltip,
  HtmlTooltipDisabled,
  IconContainer,
  StyledBlackInformationTypography,
  StyledDarkRedInformationTypography,
  StyledInformationSpan,
  StyledMainBox,
  StyledStack,
} from "../../../components/commonComponents/StyledComponents/styled";
import UploadButton from "../../../components/commonComponents/FileUpload/UploadButton";
import ActionButton, { DarkGreyButton } from "../../../components/commonComponents/Buttons/ActionButton";
import { CustomTextInput } from "../../../components/commonComponents/TextInputs/style";
import { LeftStyledTableCell, StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import { ArrowDropDown, ArrowDropUp, Delete, Download } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import {
  deleteUploadedFile,
  downloadUploadedFile,
  sortUploadedFilesList,
  uploadDataFiles,
  viewExistingUploads,
} from "../../../api_calls/dataExchange";
import { formatDate, formatToStandardDate } from "../../../api_calls/utils";
import SingleSelect from "../../../components/commonComponents/Selectors/SingleSelect";
import ConfirmationPopup from "../../../components/commonComponents/ConfirmationPopup";
import Confirmation from "../../../components/commonComponents/Confirmation";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { checkUserPermissions } from "../../../api_calls/userManagement";

const UploadData = () => {
  const userStateData = useSelector((state) => state.userSelections);
  const maxFileSize = 50 * 1024 * 1024;
  const minFileInBytes = 6;
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [duplicateFiles, setDuplicateFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fileToDelete, setFileToDelete] = useState("");
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState({ value: "" });
  // check access rights
  const allowedAccessRights = checkUserPermissions();
  const permissionScope = allowedAccessRights.allowed_access;
  // sorting measures for files listing
  const [sortingMeasure, setSortingMeasure] = React.useState({
    fileName: "asc",
    uploadDate: "desc",
    fileSize: "asc",
  });

  // convert bytes into MB
  const convertToMb = (bytesValue) => {
    return (bytesValue / (1024 * 1024)).toFixed(2);
  };

  // set duplicated file details to be shown under rejected list
  const checkDuplicateFile = (newFiles) => {
    let discardedFiles = [];
    const duplicateExists = newFiles.filter((file) => {
      const isDuplicate = acceptedFiles.some((uploadedFile) => uploadedFile.name === file.name);
      if (isDuplicate) {
        discardedFiles.push(file);
      }
      return isDuplicate;
    });

    setDuplicateFiles((existingduplicates) => [...existingduplicates, ...discardedFiles]);

    return duplicateExists.length !== 0 ? true : false;
  };

  // drag drop config
  const { getRootProps, getInputProps } = useDropzone({
    accept: { "text/csv": [] }, // Accept only .csv files"video/mp4": []
    maxSize: maxFileSize, // 50 MB in bytes
    minSize: minFileInBytes,
    onDrop: (newFiles, fileRejections) => {
      // set duplicated file details to be shown under rejected list
      checkDuplicateFile(newFiles);
      // set files in react state for later use
      setAcceptedFiles((prev) => {
        const uniqueFiles = newFiles.filter(
          (file) => !prev.some((existingFile) => existingFile.name === file.name) // Discard new duplicates
        );
        return [...prev, ...uniqueFiles];
      });
      setRejectedFiles((prev) => [...prev, ...fileRejections.map(({ file }) => file)]);
    },
  });

  // filtered/unfiltered file list object
  const existingUploads =
    typeof userStateData.existing_file_upload_list !== "undefined"
      ? typeof searchVal.value !== "undefined"
        ? userStateData.existing_file_upload_list.filter((fileContent) => fileContent.name.includes(searchVal.value))
        : userStateData.existing_file_upload_list
      : [];

  // clear all the accepted/rejected files
  const discardAllFiles = () => {
    setAcceptedFiles([]);
    setRejectedFiles([]);
    setDuplicateFiles([]);
  };

  React.useEffect(() => {
    viewExistingUploads();
  }, []);

  return (
    <>
      <Typography variant="body2" sx={{ fontSize: "15px" }}>
        In this section you can upload receipt files which are required for our data processing of contact-based advertising
        campaigns. Once the receipt file was used for processing data, they are removed from this section. Please make sure
        to use unique file names.
        {/* You may use the
        Receipt Monitoring to track the receipt uploads for earlier periods.  */}
        {/* <Box height={10} /> */}
        {/* <i>For receipt uploads, we only accept .csv files.</i> */}
      </Typography>
      <Box height={20} />
      {/* ------- file upload section -------- */}
      <Stack direction={"row"} spacing={2}>
        <Box
          {...getRootProps({ className: "dropzone" })}
          height={60}
          width={450}
          sx={{
            border: "2px dashed #888",
            padding: "20px",
            textAlign: "center",
            borderRadius: "12px", // Add rounded corners
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              marginTop: "12px",
            }}
          >
            <Typography
              variant="body3"
              sx={{
                margin: "20px",
              }}
            >
              Drag & Drop to Upload File(s) or Click to Browse
            </Typography>
            <Box height={4} />
            <Typography variant="body2">
              <em>(only .csv up to 50 MB)</em>
            </Typography>
          </Box>
        </Box>

        {/* <UploadButton uploadButtonId={"uploadDataFiles"} uploadLabel={"Upload File(s)"} acceptedFileType={"text/csv"} /> */}
      </Stack>

      {/* ------- Accepted files -------- */}
      {acceptedFiles.length > 0 && (
        <Box>
          <h4>Accepted Files</h4>
          <ul>
            {acceptedFiles.map((file, index) => (
              <li key={`${file.name}-${index}`} style={{ marginTop: "5px" }}>
                <Stack direction={"row"} spacing={2}>
                  <Typography variant="body2">
                    {file.name} - {convertToMb(file.size)} MB
                  </Typography>
                </Stack>
              </li>
            ))}
          </ul>
        </Box>
      )}
      {/* ---------- Rejected Files --------- */}
      {(rejectedFiles.length > 0 || duplicateFiles.length !== 0) && (
        <Box>
          <h4>Rejected Files</h4>
          <ul>
            {rejectedFiles.map((file, index) => (
              <li key={`${file.name}-${index}`} style={{ marginTop: "5px" }}>
                <Stack direction={"row"} spacing={2}>
                  <Typography variant="body2">
                    {file.name} - {convertToMb(file.size)} MB :{" "}
                  </Typography>

                  <StyledDarkRedInformationTypography variant="body2">
                    {file.size > maxFileSize
                      ? "File size too large. Please reduce the file size."
                      : file.size < minFileInBytes
                      ? "File is empty. This file will not be uploaded when submitted."
                      : "File format not supported. Please upload only .csv files."}
                  </StyledDarkRedInformationTypography>
                </Stack>
              </li>
            ))}
            {/* duplicated files */}
            {duplicateFiles.map((file, index) => (
              <li key={`${file.name}-${index}`} style={{ marginTop: "5px" }}>
                <Stack direction={"row"} spacing={2}>
                  <Typography variant="body2">
                    {file.name} - {convertToMb(file.size)} MB :{" "}
                  </Typography>

                  <StyledDarkRedInformationTypography variant="body2">
                    {"Duplicate file. This file will not be uploaded when submitted."}
                  </StyledDarkRedInformationTypography>
                </Stack>
              </li>
            ))}
          </ul>
        </Box>
      )}

      {/* --------- discard/submit button for file upload -------- */}
      {(acceptedFiles.length > 0 || rejectedFiles.length > 0) && (
        <Stack direction={"row"} spacing={2} mt={2}>
          <DarkGreyButton
            onClick={(e) => {
              e.preventDefault();
              discardAllFiles();
            }}
            label="Discard File(s)"
            icon={<></>}
          />
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              if (acceptedFiles.length !== 0) {
                uploadDataFiles(acceptedFiles).then(discardAllFiles());
              }
            }}
            label="Submit File(s)"
            icon={<></>}
          />
        </Stack>
      )}

      {/* ------------ existing file upload details ----------- */}
      <Box height={15} />
      <h3>Existing File Uploads</h3>
      {/* ========== search box and receipt monitoring link ========== */}
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box width={300}>
          {/* <SingleSelect
                  enableFullWidth={true}
                  label={"Search"}
                  selectionCheck={(option, value) => option.label === value.label}
                  options={userStateData.existing_file_upload_list.map((fileDtl) => {
                    return { value: fileDtl.name, label: fileDtl.name };
                  })}
                  defaultVal={searchVal}
                  onChangeCall={(selectedOption) => {
                    console.log(selectedOption);
                    setSearchVal(selectedOption);
                  }}
                /> */}
          <CustomTextInput
            fullWidth
            variant="standard"
            placeholder="Search file name"
            label="Search"
            autoComplete="off"
            type={"text"}
            value={searchVal.value}
            onChange={(e) => {
              setSearchVal({ value: e.target.value });
            }}
            onBlur={(e) => {}}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box ml={2}>
            {/* Add margin between the buttons */}
            {/* <ActionButton
              onClick={(e) => {
                e.preventDefault();
                navigate("/reciept-monitoring");
              }}
              label="Receipt Monitoring"
              icon={<></>}
            /> */}
          </Box>
        </Box>
      </Box>
      <Box height={10} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          {/* ------ header row ------ */}
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="text-wrapper">
                #
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                <Stack direction={"row"} spacing={1}>
                  <div>File Name</div>
                  {sortingMeasure.fileName === "asc" ? (
                    <ArrowDropDown
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            fileName: prevState.fileName === "asc" ? "desc" : "asc",
                          };
                        });
                        // sort the listing in redux state
                        sortUploadedFilesList("fileName", sortingMeasure.fileName === "asc" ? "desc" : "asc");
                      }}
                    />
                  ) : (
                    <ArrowDropUp
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            fileName: prevState.fileName === "asc" ? "desc" : "asc",
                          };
                        });
                        // sort the listing in redux state
                        sortUploadedFilesList("fileName", sortingMeasure.fileName === "asc" ? "desc" : "asc");
                      }}
                    />
                  )}
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                <Stack direction={"row"} spacing={1}>
                  <div>Upload Date</div>
                  {sortingMeasure.uploadDate === "asc" ? (
                    <ArrowDropDown
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            uploadDate: prevState.uploadDate === "asc" ? "desc" : "asc",
                          };
                        });
                        // sort the listing in redux state
                        sortUploadedFilesList("uploadDate", sortingMeasure.uploadDate === "asc" ? "desc" : "asc");
                      }}
                    />
                  ) : (
                    <ArrowDropUp
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            uploadDate: prevState.uploadDate === "asc" ? "desc" : "asc",
                          };
                        });
                        // sort the listing in redux state
                        sortUploadedFilesList("uploadDate", sortingMeasure.uploadDate === "asc" ? "desc" : "asc");
                      }}
                    />
                  )}
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                <Stack spacing={1} direction={"row"}>
                  <div>File Size</div>
                  {sortingMeasure.fileSize === "asc" ? (
                    <ArrowDropDown
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            fileSize: prevState.fileSize === "asc" ? "desc" : "asc",
                          };
                        });
                        // sort the listing in redux state
                        sortUploadedFilesList("fileSize", sortingMeasure.fileSize === "asc" ? "desc" : "asc");
                      }}
                    />
                  ) : (
                    <ArrowDropUp
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            fileSize: prevState.fileSize === "asc" ? "desc" : "asc",
                          };
                        });
                        // sort the listing in redux state
                        sortUploadedFilesList("fileSize", sortingMeasure.fileSize === "asc" ? "desc" : "asc");
                      }}
                    />
                  )}
                </Stack>
              </StyledTableCell>
              <StyledTableCell sx={{ width: "200px" }} align="left" className="text-wrapper">
                <StyledStack spacing={1} direction={"row"}>
                  Action
                </StyledStack>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {existingUploads.length !== 0 ? (
              existingUploads.map((fileDetail, index) => {
                return (
                  <TableRow key={"uploaded_files_" + index}>
                    <StyledTableCell component="td" scope="row" className="text-wrapper">
                      <StyledStack direction={"row"}>{index + 1}</StyledStack>
                    </StyledTableCell>
                    {/* --------- name ---------- */}
                    <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                      {fileDetail.name}
                    </StyledTableCell>
                    {/* ----------- Upload date --------- */}
                    <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                      {formatDate(fileDetail.lastModified)}
                    </StyledTableCell>
                    {/* ---------- File Size --------- */}
                    <StyledTableCell component="td" scope="row" className="text-wrapper">
                      {fileDetail.size}
                    </StyledTableCell>

                    {/* -------- Action --------- */}
                    <StyledTableCell component="td" scope="row" className="text-wrapper">
                      <StyledStack direction={"row"} spacing={2}>
                        {/* <StyledSwitch checked={storeLoc.active} /> */}
                        <HtmlTooltip title={"Download"}>
                          <IconContainer>
                            <a
                              href="#downloadFile"
                              onClick={(e) => {
                                e.preventDefault();
                                downloadUploadedFile(fileDetail.name);
                              }}
                            >
                              <Download sx={{ color: "#AF3241", marginTop: "5px" }} />
                            </a>
                          </IconContainer>
                        </HtmlTooltip>
                        {/* ------ delete is visible with access rights only ---- */}
                        {permissionScope.data_exchange_upload_can_delete === true ? (
                          <HtmlTooltip title={"Delete"}>
                            <IconContainer>
                              <a
                                href="#deleteFile"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowConfirmation(true);
                                  setFileToDelete(fileDetail.name);
                                }}
                              >
                                <Delete sx={{ color: "#AF3241", marginTop: "5px" }} />
                              </a>
                            </IconContainer>
                          </HtmlTooltip>
                        ) : (
                          <HtmlTooltipDisabled title={"No Access"}>
                            <IconContainer>
                              <Delete sx={{ color: "#E6E6E6", marginTop: "5px" }} />
                            </IconContainer>
                          </HtmlTooltipDisabled>
                        )}
                      </StyledStack>
                    </StyledTableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <StyledTableCell colSpan={4} component="td" scope="row" className="text-wrapper">
                  No unprocessed receipt files.
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* --------- record count info --------- */}
      <Box mt={2} id="infoText">
        <StyledDarkRedInformationTypography variant="body2">
          Showing {existingUploads.length} out of {existingUploads.length} results.
        </StyledDarkRedInformationTypography>
      </Box>

      {/* ------ confirmation popup for delete file ----- */}
      {showConfirmation === true && (
        <Confirmation
          confirmationActionCall={() => {
            deleteUploadedFile(fileToDelete).then(() => {
              setFileToDelete("");
              setShowConfirmation(false);
            });
          }}
          closePopup={(e) => {
            setShowConfirmation(false);
          }}
          showConfirmationPopup={showConfirmation}
          confirmationTitle={"Delete Uploaded File: " + fileToDelete}
          confirmationMessage={
            <>
              <Typography variant="body2">You are about to delete an already uploaded file.</Typography>
              <Box height={20} />
              <Typography variant="body2">Are you sure you want to permanently delete this file?</Typography>
            </>
          }
        />
      )}
    </>
  );
};

export default UploadData;
