import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { CustomTextInput } from "../TextInputs/style";
import { Typography } from "@mui/material";

export default function TempSingleSelect({
    label,
    defaultVal,
    elemId,
    options,
    onChangeCall,
    fieldError,
    enableFullWidth,
    selectionCheck,
    sortOptions,
    readOnlyFlag,
    fieldInfo,
}) {
    const [selectedValue, setSelectedValue] = React.useState(defaultVal);

    // Use useEffect to update selectedValue when defaultVal changes
    React.useEffect(() => {
        setSelectedValue(defaultVal);
    }, [defaultVal]);

    return (
        <Autocomplete
            id={elemId}
            disableCloseOnSelect={false}
            disableClearable={false}
            disabled={typeof readOnlyFlag !== "undefined" ? readOnlyFlag : false}
            fullWidth={enableFullWidth}
            options={options}
            filterOptions={(options, params) => {
                const filter = createFilterOptions();
                const filtered = filter(options, params);
                let applySorting = typeof sortOptions !== "undefined" ? sortOptions : true;

                if (applySorting === true) {
                    const sortedOptions = filtered.sort((a, b) => {
                        const isANumeric = !isNaN(a.label);
                        const isBNumeric = !isNaN(b.label);
                        if (isANumeric && isBNumeric) {
                            // If both a and b are numeric, sort them numerically
                            return a.label - b.label;
                        } else if (!isANumeric && !isBNumeric) {
                            return a.label.localeCompare(b.label);
                        } else {
                            return isANumeric ? -1 : 1;
                        }
                    });
                    return [...sortedOptions];
                } else {
                    return [...filtered];
                }
            }}
            sx={enableFullWidth ? { maxWidth: "100%" } : { maxWidth: "220px", width: "100%" }}
            value={selectedValue}
            getOptionLabel={(option) => option?.label || ""}
            isOptionEqualToValue={(option, value) => selectionCheck(option, value)}
            
            onChange={(e, selectedOption) => {
                setSelectedValue(selectedOption);
                onChangeCall(selectedOption);
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Typography variant="body2" className="selectorOptions">
                        {option.label}
                    </Typography>
                </li>
            )}
            renderInput={(params) => (
                <CustomTextInput
                    {...params}
                    fullWidth={enableFullWidth}
                    sx={enableFullWidth ? { maxWidth: "100%" } : { maxWidth: "220px", width: "100%" }}
                    label={label}
                    variant="standard"
                    error={fieldError !== "" ? true : false}
                    helperText={fieldError !== "" ? fieldError : ""}
                    InputProps={
                        typeof fieldInfo !== "undefined"
                            ? {
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                        {fieldInfo}
                                    </>
                                ),
                            }
                            : { ...params.InputProps }
                    }
                />
            )}
        />
    );
}
