import React from "react";
import { HtmlTooltip, StyledStack, StyledTablePagination } from "../StyledComponents/styled";
import { ExpandCircleDown } from "@mui/icons-material";
import { TablePagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";

const Pagination = ({ totalCount, reduxStartKeyword, reduxEndKeyword }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    if (newPage < page) {
      dispatchAction(
        updateStateData({
          pagination: {
            [reduxStartKeyword]: currentStateData.pagination[reduxStartKeyword] - rowsPerPage,
            [reduxEndKeyword]: currentStateData.pagination[reduxEndKeyword] - rowsPerPage,
          },
        })
      );
    } else {
      dispatchAction(
        updateStateData({
          pagination: {
            [reduxStartKeyword]: currentStateData.pagination[reduxStartKeyword] + rowsPerPage,
            [reduxEndKeyword]: currentStateData.pagination[reduxEndKeyword] + rowsPerPage,
          },
        })
      );
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatchAction(
      updateStateData({
        pagination: {
          [reduxStartKeyword]: 0,
          [reduxEndKeyword]: parseInt(event.target.value, 10),
        },
      })
    );
  };

  return (
    <StyledTablePagination
      sx={{ paddingLeft: "0px" }}
      component="div"
      count={totalCount}
      variant={"regular"}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPageOptions={[10, 25, 50, 100, 200]}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage="Results per page:"
    />
  );
};

export default Pagination;
