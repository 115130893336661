import React from "react";
import { filterSingleValue } from "../../../api_calls/TableauVisuals";
import SelectBox from "../Selectors/SelectBox";
import { loadStores } from "../../../api_calls/campaigns";
import { useDispatch, useSelector } from "react-redux";
import {
  dbFormatDate,
  findStoreIds,
  formatDate,
  independentStoreListOptions,
  storeListOptionsForTableauFilter,
} from "../../../api_calls/utils";
import { updateStateData } from "../../../Store/actions";
import { checkUserRole } from "../../../api_calls/userManagement";
import MultiSelect from "../Selectors/MultiSelect";

const StoreFilter = ({ vizId, fieldName, usedFor, fetchListFor }) => {
  const storeDetails = useSelector((state) => state.userSelections);
  const [changesMade, setChangesMade] = React.useState(false);
  const [storesSelection, setStoreSelection] = React.useState([]);
  const [storeNameOptionSelected, setStoreNameOptionsSelected] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [defaultValuesLoaded, setDefaultValuesLoaded] = React.useState(false);

  const userRole = storeDetails?.brand_report_view === true ? { user_role: "Brand" } : checkUserRole();
  const dispatchAction = useDispatch();
  let storeList =
    typeof fetchListFor !== "undefined" && fetchListFor === "independent"
      ? independentStoreListOptions()
      : storeListOptionsForTableauFilter(usedFor);

  if (userRole.user_role === "Brand") {
    // check brand filter object to fetch stores for brand (selected by retailer)
    storeList = storeDetails?.brand_filters?.impression_filter?.stores
      ? [...storeDetails.brand_filters.impression_filter.stores].sort()
      : [];
  }

  // to find a min selectable date based on the selected stores
  const findMinSelectableDate = (selectedValues) => {
    let storeDates = [];
    let storeEndDates = [];

    // dates for overview dashboard
    if (typeof storeDetails.store_dates !== "undefined") {
      storeDetails.store_dates.forEach((store) => {
        // overview dates
        if (selectedValues.includes(store.name) && typeof store.start_date !== "undefined" && store.start_date !== null) {
          storeDates = [...storeDates, dbFormatDate(store.start_date)];
          storeEndDates = [...storeDates, dbFormatDate(store.last_date)];
        }
      });
      storeDates = storeDates.sort();
      storeEndDates = storeEndDates.sort();
      const endDateIndex = storeEndDates.length !== 0 ? parseInt(storeEndDates.length - 1) : "";

      dispatchAction(
        updateStateData({
          date_range_start_date: storeDates.length !== 0 ? storeDates[0] : dbFormatDate(new Date()),
          date_range_end_date: storeEndDates.length !== 0 ? storeEndDates[endDateIndex] : dbFormatDate(new Date()),
        })
      );
    }

  };

  React.useEffect(() => {
    const fetchData = async () => {
      await loadStores(usedFor);
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    if (dataLoaded) {
      setStoreSelection(storeList);
      setStoreNameOptionsSelected(
        [...new Set(storeList)].map((storeName) => {
          return { value: storeName, label: storeName };
        })
      );
      findMinSelectableDate(storeList);

      setTimeout(() => {
        setDefaultValuesLoaded(true);
      }, 1000);
    }
  }, [dataLoaded]);

  return defaultValuesLoaded ? (
    <MultiSelect
      enableFullWidth={false}
      label={"Store"}
      options={[...new Set(storeList)].map((storeName) => {
        return { value: storeName, label: storeName };
      })}
      defaultVal={storeNameOptionSelected}
      selectionCheck={(option, value) => option.label === value.label}
      usedFor="storeFilter"
      onChangeCall={(selectedOption) => {
        let userChoices = [];
        setChangesMade(true);
        selectedOption.forEach((chosen) => {
          if (chosen.label !== "(All)") {
            userChoices.push(chosen.label);
          } else {
            userChoices.push([...new Set(storeList)]);
          }
        });
        setStoreSelection(userChoices);
        setStoreNameOptionsSelected(selectedOption);
      }}
      onBlurCall={(e) => {
        if (storesSelection.length !== 0 && changesMade === true) {
          findMinSelectableDate(storesSelection);
          // update - 09-10-2024:  we need to pass store ids to filter the tableau dashboard instead of store names
          // const StoreIds = findStoreIds(storesSelection);
          // filterSingleValue(fieldName, storesSelection, vizId);
          const StoreIds = findStoreIds(storesSelection);

          filterSingleValue(fieldName, StoreIds, vizId);
          // filterSingleValue("id-campaign", StoreIds, vizId);
          setChangesMade(false);
        }
      }}
    />
  ) : (
    <></>
  );
};

export default StoreFilter;
