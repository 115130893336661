import React from "react";
import Cards, { MediumCardContent, SmallCardContent } from "../../../../components/commonComponents/Cards/Cards";
import { Box, Stack, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import GoogleMapReact from "google-map-react";
import { StyledTableCell } from "../../../../components/commonComponents/TabularLists/styles";
import NewLocation from "../../ModalContent/NewLocation";
import { checkDeviceStatus, fetchConfigureLocationData } from "../../../../api_calls/infrastructure";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteItems } from "../../../../Store/actions";
import {
  HtmlTooltip,
  StyledBlackInformationTypography,
  StyledInformationSpan,
} from "../../../../components/commonComponents/StyledComponents/styled";
import { generateRandomNumber, weekDayNum, weekDayOptions } from "../../../../api_calls/utils";

const defaultProps = {
  center: {
    lat: 50.110924,
    lng: 8.682127,
  },
  zoom: 11,
};

const Overview = () => {
  const userStateData = useSelector((state) => state.userSelections);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [editSectionName, setEditSectionName] = React.useState("StoreDetail");

  const dispatchAction = useDispatch();
  const checkDeviceStatusCode = checkDeviceStatus();
  const { id } = useParams();

  // close edit store details popup
  const closeModal = () => {
    dispatchAction(deleteItems(["new_store_loc_data"]));
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    fetchConfigureLocationData(id);
  }, []);
  return (
    <>
      {typeof userStateData.store_overview !== "undefined" &&
        typeof userStateData.store_overview.number_store_retailer !== "undefined" &&
        (userStateData.store_overview.number_store_retailer === "" ||
          userStateData.store_overview.number_store_retailer === null) && (
          <>
            <Box height={15} />
            <StyledInformationSpan sx={{ fontSize: "15px" }}>
              Note: No Retailer Store ID added for this store location. Without this information, no data processing for
              this store location possible.
            </StyledInformationSpan>
            <Box height={20} />
          </>
        )}
      <Cards>
        {typeof userStateData.store_overview !== "undefined" && (
          <>
            {/* --------- store info card ----------- */}
            <SmallCardContent
              showEdit={true}
              editAction={(e) => {
                setIsModalOpen(!isModalOpen);
                setEditSectionName("StoreDetail");
              }}
              editTooltipText={"Edit Store Info"}
              cardIcon={<h3 style={{ color: "#AF3241" }}>Store Information</h3>}
            >
              <TableContainer>
                <Table sx={{ minWidth: "50" }} aria-label="store information">
                  <TableBody>
                    {/* --------- Store Name -------- */}
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Name
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.name}
                      </StyledTableCell>
                    </TableRow>
                    {/*-------- street ---------  */}
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Street & No.
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.address}
                      </StyledTableCell>
                    </TableRow>
                    {/*-------- street ---------  */}
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        ZIP Code
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.zipCode}
                      </StyledTableCell>
                    </TableRow>
                    {/*-------- city ---------  */}
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        City
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.city}
                      </StyledTableCell>
                    </TableRow>
                    {/*-------- Region ---------  */}
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Region
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.region?.name}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Country
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.country?.name}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Owner
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.company?.name}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Opening Hours
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {/* {userStateData?.store_overview?.opening_hours?.description === "24hours/7days" && "Open 24h/7 days"} */}
                        {/* ------- week day timings ------- */}
                        {weekDayOptions.map((dayName) => {
                          return (
                            <Stack direction={"row"} spacing={2} key={dayName.value + "_" + generateRandomNumber(5)}>
                              <Box width={90}>
                                <strong>{dayName.value} :</strong>
                              </Box>
                              <Box width={100}>
                                {userStateData?.store_overview?.opening_hours[dayName.value.toLowerCase()][0] === "00:00" &&
                                userStateData?.store_overview?.opening_hours[dayName.value.toLowerCase()][1] === "00:00"
                                  ? "Closed"
                                  : userStateData?.store_overview?.opening_hours[dayName.value.toLowerCase()][0] ===
                                      "00:05" &&
                                    userStateData?.store_overview?.opening_hours[dayName.value.toLowerCase()][1] === "23:50"
                                  ? "Open 24h"
                                  : userStateData?.store_overview?.opening_hours[dayName.value.toLowerCase()][0] +
                                    " - " +
                                    userStateData?.store_overview?.opening_hours[dayName.value.toLowerCase()][1]}
                              </Box>
                            </Stack>
                          );
                        })}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SmallCardContent>
            {/* -------- store infrastructure ----------- */}
            <SmallCardContent
              showEdit={true}
              editAction={(e) => {
                setIsModalOpen(!isModalOpen);
                setEditSectionName("StoreInfrastructure");
              }}
              editTooltipText={"Edit Store Infra"}
              cardIcon={<h3 style={{ color: "#AF3241" }}>Store Infrastructure</h3>}
            >
              <TableContainer>
                <Table sx={{ minWidth: "50" }} aria-label="store information">
                  <TableBody>
                    {/* --------- store Name -------- */}
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Cyreen Store ID
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.number_store_cyreen}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Retailer Store ID
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.number_store_retailer === null
                          ? "N/A"
                          : userStateData?.store_overview?.number_store_retailer}
                      </StyledTableCell>
                    </TableRow>
                    {/*-------- street ---------  */}
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        IP Address
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <HtmlTooltip title="Store IP Address can be adjusted in the Devices section">
                          {userStateData?.db_store_ip ? (
                            <strong>
                              10.{userStateData?.db_store_ip?.second_octet}.{userStateData?.db_store_ip?.third_octet}.0/24
                            </strong>
                          ) : (
                            <strong>__</strong>
                          )}
                        </HtmlTooltip>
                      </StyledTableCell>
                    </TableRow>
                    {/*-------- street ---------  */}
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Store Status
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {checkDeviceStatusCode === true ? "Active" : "Inactive"}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Data Processing
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.store_status === 1 ? "Active" : "Inactive"}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Checkout Tracking
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.automated === true ? "Automated" : "Manual/No"}
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Equipped Trolleys
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.installed_trolleys}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Equipped Baskets
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.installed_baskets}
                      </StyledTableCell>
                    </TableRow>

                    <TableRow>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        Store Size (in m&sup2;) 
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {userStateData?.store_overview?.store_size}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </SmallCardContent>
            {/* --------- MAP View ---------- */}
            {/* <MediumCardContent>
            <div style={{ height: "57vh", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <div lat={50.096303} lng={8.676329}>
                  Store Name
                </div>
              </GoogleMapReact>
            </div>
          </MediumCardContent> */}
            {/* ============ modal popup for different actions ========== */}
            {isModalOpen && (
              <NewLocation
                open={isModalOpen}
                size={"md"}
                usedFor="update"
                sectionToEdit={editSectionName}
                closeModal={closeModal}
              />
            )}
          </>
        )}
      </Cards>
    </>
  );
};

export default Overview;
