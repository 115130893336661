import React from "react";
import { filterSingleValue } from "../../../api_calls/TableauVisuals";
import SelectBox from "../Selectors/SelectBox";
import { loadStores } from "../../../api_calls/campaigns";
import { useDispatch, useSelector } from "react-redux";
import {
  dbFormatDate,
  findStoreIds,
  independentStoreListOptions,
  storeListOptionsForTableauFilter,
} from "../../../api_calls/utils";
import { updateStateData } from "../../../Store/actions";
import SingleSelect from "../Selectors/SingleSelect";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      fontSize: "12px",
    },
  },
};

const SingleSelectStoreFilter = ({ vizId, fieldName, usedFor, fetchListFor }) => {
  const storeDetails = useSelector((state) => state.userSelections);
  const [storeNameOptionSelected, setStoreNameOptionsSelected] = React.useState({});
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const dispatchAction = useDispatch();
  const [defaultValuesLoaded, setDefaultValuesLoaded] = React.useState(false);

  let storeList =
    typeof fetchListFor !== "undefined" && fetchListFor === "independent"
      ? independentStoreListOptions()
      : storeListOptionsForTableauFilter(usedFor);

  // to find a min selectable date based on the selected stores
  const findMinSelectableDate = (selectedValues) => {
    let storeDates = [];
    let storeEndDates = [];

    // dates for overview dashboard
    storeDetails.store_dates.forEach((store) => {
      // overview dates
      if (selectedValues.includes(store.name) && typeof store.start_date !== "undefined" && store.start_date !== null) {
        storeDates = [...storeDates, dbFormatDate(store.start_date)];
        storeEndDates = [...storeDates, dbFormatDate(store.last_date)];
      }
    });
    storeDates = storeDates.sort();
    storeEndDates = storeEndDates.sort();
    const endDateIndex = storeEndDates.length !== 0 ? parseInt(storeEndDates.length - 1) : "";

    dispatchAction(
      updateStateData({
        date_range_start_date: storeDates.length !== 0 ? storeDates[0] : dbFormatDate(new Date()),
        date_range_end_date: storeEndDates.length !== 0 ? storeEndDates[endDateIndex] : dbFormatDate(new Date()),
      })
    );
  };

  const handleChange = (e) => {
    // setStoreSelection(e.target.value);
    findMinSelectableDate([e.target.value]);
    filterSingleValue(fieldName, [e.target.value], vizId);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await loadStores(usedFor);
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    if (dataLoaded) {
      // setStoreSelection(storeList[0]);
      setStoreNameOptionsSelected({ value: storeList[0], label: storeList[0] });
      findMinSelectableDate([storeList[0]]);
      setTimeout(() => {
        setDefaultValuesLoaded(true);
      }, 1000);
    }
  }, [dataLoaded]);

  return defaultValuesLoaded ? (
    <SingleSelect
      enableFullWidth={true}
      label={"Store"}
      selectionCheck={(option, value) => option.label === value.label}
      options={[...new Set(storeList)].map((storeName) => {
        return { value: storeName, label: storeName };
      })}
      defaultVal={storeNameOptionSelected}
      onChangeCall={(selectedOption) => {
        // setStoreSelection(selectedOption.value);
        findMinSelectableDate([selectedOption.value]);
        setStoreNameOptionsSelected(selectedOption);

        // filterSingleValue(fieldName, [selectedOption.value], vizId);
        const StoreIds = findStoreIds(selectedOption.value);
        filterSingleValue(fieldName, StoreIds, vizId);
      }}
    />
  ) : (
    <></>
  );
};

export default SingleSelectStoreFilter;
