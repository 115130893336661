import React from "react";
import { CSVLink } from "react-csv";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { Download } from "@mui/icons-material";

const DownloadCSV = (props) => {
  const t = useCustomTranslation();
  return (
    <CSVLink
      className="cyreen_btn_light_grey"
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        boxSizing: "border-box",
        margin: "10px",
        cursor: "pointer",
        userSelect: "none",
        verticalAlign: "middle",
        appearance: "none",
        textDecoration: "none",
        fontWeight: "700",
        lineHeight: "1.71429",
        fontSize: "0.875rem",
        textTransform: "unset",
        minWidth: "64px",
        padding: "6px 12px",
        borderRadius: "8px",
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow: "none",
        // backgroundColor: "#AF3241",
        color: "#AF3241",
      }}
      data={props.csvData}
      headers={props.csvHeaders}
      filename={props.filename}
    >
      <Download fontSize="small" sx={{marginRight:"4px"}} /> {t("download_csv_button_text")}
    </CSVLink>
  );
};

export default DownloadCSV;
