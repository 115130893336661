import React from "react";
import { Box } from "@mui/material";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import { StickyTopRowStackSpaceBetween, StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkUserDetailsFromCookie } from "../../api_calls/userManagement";
import { findStoreIds, storeListOptionsForTableauFilter } from "../../api_calls/utils";
import { useSelector } from "react-redux";
import RecieptMonitoringFilter from "../Infrastructure/Dashboards/filters/RecieptMonitoringFilter";
import { loadStores } from "../../api_calls/campaigns";
import { TextButton } from "../../components/commonComponents/Buttons/ActionButton";
import { useNavigate } from "react-router-dom";

const RecieptMonitoring = () => {
  const userStateDate = useSelector((state) => state.userSelections);
  const [userInfo, setUserInfo] = React.useState({});
  let storeListMultiSelect = storeListOptionsForTableauFilter("monitoring");
  const navigate = useNavigate();

  React.useEffect(() => {
    loadStores();
    const checkUserDetails = checkUserDetailsFromCookie();
    let userDetailObj = {};
    if (checkUserDetails.success === true) {
      userDetailObj = checkUserDetails.user_detail;
    }
    setUserInfo(userDetailObj);
  }, []);
  return (
    <StyledMainBox component="main">
      {/* ------------ dashboard from tableau ------------ */}
      {typeof userInfo.root_company_id !== "undefined" && typeof userStateDate.stores_list !== "undefined" && (
        <>
          {/* ---------- filter section ------------- */}
          <StickyTopRowStackSpaceBetween direction={"row"} spacing={2}>
            {/* ------- Back to campaigns button ----------- */}
            <Box sx={{ alignContent: "center" }}>
              <TextButton
                onClick={(e) => {
                  // e.preventDefault();
                  navigate("/data-exchange");
                }}
                label={"Back to Data Exchange"}
              />
            </Box>
            <RecieptMonitoringFilter vizId={"monitoringKpiViz"} />
          </StickyTopRowStackSpaceBetween>

          <Box height={1} />
          <Box>
            <TableauDash
              vizId="monitoringKpiViz"
              vizUrl={
                "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
                userInfo.root_company_id +
                "_master_" +
                process.env.REACT_APP_ENV_NAME +
                "/Receipts_monitoring"
              }
              applyFilter={true}
              filterConfig={{
                status_filter: {
                  name: "Status",
                  values: ["Receipts available but not yet processed", "Receipts not yet received", "Receipts processed"],
                },
                "Id Store": {
                  name: "Id Store",
                  values: findStoreIds(storeListMultiSelect),
                },
              }}
            />
          </Box>
        </>
      )}
    </StyledMainBox>
  );
};
// remove array if all user types are allowed. if only some are allowed, add them like below
export default AccessControl(RecieptMonitoring, ["infra_store_monitoring_can_view"], [], ["infrastructure_section", "monitoring_infra"]);
