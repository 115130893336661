import React from "react";
import { Box } from "@mui/material";
import TableauDash from "../components/commonComponents/TableauDashboard/TableauDash";
import { useParams } from "react-router-dom";
import { filterSingleValue } from "../api_calls/TableauVisuals";

const TestDash = () => {
  const { id } = useParams();
  React.useEffect(() => {
    setTimeout(() => {
      filterSingleValue("Id Store", [18], "generalKpiViz");
    }, 10000);
  }, []);
  return (
    <Box component="div" sx={{ display: "flex" }}>
      <TableauDash
        vizId="generalKpiViz"
        vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/Monitoring/Receipts_monitoring"}
        applyFilter={false}
        filterConfig={{
          id_campaign: {
            name: "Id Store",
            values: [28],
          },
        }}
      />
    </Box>
  );
};

export default TestDash;
