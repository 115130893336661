import React from "react";
import { Box, Stack, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Link } from "@mui/material";
import {
  HtmlTooltip,
  IconContainer,
  StyledDarkRedInformationTypography,
  StyledLeftAlignedStack,
  StyledMainBox,
  StyledStack,
} from "../../../components/commonComponents/StyledComponents/styled";
import { CSVLink } from "react-csv";
import { Close, Download, Help, KeyboardArrowDown, KeyboardArrowUp, Save } from "@mui/icons-material";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import { validate_adlevel_accuracy, validate_adlevel_for_all_checks } from "../../../api_calls/campaignSheet";
import { fetchCampaign, saveCampaignAdlevels } from "../../../api_calls/campaignRequests";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { deleteItems, updateStateData } from "../../../Store/actions";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx"; // For parsing Excel
import AdlevelSheetResults from "../listings/AdlevelSheetResults";
import CommonConfirmation from "../../../components/commonComponents/CommonConfirmation";
import { setLoadingStatus } from "../../../api_calls/requestConfig";
import template from "../productsTemplate/template.xlsx";

const NewProductModal = ({ open, onClose, size, usedFor, closeModal, campaignRecord }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();

  const id = campaignRecord.campaign_id;
  const retailer = campaignRecord.retailer_company_name;
  const campaign_name = campaignRecord.campaign_name;

  const t = useCustomTranslation();
  // =================== new vars =====================
  const [acceptedFiles, setAcceptedFiles] = React.useState([]);
  const [rejectedFiles, setRejectedFiles] = React.useState([]);
  const [loadFileData, setLoadFileData] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const maxFileSize = 50 * 1024 * 1024;
  const minFileInBytes = 6;
  const [changesInProgress, setChangesInProgress] = React.useState(false);
  const [maxErrorCount, setMaxErrorCount] = React.useState(7);
  const [showAllError, setShowAllError] = React.useState(false);

  const handleUploadedData = (uploadedFileData) => {
    let adlevelSheetData = [];
    let dataContainingError = [];
    const existingAdlevelRecords =
      typeof currentStateData.created_adlevels !== "undefined" ? currentStateData.created_adlevels : [];

    setChangesInProgress(true);

    if (uploadedFileData.length !== 0) {
      uploadedFileData.forEach((fileData, index) => {
        const newRecord = {
          record_index: index,
          nameCompany: retailer,
          campaign: id,
          bundleSize: typeof fileData.bundle_size !== "undefined" && fileData.bundle_size !== 0 ? fileData.bundle_size : 1,
          nameAdLevel: fileData.group_name,
          numberAdLevel: fileData.group_number,
          gtin: fileData.gtin,
          description: fileData.description,
        };
        // validate record
        const checkValidity = validate_adlevel_for_all_checks(newRecord);

        if (checkValidity.error === true) {
          dataContainingError = [...dataContainingError, { ...newRecord, error_message: checkValidity.message }];
        } else {
          // validate accuracy
          const checkAccuracy = validate_adlevel_accuracy(newRecord, [
            ...adlevelSheetData,
            // ...dataContainingError,
            ...existingAdlevelRecords,
          ]);

          //================= add accuracy errors to the error object ===================
          if (checkAccuracy.error === true) {
            dataContainingError = [
              ...dataContainingError,
              {
                ...newRecord,
                error_message:
                  typeof checkAccuracy.messagesObj.duplicate !== "undefined" && checkAccuracy.messagesObj.duplicate === true
                    ? "Duplicate record. Check the import file for duplicate rows and check if the record is already added to existing analytics products."
                    : Object.keys(checkAccuracy.messagesObj)
                        .map((errorField) => {
                          return checkAccuracy.messagesObj[errorField];
                        })
                        .join(", "),
              },
            ];
          } else {
            adlevelSheetData = [...adlevelSheetData, newRecord];
          }
        }
      });

      dispatchAction(
        updateStateData({
          adlevel_sheet_data: adlevelSheetData,
          uploaded_sheet_errors: dataContainingError,
        })
      );
      const errorCountToShow =
        dataContainingError.length !== 0 && dataContainingError.length > 7 ? 0 : dataContainingError.length;
      setMaxErrorCount(errorCountToShow);
    }
  };

  const resetUploadedData = () => {
    setAcceptedFiles([]);
    setRejectedFiles([]);
    setChangesInProgress(false);
    dispatchAction(deleteItems(["adlevel_sheet_data", "uploaded_sheet_errors"]));
  };

  // handle file upload
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-excel": [".xls"],
    }, // Accept only .csv files"video/mp4": []
    maxSize: maxFileSize, // 50 MB in bytes
    minSize: minFileInBytes,
    maxFiles: 1, // Allow only one file at a time
    onDrop: (acceptedFiles, fileRejections) => {
      resetUploadedData();
      setLoadingStatus(true); //to show loader screen
      // set files in react state for later use
      setAcceptedFiles((prev) => {
        const uniqueFiles = acceptedFiles.filter(
          (file) => !prev.some((existingFile) => existingFile.name === file.name) // Discard new duplicates
        );
        return [...prev, ...uniqueFiles];
      });
      setRejectedFiles((prev) => [...prev, ...fileRejections.map(({ file }) => file)]);

      const file = acceptedFiles[0];
      if (!file) return;

      const fileExtension = file.name.split(".").pop().toLowerCase();

      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileContent = event.target.result;

        if (fileExtension === "csv") {
          console.log(fileContent)
          // Parse CSV
          Papa.parse(fileContent, {
            delimitersToGuess: [";", ","], // Multiple delimiters allowed
            header: true, // Treat the first row as headers
            skipEmptyLines: true,
            complete: (result) => {
              console.log(result)
              handleUploadedData(result.data);
              setLoadingStatus(false); //to hide loader screen
              // console.log("CSV Data:", result.data);
            },
            error: (err) => {
              setErrorMessage(`Error parsing CSV file: ${err.message}`);
            },
          });
        } else if (fileExtension === "xlsx" || fileExtension === "xls") {
          try {
            // Read Excel
            const workbook = XLSX.read(new Uint8Array(fileContent), { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

            handleUploadedData(sheetData);
            setLoadingStatus(false); //to hide loader screen
            // console.log("Excel Data:", sheetData);
          } catch (err) {
            console.log(err.message);
            setErrorMessage(`Error parsing Excel file: ${err.message}`);
          }
        } else {
          setErrorMessage("Unsupported file format. Please upload a CSV or Excel file.");
        }
      };

      if (fileExtension === "csv") {
        reader.readAsText(file);
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        reader.readAsArrayBuffer(file); // Updated to use ArrayBuffer
      }
    },
  });

  // save uploaded products
  const saveAdlevels = async () => {
    if (
      typeof currentStateData.uploaded_sheet_errors !== "undefined" &&
      currentStateData.uploaded_sheet_errors.length !== 0
    ) {
      toast("Your file contains error, please fix and try again.", { type: "error" });
    } else {
      const saveRecords = await saveCampaignAdlevels(id);
      if (saveRecords.success === true) {
        // check if any error returned from the api
        if (
          typeof saveRecords.api_response !== "undefined" &&
          typeof saveRecords.api_response.response.success !== "undefined" &&
          saveRecords.api_response.response.success === false
        ) {
          toast(saveRecords.api_response.response.error_detail, { type: "error" });
        } else {
          //show success notification
          const message = "Analysis groups added successfully.";
          toast(message, { type: "success" });
          resetUploadedData();
        }
      }
    }
  };

  const csvHeaders = [
    { label: "group_number", key: "group_number" },
    { label: "group_name", key: "group_name" },
    { label: "description", key: "description" },
    { label: "gtin", key: "gtin" },
    { label: "bundle_size", key: "bundle_size" },
    // { label: "id_campaign", key: "campaign" },
    // { label: "name_company", key: "nameCompany" },
  ];

  const csvData = [
    {
      group_number: "1",
      group_name: "test",
      description: "Product 1",
      gtin: "123456781",
      bundle_size: "1",
      // campaign: campaignRecord.campaign_id,
      // nameCompany: retailer,
    },
    {
      group_number: "1",
      group_name: "test",
      description: "Product 2",
      gtin: "123456782",
      bundle_size: "1",
      // campaign: campaignRecord.campaign_id,
      // nameCompany: retailer,
    },
    {
      group_number: "1",
      group_name: "test",
      description: "Product 3",
      gtin: "123456783",
      bundle_size: "1",
      // campaign: campaignRecord.campaign_id,
      // nameCompany: retailer,
    },
    {
      group_number: "1",
      group_name: "test",
      description: "Product 4",
      gtin: "123456784",
      bundle_size: "1",
      // campaign: campaignRecord.campaign_id,
      // nameCompany: retailer,
    },
  ];

  React.useEffect(() => {
    fetchCampaign(campaignRecord.campaign_id, "products");
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> Manage your analytics products</DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            <Typography variant="body2">
              Here you may manage the analytics products of your campaign. You may add analytics products manually or by
              importing a .csv or .xlsx file. When importing a file, please follow the instructions next to the template
              download.
            </Typography>
            <Box height={20} />
            {/* ------- file upload -------- */}
            {typeof currentStateData.campaign_detail !== "undefined" &&
              ![2, 4].includes(currentStateData.campaign_detail.campaign_status_code) && (
                <>
                  <Box
                    {...getRootProps({ className: "dropzone" })}
                    height={60}
                    sx={{
                      border: "2px dashed #888",
                      padding: "20px",
                      textAlign: "center",
                      borderRadius: "12px", // Add rounded corners
                      cursor: "pointer",
                    }}
                  >
                    <input id="productsFile" {...getInputProps()} />
                    <Box
                      sx={{
                        marginTop: "12px",
                      }}
                    >
                      <Typography
                        variant="body3"
                        sx={{
                          margin: "20px",
                        }}
                      >
                        Drag & Drop to Upload File or Click to Browse
                      </Typography>
                      <Box height={4} />
                      <Typography variant="body2">
                        <em>(only .csv, .xlsx, or .xls)</em>
                      </Typography>
                    </Box>
                  </Box>
                  <Box height={20} />
                  <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Box width={300}>
                      {/* ------- Accepted files -------- */}
                      {acceptedFiles.length > 0 && (
                        <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "18px" }}>
                          {typeof currentStateData.uploaded_sheet_errors !== "undefined" &&
                          currentStateData.uploaded_sheet_errors.length !== 0
                            ? "Rejected File"
                            : "Accepted File"}
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex" alignItems="center">
                      {/* ---------------- download template button ----------- */}
                      <Link
                        href={template}
                        download="product_template.xlsx"
                        underline="none"
                        aria-label={"Download products template"}
                        title={"Download Template"}
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                          color: "#AF3241",
                        }}
                      >
                        <Download fontSize="small" title="download csv" /> {"  "} {t("download_template_button_text")}
                        {"  "}
                        {"  "}
                        <HtmlTooltip
                          title={
                            <Box width={250}>
                              <h3>Instructions for imports:</h3>
                              {/* <Box height={5} /> */}

                              {/* <Box height={2} /> */}
                              <Typography variant="body2">
                                <ul style={{ paddingLeft: "5px", marginLeft: "10px" }}>
                                  <li>Column order must match template</li>
                                  <li>Column names must match template</li>
                                  <li>Every product row must have information for all columns</li>
                                </ul>
                              </Typography>
                            </Box>
                          }
                        >
                          <IconContainer>
                            <Help fontSize="small" title="download csv" sx={{ marginTop: "8px" }} />
                          </IconContainer>
                        </HtmlTooltip>
                      </Link>
                    </Box>
                  </Box>
                  {/* ----------- File details ------------ */}
                  <Box height={5} />
                  <Box>
                    <ul style={{ margin: "0px" }}>
                      {acceptedFiles.map((file, index) => (
                        <li key={`${file.name}-${index}`} style={{ marginTop: "5px" }}>
                          <Stack direction={"row"} spacing={2}>
                            <Typography variant="body2">{file.name}</Typography>
                          </Stack>
                        </li>
                      ))}
                      {/* ------- file info ------- */}
                      {typeof currentStateData.adlevel_sheet_data !== "undefined" &&
                        currentStateData.adlevel_sheet_data.length !== 0 && (
                          <li style={{ marginTop: "5px" }}>
                            <Stack direction={"row"} spacing={2}>
                              <Typography variant="body2">Total Records :</Typography>

                              <StyledDarkRedInformationTypography variant="body2">
                                {currentStateData.adlevel_sheet_data.length}
                              </StyledDarkRedInformationTypography>
                            </Stack>
                          </li>
                        )}
                      {/* ------ error info ------- */}
                      {typeof currentStateData.uploaded_sheet_errors !== "undefined" &&
                        currentStateData.uploaded_sheet_errors.length !== 0 && (
                          <li style={{ marginTop: "5px" }}>
                            <Stack direction={"row"} spacing={2}>
                              <Typography variant="body2">Records with error :</Typography>

                              <StyledDarkRedInformationTypography variant="body2">
                                {currentStateData.uploaded_sheet_errors.length}
                              </StyledDarkRedInformationTypography>
                            </Stack>
                          </li>
                        )}
                    </ul>
                  </Box>
                  <Box height={10} />
                  {/* ---------- Rejected Files --------- */}
                  {typeof currentStateData.uploaded_sheet_errors !== "undefined" &&
                    currentStateData.uploaded_sheet_errors.length !== 0 && (
                      <Box>
                        <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "18px" }}>
                          Rejected File Errors
                        </Typography>
                        <Box height={5} />
                        {currentStateData.uploaded_sheet_errors.length > 7 && (
                          <Typography variant="p">
                            <StyledLeftAlignedStack
                              direction={"row"}
                              spacing={1}
                              sx={{ alignItems: "left", fontSize: "14px" }}
                            >
                              <div>Multiple entries are not valid. Expand to see error details.</div>
                              {showAllError === true ? (
                                <KeyboardArrowUp
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setMaxErrorCount(0);
                                    setShowAllError(false);
                                  }}
                                />
                              ) : (
                                <KeyboardArrowDown
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setMaxErrorCount(currentStateData.uploaded_sheet_errors.length);
                                    setShowAllError(true);
                                  }}
                                />
                              )}
                            </StyledLeftAlignedStack>
                          </Typography>
                        )}
                        <ul>
                          {currentStateData.uploaded_sheet_errors.slice(0, maxErrorCount).map((record, index) => (
                            <li key={"error_list_" + index} style={{ marginTop: "5px" }}>
                              <Stack direction={"row"} spacing={1}>
                                <Typography width={100} variant="body2">Record No.: {record.record_index + 2}</Typography>

                                <StyledDarkRedInformationTypography variant="body2">
                                  {record.error_message}
                                </StyledDarkRedInformationTypography>
                              </Stack>
                            </li>
                          ))}
                        </ul>
                      </Box>
                    )}
                  <Box height={5} />
                  {/* --------- Save products button --------- */}
                  {typeof currentStateData.adlevel_sheet_data !== "undefined" &&
                    currentStateData.adlevel_sheet_data.length !== 0 &&
                    typeof currentStateData.uploaded_sheet_errors !== "undefined" &&
                    currentStateData.uploaded_sheet_errors.length === 0 && (
                      <StyledStack direction={"row"} spacing={4}>
                        <ActionButton
                          onClick={(e) => {
                            e.preventDefault();
                            saveAdlevels();
                          }}
                          label={"Save Products"}
                          icon={<Save />}
                        />
                      </StyledStack>
                    )}
                </>
              )}

            {/* ---------- created adlevels will show here ------------ */}
            <Box height={20} />
            <AdlevelSheetResults
              campaignId={id}
              campaign_name={campaign_name}
              retailer={retailer}
              usedFor="existingList"
              closeModal={closeModal}
              trackChangesInProgress={setChangesInProgress}
              controlAcceptedFiles={setAcceptedFiles}
              controlRejectedFiles={setRejectedFiles}
            />
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              if (changesInProgress === true) {
                setShowConfirmation(true);
              } else {
                closeModal();
              }
            }}
            label="Cancel"
            icon={<Close />}
          />
        </Stack>
      </DialogActions>
      {showConfirmation === true && (
        <CommonConfirmation
          confirmationActionCall={() => {
            closeModal();
          }}
          closePopup={(e) => {
            setShowConfirmation(false);
          }}
          confirmTitle="Unsaved Changes"
          showConfirmationPopup={showConfirmation}
          confirmationMessage="You have unsaved changes which will be lost. Are you sure you want to cancel?"
        />
      )}
    </Dialog>
  );
};

export default NewProductModal;
