import React from "react";
import { Box, Tab } from "@mui/material";

import { StyledMainBox, StyledTabs } from "../../components/commonComponents/StyledComponents/styled";

import { fetchUsers, checkUserDetailsFromCookie, fetchRoles, checkUserPermissions } from "../../api_calls/userManagement";
import { fetchCompanies } from "../../api_calls/companyRequests";

import AccessControl from "../../components/commonComponents/Hoc/AccessControl";

import PropTypes from "prop-types";
import UserList from "./tabData/UserList";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={5}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Users = () => {
  const [value, setValue] = React.useState(0);
  const checkUserDetails = checkUserDetailsFromCookie();
  const allowedAccessRights = checkUserPermissions();
  const permissionScope = allowedAccessRights.allowed_access;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let tabsConfig = [];
  // tabs will only be visible to cyreen users
  // user_list_can_edit_cyreen
  // user_list_can_delete_cyreen
  if (
    checkUserDetails.user_detail.user_type === "cyreen" &&
    permissionScope.user_list_can_edit_cyreen === true &&
    permissionScope.user_list_can_delete_cyreen === true
  ) {
    tabsConfig = [
      {
        label: "Customers",
        icon: "",
        unique_key: "CustomersList",
        dashboard: <UserList usedFor="customers" />,
        filter_component: <></>,
      },
      {
        label: "Cyreen",
        icon: "",
        unique_key: "cyreenUsersList",
        dashboard: <UserList usedFor="cyreen" />,
        filter_component: <></>,
      },
    ];
  }

  React.useEffect(() => {
    fetchUsers();
    fetchCompanies();
  }, []);

  return (
    <>
      {/* ========== main component body =========== */}
      <StyledMainBox component="main">
        <Box height={30} />
        <h1>User List</h1>
        {/* ============ user tabs =========== */}
        {checkUserDetails.user_detail.user_type === "cyreen" &&
          permissionScope.user_list_can_edit_cyreen === true &&
          permissionScope.user_list_can_delete_cyreen === true ? (
          <>
            <Box
              sx={{
                display: "flex", // Make the Box a flex container
                position: "sticky",
                top: "0",
                zIndex: 1,
                backgroundColor: "#fff",
              }}
            >
              <Box component={"div"}>
                <StyledTabs value={value} onChange={handleChange} aria-label="data-exchange" textColor={"#F04B55"}>
                  {tabsConfig.map((tabDetail) => {
                    return (
                      <Tab key={tabDetail.unique_key} icon={tabDetail.icon} label={tabDetail.label} {...a11yProps(0)} />
                    );
                  })}
                </StyledTabs>
              </Box>
            </Box>
            {/* ============== tab contents =========== */}
            {tabsConfig.map((tabDetail, index) => {
              return (
                <CustomTabPanel value={value} index={index}>
                  {tabDetail.dashboard}
                </CustomTabPanel>
              );
            })}
          </>
        ) : (
          <UserList usedFor={"customer"} />
        )}
      </StyledMainBox>
    </>
  );
};

export default AccessControl(Users, ["user_list_can_view"], [], ["user_section", "user_list"]);
