import React from "react";
import { StickyTopRowStack } from "../../../../components/commonComponents/StyledComponents/styled";
import { Box } from "@mui/material";
import StoreFilter from "../../../../components/commonComponents/Filters/StoreFilter";

const TotalMonitoringFilter = ({ vizId }) => {
  return (
    <StickyTopRowStack direction={"row"} spacing={4}>
      {/* ------- stores filter ----------- */}
      <Box sx={{ width: 220 }}>
        <StoreFilter fieldName={"Id Store"} usedFor={"receipt-monitoring"} vizId={vizId} fetchListFor="independent" />
      </Box>
    </StickyTopRowStack>
  );
};

export default TotalMonitoringFilter;
