import React from "react";
import { Box, Stack } from "@mui/material";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import { Add, Save } from "@mui/icons-material";
import AddNewPicture from "./ModalContent/AddPicture";
import PicturesListing from "./listings/PicturesListing";
import PictureListFilters from "./filters/PictureListFilters";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import { resetPicturesData } from "../../api_calls/pictureRequests";
import { resetBrandReportView } from "../../api_calls/utils";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkUserPermissions } from "../../api_calls/userManagement";

const Pictures = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const allowedAccessRights = checkUserPermissions();
  const permissionScope = allowedAccessRights.allowed_access;
  const closeModal = () => {
    resetPicturesData();
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  React.useEffect(() => {
    resetBrandReportView();
  }, []);

  return (
    <>
      <StyledMainBox component="main">
        {/* <Box height={30} /> */}
        <h1>Pictures</h1>
        {/* ============ chose data fields button =========== */}
        {permissionScope.picture_list_can_edit === true && (
          <Stack direction="row" spacing={2}>
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
              label={"New Picture"}
              icon={<Add />}
            />
          </Stack>
        )}

        {/* --------------- filters ------------- */}
        <Box height={30} />
        <PictureListFilters />
        {/* ============== pictures listing ============ */}
        <Box height={30} />
        <PicturesListing />
      </StyledMainBox>
      {/* ============ modal popup for different actions ========== */}
      {isModalOpen && (
        <AddNewPicture open={isModalOpen} onClose={closeModal} size={"md"} closeModal={closeModal} usedFor={"add"} />
      )}
    </>
  );
};

export default AccessControl(Pictures, ["picture_list_can_view", "picture_list_can_edit"], [], ["campaigns_section", "pictures"]);
