import React, { useState } from "react";
import { Box, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  HtmlTooltip,
  IconContainer,
  StyledDarkRedInformationTypography,
  StyledInformationSpan,
  StyledMainBox,
  StyledStack,
} from "../../../components/commonComponents/StyledComponents/styled";
import UploadButton from "../../../components/commonComponents/FileUpload/UploadButton";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { CustomTextInput } from "../../../components/commonComponents/TextInputs/style";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import { ArrowDropDown, ArrowDropUp, Delete, Download } from "@mui/icons-material";
import {
  downloadCyreenFile,
  downloadUploadedFile,
  sortCyreenFilesList,
  viewCyreenUploads,
} from "../../../api_calls/dataExchange";
import { formatDate } from "../../../api_calls/utils";

const DownloadData = () => {
  const userStateData = useSelector((state) => state.userSelections);
  const [searchVal, setSearchVal] = useState({ value: "" });
  const [sortingMeasure, setSortingMeasure] = React.useState({
    fileName: "asc",
    uploadDate: "asc",
    fileSize: "asc",
  });
  const sortUploadsList = (sortingParamName, sortingParamValue) => {
    sortCyreenFilesList(sortingParamName, sortingParamValue);
  };

  const cyreenFilesList =
    typeof userStateData.cyreen_file_upload_list !== "undefined"
      ? typeof searchVal.value !== "undefined"
        ? userStateData.cyreen_file_upload_list.filter((fileContent) => fileContent.name.includes(searchVal.value))
        : userStateData.cyreen_file_upload_list
      : [];

  React.useEffect(() => {
    viewCyreenUploads();
  }, []);

  return (
    <>
      <Typography variant="body2" sx={{ fontSize: "15px" }}>
        In this section you can download files provided by Cyreen.
      </Typography>

      <Box height={10} />
      {/* ========== search box and receipt monitoring link ========== */}
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box width={300}>
          <CustomTextInput
            variant="standard"
            fullWidth
            label="Search"
            placeholder="Search file name"
            type={"text"}
            autoComplete="off"
            value={searchVal.value}
            onChange={(e) => {
              setSearchVal({ value: e.target.value });
            }}
            onBlur={(e) => {}}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box ml={2}>
            {/* Add margin between the buttons */}
            {/* <ActionButton
              onClick={(e) => {
                e.preventDefault();
              }}
              label="Receipt Monitoring"
              icon={<></>}
            /> */}
          </Box>
        </Box>
      </Box>
      <Box height={20} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          {/* ------ header row ------ */}
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="text-wrapper">
                #
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                <Stack direction={"row"} spacing={1}>
                  <div>File Name</div>
                  {sortingMeasure.fileName === "asc" ? (
                    <ArrowDropDown
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            fileName: prevState.fileName === "asc" ? "desc" : "asc",
                          };
                        });
                        sortUploadsList("fileName", sortingMeasure.fileName === "asc" ? "desc" : "asc");
                      }}
                    />
                  ) : (
                    <ArrowDropUp
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            fileName: prevState.fileName === "asc" ? "desc" : "asc",
                          };
                        });
                        sortUploadsList("fileName", sortingMeasure.fileName === "asc" ? "desc" : "asc");
                      }}
                    />
                  )}
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                <Stack direction={"row"} spacing={1}>
                  <div>Upload Date</div>
                  {sortingMeasure.uploadDate === "asc" ? (
                    <ArrowDropDown
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            uploadDate: prevState.uploadDate === "asc" ? "desc" : "asc",
                          };
                        });
                        sortUploadsList("uploadDate", sortingMeasure.uploadDate === "asc" ? "desc" : "asc");
                      }}
                    />
                  ) : (
                    <ArrowDropUp
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            uploadDate: prevState.uploadDate === "asc" ? "desc" : "asc",
                          };
                        });
                        sortUploadsList("uploadDate", sortingMeasure.uploadDate === "asc" ? "desc" : "asc");
                      }}
                    />
                  )}
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                <Stack direction={"row"} spacing={1}>
                  <div>File Size</div>
                  {sortingMeasure.fileSize === "asc" ? (
                    <ArrowDropDown
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            fileSize: prevState.fileSize === "asc" ? "desc" : "asc",
                          };
                        });
                        sortUploadsList("fileSize", sortingMeasure.fileSize === "asc" ? "desc" : "asc");
                      }}
                    />
                  ) : (
                    <ArrowDropUp
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setSortingMeasure((prevState) => {
                          return {
                            ...prevState,
                            fileSize: prevState.fileSize === "asc" ? "desc" : "asc",
                          };
                        });
                        sortUploadsList("fileSize", sortingMeasure.fileSize === "asc" ? "desc" : "asc");
                      }}
                    />
                  )}
                </Stack>
              </StyledTableCell>
              <StyledTableCell sx={{ width: "200px" }} align="left" className="text-wrapper">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cyreenFilesList.length !== 0 ? (
              cyreenFilesList.map((fileDetail, index) => {
                return (
                  <TableRow key={"uploaded_files_" + index}>
                    <StyledTableCell component="td" scope="row" className="text-wrapper">
                      <StyledStack direction={"row"}>{index + 1}</StyledStack>
                    </StyledTableCell>
                    {/* --------- name ---------- */}
                    <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                      {fileDetail.name}
                    </StyledTableCell>
                    {/* ----------- Upload date --------- */}
                    <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                      {formatDate(fileDetail.lastModified)}
                    </StyledTableCell>
                    {/* ---------- File Size --------- */}
                    <StyledTableCell component="td" scope="row" className="text-wrapper">
                      {fileDetail.size}
                    </StyledTableCell>

                    {/* -------- Action --------- */}
                    <StyledTableCell component="td" scope="row" className="text-wrapper">
                      <StyledStack direction={"row"} spacing={2}>
                        {/* <StyledSwitch checked={storeLoc.active} /> */}
                        <HtmlTooltip title={"Download"}>
                          <IconContainer>
                            <a
                              href="#downloadFile"
                              onClick={(e) => {
                                e.preventDefault();
                                downloadCyreenFile(fileDetail.name);
                              }}
                            >
                              <Download sx={{ color: "#AF3241", marginTop: "5px" }} />
                            </a>
                          </IconContainer>
                        </HtmlTooltip>
                      </StyledStack>
                    </StyledTableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <StyledTableCell colSpan={4} component="td" scope="row" className="text-wrapper">
                  No files available yet.
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* --------- record count info --------- */}
      <Box mt={2} id="infoText">
        <StyledDarkRedInformationTypography variant="body2">
          Showing {cyreenFilesList.length} out of {cyreenFilesList.length} results.
        </StyledDarkRedInformationTypography>
      </Box>
    </>
  );
};

export default DownloadData;
