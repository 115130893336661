import React from "react";
import { Box, Link, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import { Add, ArrowDropDown, ArrowDropUp, Save } from "@mui/icons-material";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import {
  StyledBlackInformationTypography,
  StyledDarkRedInformationTypography,
  StyledMainBox,
  StyledStack,
} from "../../components/commonComponents/StyledComponents/styled";
import SimpleModal from "../../components/commonComponents/Modals/SimpleModal";
import AddRole from "./modalContent/AddRole";
import { checkUserPermissions, fetchRoles, fetchUsers } from "../../api_calls/userManagement";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { useDispatch, useSelector } from "react-redux";
import {
  SmallWidthStyledTableCell,
  SpecialWidthStyledTableCell,
  StyledTableCell,
} from "../../components/commonComponents/TabularLists/styles";
import RoleDetail from "./modalContent/RoleDetail";
import { deleteItems } from "../../Store/actions";

const Roles = () => {
  const currentStateData = useSelector((state) => state.userSelections);
  const [selectedRoleInfo, setSelectedRoleInfo] = React.useState({});
  const allowedAccessRights = checkUserPermissions();
  const permissionScope = allowedAccessRights.allowed_access;
  const dispatchAction = useDispatch();
  const [open, setOpen] = React.useState(false);

  const closeSection = (newOpen) => {
    setOpen(newOpen);
    dispatchAction(deleteItems(["assignable_users_list", "assignable_cyreen_users"]));
  };
  // sorting measures for files listing
  const [sortingMeasure, setSortingMeasure] = React.useState({
    rolename: "asc",
    userCount: "asc",
  });
  const [sortingParamName, setSortingParamName] = React.useState("rolename");

  // to return the sorted list items
  const sortedResults = (toBeSortedList) => {
    return toBeSortedList.slice().sort((a, b) => {
      if (sortingParamName === "rolename") {
        return sortingMeasure.rolename === "asc"
          ? a.role_name.localeCompare(b.role_name)
          : b.role_name.localeCompare(a.role_name);
      } else if (sortingParamName === "userCount") {
        // assigned_users_count
        return sortingMeasure.userCount === "asc"
          ? parseInt(a.assigned_users_count) - parseInt(b.assigned_users_count)
          : parseInt(b.assigned_users_count) - parseInt(a.assigned_users_count);
      } else {
        return sortingMeasure.rolename === "asc"
          ? a.role_name.localeCompare(b.role_name)
          : b.role_name.localeCompare(a.role_name);
      }
    });
  };

  const customerRoles =
    typeof currentStateData.role_list !== "undefined" && currentStateData.role_list.length !== 0
      ? sortedResults(
        currentStateData.role_list.filter((role) => {
          if (!role.role_cyreen === true) {
            if (permissionScope.user_list_can_edit_external === true && role.role_name.toLowerCase() !== "brand") {
              return true;
            }

            if (permissionScope.user_list_can_edit_brand === true && role.role_name.toLowerCase() === "brand") {
              return true;
            }
          } else {
            return false;
          }
        })
      )
      : [];

  const cyreenRoles =
    typeof currentStateData.role_list !== "undefined" && currentStateData.role_list.length !== 0
      ? sortedResults(
        currentStateData.role_list.filter((role) => {
          if (role.role_cyreen === true) {
            if (
              permissionScope.user_list_can_delete_cyreen === true &&
              permissionScope.user_list_can_edit_cyreen === true
            ) {
              return true;
            }
          } else {
            return false;
          }
        })
      )
      : [];

  const rolesListing = [...customerRoles, ...cyreenRoles];

  const handleSortingParamChange = (paramName) => {
    setSortingMeasure((prevState) => {
      return {
        ...prevState,
        [paramName]: prevState[paramName] === "asc" ? "desc" : "asc",
      };
    });
    setSortingParamName(paramName);
  };

  const RoleRowJsx = ({ role }) => {
    return (
      <TableRow>
        {/* ---------- role name --------- */}
        <SpecialWidthStyledTableCell component="td" scope="row" className="text-wrapper">
          <Stack direction={"row"} spacing={1}>
            <Link
              href="#"
              underline="none"
              aria-label={"check details for " + role.role_name}
              title={role.role_name}
              sx={{ color: "#AF3241" }}
              onClick={(e) => {
                e.preventDefault();
                setSelectedRoleInfo(role);
                setOpen(true);
              }}
            >
              {role.role_name}

            </Link>
          </Stack>
        </SpecialWidthStyledTableCell>
        {/* ---------- role description ---------- */}
        <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
          <Stack direction={"row"}>{role.description}</Stack>
        </StyledTableCell>
        {/* ---------- modules --------- */}
        <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
          <Stack direction={"row"}>{role.modules}</Stack>
        </StyledTableCell>
        {/* -------- users --------- */}
        <SmallWidthStyledTableCell component="td" scope="row" className="text-wrapper">
          <Stack direction={"row"}>{role.assigned_users_count}</Stack>
        </SmallWidthStyledTableCell>
      </TableRow>
    );
  };

  React.useEffect(() => {
    fetchRoles(true);
  }, []);

  return (
    <>
      {/* ========== main component body =========== */}
      <StyledMainBox component="main">
        <Box height={30} />
        <h1>Roles</h1>
        <Box height={5} />
        <Typography variant="body2" sx={{ fontSize: "15px" }}>
          This section lists all permission roles that you are able to assign to users. You may review the details of each
          role and assign user(s) to the listed permission roles by clicking on the individual roles.
        </Typography>
        {/* ============ Action buttons =========== */}
        {/* <Stack direction="row" spacing={2}>
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              openModal();
            }}
            label={"New Role"}
            icon={<Add />}
          />
        </Stack> */}
        {/* ============== roles listing ============ */}
        {/* ============== users listing ============ */}
        <Box height={30} />
        {rolesListing.length !== 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              {/* ------ header row ------ */}
              <TableHead>
                <TableRow>
                  <SpecialWidthStyledTableCell align="left" className="text-wrapper">
                    <Stack direction={"row"} spacing={1}>
                      <div>Name</div>
                      {sortingMeasure.rolename === "asc" ? (
                        <ArrowDropDown
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleSortingParamChange("rolename");
                          }}
                        />
                      ) : (
                        <ArrowDropUp
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleSortingParamChange("rolename");
                          }}
                        />
                      )}
                    </Stack>
                  </SpecialWidthStyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    <Stack direction={"row"} spacing={1}>
                      <div>Description</div>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    <Stack direction={"row"} spacing={1}>
                      <div>Modules</div>
                    </Stack>
                  </StyledTableCell>
                  <SmallWidthStyledTableCell align="left" className="text-wrapper">
                    <Stack direction={"row"} spacing={1}>
                      <div>Users</div>
                      {sortingMeasure.userCount === "asc" ? (
                        <ArrowDropDown
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleSortingParamChange("userCount");
                          }}
                        />
                      ) : (
                        <ArrowDropUp
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleSortingParamChange("userCount");
                          }}
                        />
                      )}
                    </Stack>
                  </SmallWidthStyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cyreenRoles.length !== 0 && (
                  <TableRow>
                    <StyledTableCell colSpan={4}>
                      <Typography variant="body2">
                        <strong>Customer Roles</strong>
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                )}
                {customerRoles.map((role, index) => {
                  // console.log("User", user)
                  return <RoleRowJsx role={role} key={index + "_role_list_row"} />;
                })}
                {cyreenRoles.length !== 0 && (
                  <>
                    <TableRow>
                      <StyledTableCell colSpan={4}>
                        <Typography variant="body2">
                          <strong>Cyreen Roles</strong>
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                    {cyreenRoles.map((role, index) => {
                      // console.log("User", user)
                      return <RoleRowJsx role={role} key={index + "_role_list_row"} />;
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledMainBox>
      {/* ============ modal popup for different actions ========== */}
      {open === true && <RoleDetail showSection={open} closeSection={closeSection} roleInfo={selectedRoleInfo} />}
    </>
  );
};

export default AccessControl(Roles, ["user_list_can_view"], [], ["user_section", "role_list"]);
