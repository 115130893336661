import React from "react";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import { useNavigate, useParams } from "react-router-dom";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { fetchCampaign } from "../../api_calls/campaignRequests";
import { useSelector } from "react-redux";
import { checkUserRole, pageAccessAllowed } from "../../api_calls/userManagement";
import AnalyticsView from "./ModalContent/AnalyticsView";

const CampaignAnalytics = () => {
  const userRole = checkUserRole();

  const campaignDetails = useSelector((state) => state.userSelections);
  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchCampaign(id, "all");
  }, []);

  return (
    <StyledMainBox component="main">
      {typeof campaignDetails.campaign_detail !== "undefined" && typeof campaignDetails.brand_filters !== "undefined" && (
        <>
          <AnalyticsView viewMode={userRole.user_role} />
        </>
      )}
    </StyledMainBox>
  );
};

export default AccessControl(CampaignAnalytics, [
  "campaign_reports_performance_can_view",
  "campaign_reports_impression_can_view",
], [], ["campaigns_section", "campaign_list"]);
