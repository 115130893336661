import { toast } from "react-toastify";
import { deleteItems, updateStateData } from "../Store/actions";
import store from "../Store/store";
import { sendApiRequest } from "./requestConfig";
import {
  allNumbersBetweenRange,
  validateAlphabets,
  validateAlphanumeric,
  validateEmpty,
  validateNegativeDecimalNumbers,
  validateNumbers,
} from "./utils";
import { checkUserDetailsFromCookie } from "./userManagement";

let mappingOffsets = {
  ANT1: [
    "ANT1: MULTIPLEXER OUT1",
    "ANT1: MULTIPLEXER OUT2",
    "ANT1: MULTIPLEXER OUT3",
    "ANT1: MULTIPLEXER OUT4",
    "ANT1: MULTIPLEXER OUT5",
    "ANT1: MULTIPLEXER OUT6",
    "ANT1: MULTIPLEXER OUT7",
    "ANT1: MULTIPLEXER OUT8",
  ],
  ANT2: [
    "ANT2: MULTIPLEXER OUT1",
    "ANT2: MULTIPLEXER OUT2",
    "ANT2: MULTIPLEXER OUT3",
    "ANT2: MULTIPLEXER OUT4",
    "ANT2: MULTIPLEXER OUT5",
    "ANT2: MULTIPLEXER OUT6",
    "ANT2: MULTIPLEXER OUT7",
    "ANT2: MULTIPLEXER OUT8",
  ],
  ANT3: [
    "ANT3: MULTIPLEXER OUT1",
    "ANT3: MULTIPLEXER OUT2",
    "ANT3: MULTIPLEXER OUT3",
    "ANT3: MULTIPLEXER OUT4",
    "ANT3: MULTIPLEXER OUT5",
    "ANT3: MULTIPLEXER OUT6",
    "ANT3: MULTIPLEXER OUT7",
    "ANT3: MULTIPLEXER OUT8",
  ],
  ANT4: [
    "ANT4: MULTIPLEXER OUT1",
    "ANT4: MULTIPLEXER OUT2",
    "ANT4: MULTIPLEXER OUT3",
    "ANT4: MULTIPLEXER OUT4",
    "ANT4: MULTIPLEXER OUT5",
    "ANT4: MULTIPLEXER OUT6",
    "ANT4: MULTIPLEXER OUT7",
    "ANT4: MULTIPLEXER OUT8",
  ],
  "No Multiplexer": [],
};

// track changes across different tabs, and show notification if user leaves a section without saving the details
export const trackChanges = (trackFlag) => {
  store.dispatch(
    updateStateData({
      track_infra_changes: trackFlag,
    })
  );
};

// to sort store locations
export const sortStoreLocations = (sortingParam, sortingMeasure) => {
  const userStateData = store.getState().userSelections;
  const sortedList =
    typeof userStateData.infra_store_locs !== "undefined" && userStateData.infra_store_locs.length !== 0
      ? userStateData.infra_store_locs.slice().sort((a, b) => {
        if (sortingParam.cyreenId === true) {
          return sortingMeasure.cyreenId === "asc"
            ? a.number_store_cyreen - b.number_store_cyreen
            : b.number_store_cyreen - a.number_store_cyreen;
        } else {
          return sortingMeasure.store_name === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        }
      })
      : [];

  store.dispatch(
    updateStateData({
      ...userStateData,
      infra_store_locs: sortedList,
    })
  );
};

// to fetch the brand report filters saved by retailer/admin
export const fetchStoreLocations = async () => {
  // const userStateDetails = store.getState().userSelections;
  const fetchStoreLoc = await sendApiRequest("stores", {}, "GET");
  //   evaluate api response
  if (typeof fetchStoreLoc.success !== "undefined" && fetchStoreLoc.success === true) {
    store.dispatch(
      updateStateData({
        infra_store_locs:
          typeof fetchStoreLoc.response !== "undefined" && fetchStoreLoc.response.length !== 0
            ? fetchStoreLoc.response.slice().map(record => {
              return {
                ...record,
                loc_status: record.active === true ? "active" : "inactive",
                loc_company_name: record?.company?.name
              }
            }).sort((a, b) => {
              return a.number_store_cyreen - b.number_store_cyreen;
            })
            : [],
      })
    );

    return {
      success: true,
    };
  } else {
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

// to fetch opening hours
export const fetchOpeningHours = async () => {
  const fetchHours = await sendApiRequest("stores/opening-hours", {}, "GET");
  //evaluate api response
  if (typeof fetchHours.success !== "undefined" && fetchHours.success === true) {
    store.dispatch(
      updateStateData({
        infra_opening_hours: fetchHours.response.map((openHour) => {
          return {
            id: openHour.id,
            description: openHour.description,
            ...openHour,
          };
        }),
      })
    );

    return {
      success: true,
    };
  } else {
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

// fetch opening hour id
export const fetchOpeningHourId = (selectedHourDesc) => {
  const userStateData = store.getState().userSelections;
  let hourId = "";
  let weekDayHours = {};
  let selectedWeekDays = {};

  if (
    selectedHourDesc !== "" &&
    typeof userStateData.infra_opening_hours !== "undefined" &&
    userStateData.infra_opening_hours.length !== 0
  ) {
    let openingHourList = userStateData.infra_opening_hours;
    if (typeof userStateData.custom_opening_hour !== "undefined") {
      openingHourList = [...openingHourList, userStateData.custom_opening_hour];
    }
    openingHourList.forEach((hourDetail) => {
      if (selectedHourDesc === hourDetail.description) {
        hourId = hourDetail.id;
        weekDayHours = {
          monday: hourDetail.monday,
          tuesday: hourDetail.tuesday,
          wednesday: hourDetail.wednesday,
          thursday: hourDetail.thursday,
          friday: hourDetail.friday,
          saturday: hourDetail.saturday,
          sunday: hourDetail.sunday,
        };
        selectedWeekDays = {
          monday: hourDetail.monday[0] === "00:00" && hourDetail.monday[1] === "00:00" ? false : true,
          tuesday: hourDetail.tuesday[0] === "00:00" && hourDetail.tuesday[1] === "00:00" ? false : true,
          wednesday: hourDetail.wednesday[0] === "00:00" && hourDetail.wednesday[1] === "00:00" ? false : true,
          thursday: hourDetail.thursday[0] === "00:00" && hourDetail.thursday[1] === "00:00" ? false : true,
          friday: hourDetail.friday[0] === "00:00" && hourDetail.friday[1] === "00:00" ? false : true,
          saturday: hourDetail.saturday[0] === "00:00" && hourDetail.saturday[1] === "00:00" ? false : true,
          sunday: hourDetail.sunday[0] === "00:00" && hourDetail.sunday[1] === "00:00" ? false : true,
        };
      }
    });
  }
  return {
    hourId,
    weekDayHours,
    selectedWeekDays,
    selected_default_hours:
      typeof weekDayHours.monday !== "undefined" ? [weekDayHours.monday[0], weekDayHours.monday[1]] : ["00:00", "00:00"],
  };
};

// fetch region id
export const fetchRegionId = (selectedRegion) => {
  const userStateData = store.getState().userSelections;
  let regionId = {};
  if (
    selectedRegion !== "" &&
    typeof userStateData.regions_list !== "undefined" &&
    userStateData.regions_list.length !== 0
  ) {
    userStateData.regions_list.forEach((regionDetail) => {
      if (selectedRegion === regionDetail.label) {
        regionId = regionDetail;
      }
    });
  }
  return regionId;
};

// fetch country id
export const fetchCountryId = (selectedCountry) => {
  const userStateData = store.getState().userSelections;
  let countryId = "";
  if (
    selectedCountry !== "" &&
    typeof userStateData.countries_list !== "undefined" &&
    userStateData.countries_list.length !== 0
  ) {
    userStateData.countries_list.forEach((countryDetail) => {
      if (selectedCountry === countryDetail.name) {
        countryId = countryDetail.id;
      }
    });
  }
  return countryId;
};

function validateTimeGap(openingTime, closingTime) {
  // Split the time strings to get hours and minutes
  const openingTimeParts = openingTime.split(":").map(Number);
  const closingTimeParts = closingTime.split(":").map(Number);

  // Convert opening and closing times to minutes
  let openingMinutes = openingTimeParts[0] * 60 + openingTimeParts[1];
  let closingMinutes = closingTimeParts[0] * 60 + closingTimeParts[1];

  // If closing time is before opening time, adjust closing time to next day
  if (closingMinutes < openingMinutes) {
    closingMinutes += 24 * 60; // Add 24 hours in minutes
  }

  // Calculate the time difference in minutes
  const timeDifference = closingMinutes - openingMinutes;

  // Check if the time difference is at least 15 minutes
  return timeDifference >= 15;
}

// validate store loc data
const validateStoreLocData = () => {
  const currentStateData = store.getState().userSelections;
  const loggedUserDetails = checkUserDetailsFromCookie();
  const storeId =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.id !== "undefined"
      ? currentStateData.new_store_loc_data.id
      : "";
  const storeName =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.store_name !== "undefined"
      ? currentStateData.new_store_loc_data.store_name
      : "";
  const storeCountry =
    typeof loggedUserDetails.success !== "undefined" && loggedUserDetails.success === true
      ? loggedUserDetails.user_detail.current_workspace.country.name
      : "";
  const storeCountryId =
    typeof loggedUserDetails.success !== "undefined" && loggedUserDetails.success === true
      ? loggedUserDetails.user_detail.current_workspace.country.id
      : "";
  // typeof currentStateData.new_store_loc_data !== "undefined" &&
  // typeof currentStateData.new_store_loc_data.store_country !== "undefined"
  //   ? currentStateData.new_store_loc_data.store_country
  //   : "";
  const storeRegion =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.store_region !== "undefined"
      ? currentStateData.new_store_loc_data.store_region
      : "";
  const newRegion =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.new_region !== "undefined"
      ? currentStateData.new_store_loc_data.new_region
      : false;
  const newRegionName =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.new_region_name !== "undefined"
      ? currentStateData.new_store_loc_data.new_region_name
      : "";
  const newRegionCode =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.new_region_code !== "undefined"
      ? currentStateData.new_store_loc_data.new_region_code
      : "";
  const storeStreet =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.store_street !== "undefined"
      ? currentStateData.new_store_loc_data.store_street
      : "";
  const storeZip =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.store_zip !== "undefined"
      ? currentStateData.new_store_loc_data.store_zip
      : "";
  const storeCity =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.store_city !== "undefined"
      ? currentStateData.new_store_loc_data.store_city
      : "";
  const longitude =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.longitude !== "undefined"
      ? currentStateData.new_store_loc_data.longitude
      : "";
  const latitude =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.latitude !== "undefined"
      ? currentStateData.new_store_loc_data.latitude
      : "";
  const storeOpeningTime =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.store_opening_hour !== "undefined"
      ? currentStateData.new_store_loc_data.store_opening_hour
      : "";
  const selectedDays =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.selected_week_days !== "undefined"
      ? currentStateData.new_store_loc_data.selected_week_days
      : [];
  const weekDayTimings =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.weekday_timings !== "undefined"
      ? currentStateData.new_store_loc_data.weekday_timings
      : {};
  const addNewHours =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.new_opening_hours !== "undefined"
      ? currentStateData.new_store_loc_data.new_opening_hours
      : false;
  const retailerStoreId =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.retailer_store_id !== "undefined"
      ? currentStateData.new_store_loc_data.retailer_store_id
      : "";
  const storeTrolleys =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.store_trolleys !== "undefined"
      ? currentStateData.new_store_loc_data.store_trolleys
      : "";

  const storeBaskets =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.store_baskets !== "undefined"
      ? currentStateData.new_store_loc_data.store_baskets
      : "";
  const storeStatus =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.store_status !== "undefined"
      ? currentStateData.new_store_loc_data.store_status
      : "";
  const storeSize =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.store_size !== "undefined"
      ? currentStateData.new_store_loc_data.store_size
      : "";
  const automatedStores =
    typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.automated_checkout !== "undefined"
      ? currentStateData.new_store_loc_data.automated_checkout
      : "Automated";

  const ownerValue = ["cyreen", "branch"].includes(loggedUserDetails.user_detail.user_type)
    ? typeof currentStateData.new_store_loc_data !== "undefined" &&
      typeof currentStateData.new_store_loc_data.share_list !== "undefined"
      ? currentStateData.new_store_loc_data.share_list
      : ""
    : typeof loggedUserDetails.success !== "undefined" &&
      loggedUserDetails.success === true &&
      typeof loggedUserDetails.user_detail.assigned_company !== "undefined"
      ? loggedUserDetails.user_detail.assigned_company.id
      : "";

  let error = false;
  let errorMessages = {};

  // validate all the required fields
  // store name
  const validateStoreName = validateEmpty(storeName);
  if (validateStoreName.error === true) {
    error = true;
    errorMessages = {
      ...errorMessages,
      store_name: "Store name must not be empty",
    };
  }
  // country
  if (storeCountry === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      store_country: "Please select the country",
    };
  }
  // region
  if (storeRegion === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      store_region: "Please select the region",
    };
  }
  // if a new region to be added
  if (newRegion === true) {
    // validate details
    const validateRegionName = validateEmpty(newRegionName);
    const validateRegionCode = validateEmpty(newRegionCode);
    //
    if (validateRegionName.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        new_region_name: "Region name must not be empty and may not contain special characters",
      };
    } else {
      // check for duplicacy
      const existingRegionNames = currentStateData.regions_list.map((regionOption) => {
        return regionOption.label;
      });
      if (existingRegionNames.includes(newRegionName)) {
        error = true;
        errorMessages = {
          ...errorMessages,
          new_region_name: "Region name already exist",
        };
      }
    }
    // Region code
    if (validateRegionCode.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        new_region_code: "Region code must not be empty and may not contain special characters",
      };
    }
  }
  // street
  const validateStreet = validateEmpty(storeStreet);
  if (validateStreet.error === true) {
    error = true;
    errorMessages = {
      ...errorMessages,
      store_street: "Street & no. must not be empty",
    };
  }

  // zip
  const validateZip = validateEmpty(storeZip);
  if (validateZip.error === true) {
    error = true;
    errorMessages = {
      ...errorMessages,
      store_zip: "Zip code  must not be empty",
    };
  }

  // city
  const validateCity = validateEmpty(storeCity);
  if (validateCity.error === true) {
    error = true;
    errorMessages = {
      ...errorMessages,
      store_city: "City must not be empty",
    };
  }
  // longitude latitude
  const validateLongitude = validateNegativeDecimalNumbers(longitude, true);
  if (validateLongitude.error === true) {
    error = true;
    errorMessages = {
      ...errorMessages,
      longitude: "Longitude cannot be empty or contain letters. Use period as decimal separator",
    };
  }

  // city
  const validateLatitude = validateNegativeDecimalNumbers(latitude, true);
  if (validateLatitude.error === true) {
    error = true;
    errorMessages = {
      ...errorMessages,
      latitude: "Latitude cannot be empty or contain letters. Use period as decimal separator",
    };
  }
  // opening time
  if (storeOpeningTime === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      store_opening_time: "Please select the opening hours",
    };
  } else {
    // validate selected days (atleast one day should be enabled.)
    if (!Object.values(selectedDays).includes(true)) {
      error = true;
      errorMessages = {
        ...errorMessages,
        week_day_selection: "Please enable at least one weekday",
      };
    }
    // validate week day timings (there must be 15 minutes of time gap)
    ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].forEach((dayName) => {
      if (
        selectedDays[dayName] === true &&
        validateTimeGap(weekDayTimings[dayName][0], weekDayTimings[dayName][1]) !== true
      ) {
        error = true;
        errorMessages = {
          ...errorMessages,
          [dayName]: "Opening and closing times should differ by at least 15 minutes.",
        };
      }
    });
  }
  // validate opening and closing time to have 15 minutes difference (if new hours are added)
  // if (addNewHours === true) {
  //   const newOpeningTime =
  //     typeof currentStateData.new_store_loc_data !== "undefined" &&
  //     typeof currentStateData.new_store_loc_data.store_new_opening_hour !== "undefined"
  //       ? currentStateData.new_store_loc_data.store_new_opening_hour
  //       : "";
  //   const newClosingTime =
  //     typeof currentStateData.new_store_loc_data !== "undefined" &&
  //     typeof currentStateData.new_store_loc_data.store_new_closing_hour !== "undefined"
  //       ? currentStateData.new_store_loc_data.store_new_closing_hour
  //       : "";
  //   // validate that opening and closing times are not empty
  //   if (newOpeningTime === "") {
  //     error = true;
  //     errorMessages = {
  //       ...errorMessages,
  //       store_new_opening_hour: "Please select the opening hour",
  //     };
  //   }
  //   if (newClosingTime === "") {
  //     error = true;
  //     errorMessages = {
  //       ...errorMessages,
  //       store_new_closing_hour: "Please select the closing hour",
  //     };
  //   }
  //   // validate for 15 minutes of gap
  //   if (newOpeningTime !== "" && newClosingTime !== "") {
  //     if (validateTimeGap(newOpeningTime, newClosingTime) !== true) {
  //       error = true;
  //       errorMessages = {
  //         ...errorMessages,
  //         store_new_closing_hour: "Opening and closing times should differ by at least 15 minutes.",
  //       };
  //     }
  //   }
  // }

  // owner validation
  if (ownerValue === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      share_list_selection: "Please make a selection",
    };
  }
  // retailer store id
  if (retailerStoreId !== "" && retailerStoreId !== null) {
    const validateRetailerId = validateNumbers(retailerStoreId);
    if (validateRetailerId.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        retailer_store_id: "Retailer store id may not contain letters or negative values",
      };
    }
  }
  // storeTrolleys

  if (storeTrolleys !== "" && storeTrolleys !== null) {
    const validateTrolleys = validateNumbers(storeTrolleys);
    if (validateTrolleys.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        store_trolleys: "Equipped trolleys may not contain letters or negative values",
      };
    }
  }
  // storeBaskets

  if (storeBaskets !== "" && storeBaskets !== null) {
    const validateBaskets = validateNumbers(storeBaskets);
    if (validateBaskets.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        store_baskets: "Equipped baskets may not contain letters or negative values",
      };
    }
  }
  // store status
  // if (storeStatus === "") {
  //   error = true;
  //   errorMessages = {
  //     ...errorMessages,
  //     store_status: "Please select the store status",
  //   };
  // }

  // store size
  // if (storeSize === "" || storeSize === null) {
  //   error = true;
  //   errorMessages = {
  //     ...errorMessages,
  //     store_size: "Please enter the store size",
  //   };
  // }

  if (storeSize !== "" && storeSize !== null) {
    const validateStoreSize = validateNumbers(storeSize, true);
    if (validateStoreSize.error === true) {
      error = true;
      errorMessages = {
        ...errorMessages,
        store_size: "Store size must not be empty and may not contain letters or negative values",
      };
    }
  }

  // return error messages or the store data request body
  if (error === true) {
    toast("Please fill in valid information", { type: "error" });
    return {
      error: true,
      errorMessages,
    };
  } else {
    let storeLocRequestObj = {
      name: storeName,
      address: storeStreet,
      city: storeCity,
      zipCode: storeZip,
      region_id: currentStateData?.new_store_loc_data?.store_region_id,
      region: currentStateData?.new_store_loc_data?.store_region_obj,
      country_id: storeCountryId,
      id_opening_hour: currentStateData?.new_store_loc_data?.store_opening_hour_id,
      // active: false, //by default, new store location will be set to inactive //storeStatus === "Active" ? true : false,
      installed_trolleys: storeTrolleys,
      installed_baskets: storeBaskets,
      new_opening_hours: addNewHours,
      store_size: storeSize,
      automated: automatedStores === "Automated" ? true : false,
      number_store_retailer: retailerStoreId,
      longitude: longitude,
      latitude: latitude,
      retailer_id: ownerValue,
    };

    // append store id (if we are updating the store location)
    if (storeId !== "") {
      const actualDeviceStatus = checkDeviceStatus();
      const activeStoreValue = storeStatus === "Active" ? true : false;
      storeLocRequestObj = {
        ...storeLocRequestObj,
        id: storeId,
      };
      if (actualDeviceStatus !== activeStoreValue) {
        storeLocRequestObj = {
          ...storeLocRequestObj,
          active: activeStoreValue,
        };
      }
    } else {
      storeLocRequestObj = {
        ...storeLocRequestObj,
        active: false,
      };
    }

    // update request body if new region has to be added
    if (currentStateData?.new_store_loc_data?.new_region === true) {
      storeLocRequestObj = {
        ...storeLocRequestObj,
        region: {
          name: newRegionName,
          code: newRegionCode,
          country: storeCountryId,
        },
      };
    }

    // update request body if new opening hours are added
    if (
      currentStateData?.new_store_loc_data?.new_opening_hours === true ||
      currentStateData?.new_store_loc_data?.time_modifications === true
    ) {
      let openingHourObj = {
        monday:
          currentStateData?.new_store_loc_data?.selected_week_days.monday === true
            ? currentStateData?.new_store_loc_data?.weekday_timings?.monday
            : ["00:00", "00:00"],
        tuesday:
          currentStateData?.new_store_loc_data?.selected_week_days.tuesday === true
            ? currentStateData?.new_store_loc_data?.weekday_timings?.tuesday
            : ["00:00", "00:00"],
        wednesday:
          currentStateData?.new_store_loc_data?.selected_week_days.wednesday === true
            ? currentStateData?.new_store_loc_data?.weekday_timings?.wednesday
            : ["00:00", "00:00"],
        thursday:
          currentStateData?.new_store_loc_data?.selected_week_days.thursday === true
            ? currentStateData?.new_store_loc_data?.weekday_timings?.thursday
            : ["00:00", "00:00"],
        friday:
          currentStateData?.new_store_loc_data?.selected_week_days.friday === true
            ? currentStateData?.new_store_loc_data?.weekday_timings?.friday
            : ["00:00", "00:00"],
        saturday:
          currentStateData?.new_store_loc_data?.selected_week_days.saturday === true
            ? currentStateData?.new_store_loc_data?.weekday_timings?.saturday
            : ["00:00", "00:00"],
        sunday:
          currentStateData?.new_store_loc_data?.selected_week_days.sunday === true
            ? currentStateData?.new_store_loc_data?.weekday_timings?.sunday
            : ["00:00", "00:00"],
      };

      let openingHourDetails = {
        ...openingHourObj,
        description: deriveDescription(openingHourObj),
      };

      storeLocRequestObj = {
        ...storeLocRequestObj,
        opening_hours: openingHourDetails,
        new_opening_hours: true,
        id_opening_hour: "",
      };
    }
    return {
      error: false,
      store_loc_request_data: storeLocRequestObj,
    };
  }
};

const deriveDescription = (weekday_timings) => {
  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  const shortDays = ["Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat.", "Sun."];

  // Find the first open day
  let startDayIndex = 0;
  while (
    startDayIndex < days.length &&
    weekday_timings[days[startDayIndex]][0] === "00:00" &&
    weekday_timings[days[startDayIndex]][1] === "00:00"
  ) {
    startDayIndex++;
  }

  // Find the last open day
  let endDayIndex = days.length - 1;
  while (
    endDayIndex >= 0 &&
    weekday_timings[days[endDayIndex]][0] === "00:00" &&
    weekday_timings[days[endDayIndex]][1] === "00:00"
  ) {
    endDayIndex--;
  }

  // If all days are closed
  if (startDayIndex > endDayIndex) {
    return "Closed all week";
  }

  // Check if all open days have the same timings
  const commonStart = weekday_timings[days[startDayIndex]][0];
  const commonEnd = weekday_timings[days[startDayIndex]][1];

  return `${shortDays[startDayIndex]}-${shortDays[endDayIndex]} ${commonStart} - ${commonEnd}`;
};

// save store location data
export const saveStoreLocData = async (usedFor) => {
  // validate data
  const validateStoreData = validateStoreLocData();

  if (validateStoreData.error === true) {
    return {
      success: false,
      errorMessages: validateStoreData.errorMessages,
    };
  } else {
    const saveData = await sendApiRequest("stores/save", validateStoreData.store_loc_request_data, "POST");
    //evaluate api response
    if (typeof saveData.response.success !== "undefined" && saveData.response.success === true) {
      let message = usedFor === "update" ? "Store updated successfully." : "Store added successfully.";
      toast(message, { type: "success" });

      return {
        success: true,
        store_id: saveData.response.store_id,
      };
    } else {
      toast(saveData.response.error_detail, { type: "error" });
      return {
        success: false,
      };
    }
  }
};

// fetch countries
export const fetchCountriesList = async () => {
  const fetchCountries = await sendApiRequest("countries", {}, "GET");
  //evaluate api response
  if (typeof fetchCountries.success !== "undefined" && fetchCountries.success === true) {
    store.dispatch(
      updateStateData({
        countries_list: fetchCountries.response,
      })
    );

    return {
      success: true,
    };
  } else {
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

// fetch store overview details
export const fetchStoreOverviewDetails = async (storeId) => {
  const fetchStoreOverview = await sendApiRequest("stores/" + storeId, {}, "GET");
  console.log(fetchStoreOverview);
  //evaluate api response
  if (
    typeof fetchStoreOverview.response !== "undefined" &&
    typeof fetchStoreOverview.response.error !== "undefined" &&
    fetchStoreOverview.response.error === true
  ) {
    toast("Store data not found", { type: "error" });
    setTimeout(() => {
      window.location.href = "/infrastructure/locations";
    }, 2000);
    return {
      success: false,
    };
  } else {
    if (typeof fetchStoreOverview.success !== "undefined" && fetchStoreOverview.success === true) {
      store.dispatch(
        updateStateData({
          store_overview: fetchStoreOverview.response,
        })
      );

      return {
        success: true,
      };
    } else {
      toast("Sorry, something went wrong", { type: "error" });
      return {
        success: false,
      };
    }
  }
};

export const verifyStoreIP = async (storeId) => {
  const currentStateData = store.getState().userSelections;
  let error = false;
  let error_message = "";

  if (typeof currentStateData.store_ip_info !== "undefined") {
    if (
      typeof currentStateData.store_ip_info.second_octet === "undefined" ||
      currentStateData?.store_ip_info?.second_octet === ""
    ) {
      error = true;
      error_message = "Please define a Store IP Address";
    } else {
      // validate octet value
      const validate = octetValidation(currentStateData?.store_ip_info?.second_octet);
      if (validate.error === true) {
        error = true;
        error_message = validate.error_message;
      }
    }

    if (
      typeof currentStateData.store_ip_info.third_octet === "undefined" ||
      currentStateData?.store_ip_info?.third_octet === ""
    ) {
      error = true;
      error_message = "Please define a Store IP Address";
    } else {
      // validate octet value
      const validate = octetValidation(currentStateData?.store_ip_info?.third_octet);
      if (validate.error === true) {
        error = true;
        error_message = validate.error_message;
      }
    }
  } else {
    error = true;
    error_message = "Please define a Store IP Address";
  }

  // check for duplicacy
  if (error === false) {
    const ipDuplicacy = await duplicateIpCheck(storeId);
    if (ipDuplicacy.error === true) {
      error = true;
      error_message = "IP address not available. Please verify your input";
    }
  }

  return {
    error: error,
    error_message: error_message,
  };
};

// define the list of devices based on the device counts defined by user
export const defineDevicesList = () => {
  const currentStateData = store.getState().userSelections;
  const selectedDeviceCounts = currentStateData?.device_count_info;
  const dbDeviceInfo = typeof currentStateData.db_device_info !== "undefined" ? currentStateData.db_device_info : [];
  const storeIpInfo =
    "10." + currentStateData?.store_ip_info?.second_octet + "." + currentStateData?.store_ip_info?.third_octet;

  let deviceList = [];

  // screen area reader list
  if (selectedDeviceCounts?.screen_area_reader) {
    let lastOctetRangeForScreens = allNumbersBetweenRange(10, 99);
    // define screen and area reader devices
    for (let index = 0; index < selectedDeviceCounts.screen_area_reader; index++) {
      let deviceObj = {
        type: index === 0 ? "Master Screen" : "Screen",
        store_ip: storeIpInfo,
        ip_address: dbDeviceInfo.length !== 0 ? "" : storeIpInfo + "." + lastOctetRangeForScreens[0],
        last_octet: dbDeviceInfo.length !== 0 ? "" : lastOctetRangeForScreens[0],
        active: false,
        color_code: "#fff",
        area_reader: {
          type: "Area Reader",
          store_ip: storeIpInfo,
          ip_address: dbDeviceInfo.length !== 0 ? "" : storeIpInfo + "." + lastOctetRangeForScreens[1],
          last_octet: dbDeviceInfo.length !== 0 ? "" : lastOctetRangeForScreens[1],
          active: false,
        },
      };
      // we need to maintain the existing status of device (if it's already added)
      if (dbDeviceInfo.length !== 0 && typeof dbDeviceInfo.screens[index] !== "undefined") {
        const lastOctetForScreen = dbDeviceInfo.screens[index].ip_address.split(".");
        const lastOctetForAreaReader = dbDeviceInfo.screens[index].area_reader.ip_address.split(".");
        deviceObj = {
          ...deviceObj,
          id: dbDeviceInfo.screens[index].id,
          active: dbDeviceInfo.screens[index].active,
          ip_address: storeIpInfo + "." + lastOctetForScreen[3],
          last_octet: lastOctetForScreen[3],
          color_code: "#fff",
          area_reader: {
            ...deviceObj.area_reader,
            id: dbDeviceInfo.screens[index].area_reader.id,
            active: dbDeviceInfo.screens[index].area_reader.active,
            ip_address: storeIpInfo + "." + lastOctetForAreaReader[3],
            last_octet: lastOctetForAreaReader[3],
          },
        };
      }
      deviceList.push(deviceObj);
      // remove octets which are already used.
      lastOctetRangeForScreens.splice(0, 5);
    }
  }
  // checkout reader list
  if (selectedDeviceCounts?.checkout_reader) {
    let lastOctetRangeForCheckoutReaders = allNumbersBetweenRange(
      100,
      parseInt(100 + parseInt(selectedDeviceCounts.checkout_reader))
    );
    for (let index = 0; index < selectedDeviceCounts.checkout_reader; index++) {
      let deviceObj = {
        type: "Checkout Reader",
        store_ip: storeIpInfo,
        ip_address: dbDeviceInfo.length !== 0 ? "" : storeIpInfo + "." + lastOctetRangeForCheckoutReaders[0],
        last_octet: dbDeviceInfo.length !== 0 ? "" : lastOctetRangeForCheckoutReaders[0],
        active: false,
        color_code: "#E6E6E6",
      };
      // we need to maintain the existing status of device (if it's already added)
      if (dbDeviceInfo.length !== 0 && typeof dbDeviceInfo.checkout_readers[index] !== "undefined") {
        const lastOctetForCheckout = dbDeviceInfo.checkout_readers[index].ip_address.split(".");
        deviceObj = {
          ...deviceObj,
          id: dbDeviceInfo.checkout_readers[index].id,
          active: dbDeviceInfo.checkout_readers[index].active,
          ip_address: storeIpInfo + "." + lastOctetForCheckout[3],
          last_octet: lastOctetForCheckout[3],
          color_code: "#E6E6E6",
        };
      }
      deviceList.push(deviceObj);
      // remove octets which are already used.
      lastOctetRangeForCheckoutReaders.splice(0, 1);
    }
  }
  // checkin reader list
  if (selectedDeviceCounts?.checkin_reader) {
    let startNum = parseInt(100 + parseInt(selectedDeviceCounts.checkout_reader));
    let endNum = parseInt(startNum + parseInt(selectedDeviceCounts.checkin_reader));
    let lastOctetRangeForCheckInReaders = allNumbersBetweenRange(startNum, endNum);
    for (let index = 0; index < selectedDeviceCounts.checkin_reader; index++) {
      let deviceObj = {
        type: "Checkin Reader",
        store_ip: storeIpInfo,
        ip_address: dbDeviceInfo.length !== 0 ? "" : storeIpInfo + "." + lastOctetRangeForCheckInReaders[0],
        last_octet: dbDeviceInfo.length !== 0 ? "" : lastOctetRangeForCheckInReaders[0],
        active: false,
        color_code: "#fff",
      };
      // we need to maintain the existing status of device (if it's already added)
      if (dbDeviceInfo.length !== 0 && typeof dbDeviceInfo.checkin_readers[index] !== "undefined") {
        const lastOctetForCheckin = dbDeviceInfo.checkin_readers[index].ip_address.split(".");
        deviceObj = {
          ...deviceObj,
          id: dbDeviceInfo.checkin_readers[index].id,
          active: dbDeviceInfo.checkin_readers[index].active,
          ip_address: storeIpInfo + "." + lastOctetForCheckin[3],
          last_octet: lastOctetForCheckin[3],
          color_code: "#fff",
        };
      }
      deviceList.push(deviceObj);
      lastOctetRangeForCheckInReaders.splice(0, 1);
    }
  }
  // self checkout reader list
  if (selectedDeviceCounts?.self_checkout_reader) {
    let startNum = parseInt(
      100 + parseInt(selectedDeviceCounts.checkout_reader) + parseInt(selectedDeviceCounts.checkin_reader)
    );
    let endNum = parseInt(startNum + parseInt(selectedDeviceCounts.self_checkout_reader));
    let lastOctetRangeForSelfCheckOutReaders = allNumbersBetweenRange(startNum, endNum);
    for (let index = 0; index < selectedDeviceCounts.self_checkout_reader; index++) {
      let deviceObj = {
        type: "Self Checkout Reader",
        store_ip: storeIpInfo,
        ip_address: dbDeviceInfo.length !== 0 ? "" : storeIpInfo + "." + lastOctetRangeForSelfCheckOutReaders[0],
        last_octet: dbDeviceInfo.length !== 0 ? "" : lastOctetRangeForSelfCheckOutReaders[0],
        active: false,
        color_code: "#E6E6E6",
      };
      // we need to maintain the existing status of device (if it's already added)
      if (dbDeviceInfo.length !== 0 && typeof dbDeviceInfo.self_checkout_readers[index] !== "undefined") {
        const lastOctetForSelfCheckout = dbDeviceInfo.self_checkout_readers[index].ip_address.split(".");
        deviceObj = {
          ...deviceObj,
          id: dbDeviceInfo.self_checkout_readers[index].id,
          active: dbDeviceInfo.self_checkout_readers[index].active,
          ip_address: storeIpInfo + "." + lastOctetForSelfCheckout[3],
          last_octet: lastOctetForSelfCheckout[3],
          color_code: "#E6E6E6",
        };
      }
      deviceList.push(deviceObj);
      lastOctetRangeForSelfCheckOutReaders.splice(0, 1);
    }
  }
  // Instore reader without screen list
  if (selectedDeviceCounts?.instore_reader_without_screen) {
    let startNum = parseInt(
      100 +
      parseInt(selectedDeviceCounts.checkout_reader) +
      parseInt(selectedDeviceCounts.checkin_reader) +
      parseInt(selectedDeviceCounts.self_checkout_reader)
    );
    let endNum = parseInt(startNum + parseInt(selectedDeviceCounts.instore_reader_without_screen));
    let lastOctetRangeForInstoreReader = allNumbersBetweenRange(startNum, endNum);
    for (let index = 0; index < selectedDeviceCounts.instore_reader_without_screen; index++) {
      let deviceObj = {
        type: "Instore Reader Without Screen",
        store_ip: storeIpInfo,
        ip_address: dbDeviceInfo.length !== 0 ? "" : storeIpInfo + "." + lastOctetRangeForInstoreReader[0],
        last_octet: dbDeviceInfo.length !== 0 ? "" : lastOctetRangeForInstoreReader[0],
        active: false,
        color_code: "#fff",
      };
      // we need to maintain the existing status of device (if it's already added)
      if (dbDeviceInfo.length !== 0 && typeof dbDeviceInfo.instore_readers_without_screen[index] !== "undefined") {
        const lastOctetForInstore = dbDeviceInfo.instore_readers_without_screen[index].ip_address.split(".");
        deviceObj = {
          ...deviceObj,
          id: dbDeviceInfo.instore_readers_without_screen[index].id,
          ip_address: storeIpInfo + "." + lastOctetForInstore[3],
          active: dbDeviceInfo.instore_readers_without_screen[index].active,
          last_octet: lastOctetForInstore[3],
          color_code: "#fff",
        };
      }
      deviceList.push(deviceObj);
      lastOctetRangeForInstoreReader.splice(0, 1);
    }
  }

  store.dispatch(
    updateStateData({
      device_list: deviceList,
    })
  );
};

export const duplicateOctetCheck = (octetInfo, index, area_reader_validation = false) => {
  let error = false;
  let errorMessage = "";
  // validate for duplicateValue
  const duplicateCheck = fetchLastOctetList(index, area_reader_validation);
  if (duplicateCheck.includes(parseInt(octetInfo.last_octet)) || parseInt(octetInfo.last_octet) === 10) {
    error = true;
    errorMessage = "Each device must have a unique IP octet";
  } else {
    // validate octet rule
    const validate = octetValidation(octetInfo.last_octet, true);
    if (validate.error === true) {
      error = true;
      errorMessage = validate.error_message;
    } else {
      error = false;
    }
  }
  return {
    error,
    errorMessage,
  };
};

export const octetValidation = (octetValue, last_octet = false) => {
  const validateOctetForNumber = validateNumbers(octetValue);
  if (validateOctetForNumber.error === true) {
    return {
      error: true,
      error_message: "IP octets must be between 11-255 and cannot be negative, empty, or contain letters",
    };
  } else {
    if (parseInt(octetValue) < 0 || parseInt(octetValue) > 255) {
      return {
        error: true,
        error_message: "IP octets must be between 0-255 and cannot be negative, empty, or contain letters",
      };
    } else {
      if (last_octet === true) {
        if (parseInt(octetValue) < 11 || parseInt(octetValue) > 255) {
          return {
            error: true,
            error_message: "IP octets must be between 11-255 and cannot be negative, empty, or contain letters",
          };
        }
      }
    }

    return {
      error: false,
      error_message: "",
    };
  }
};

export const fetchLastOctetList = (indexToExclude, areaReaderValidation = false) => {
  const currentStateData = store.getState().userSelections;
  // validate duplicate ip's based on last octet (because initia 3 octets are fixed being defined as store ip)
  const deviceInfo = typeof currentStateData.device_list !== "undefined" ? currentStateData.device_list : [];
  // get a list of last octets
  let lastOctetValues = [];
  if (deviceInfo.length !== 0) {
    deviceInfo.forEach((device, index) => {
      if (device.active === true) {
        if (index !== indexToExclude) {
          lastOctetValues.push(parseInt(device.last_octet));
          if (typeof device.area_reader !== "undefined") {
            lastOctetValues.push(parseInt(device.area_reader.last_octet));
          }
        } else {
          if (areaReaderValidation === true) {
            lastOctetValues.push(parseInt(device.last_octet));
          }
        }
      }
    });
  }
  return lastOctetValues;
};

export const checkDeviceStatus = () => {
  const currentStateData = store.getState().userSelections;
  let deviceStatusArray = [];
  if (typeof currentStateData.device_list !== "undefined" && currentStateData.device_list.length !== 0) {
    currentStateData.device_list.forEach((device, index) => {
      deviceStatusArray.push(device.active);
    });
  }

  return deviceStatusArray.length !== 0 ? deviceStatusArray.includes(true) : false;
};

export const validateDeviceList = () => {
  const currentStateData = store.getState().userSelections;
  let errorDetails = {};
  let error = false;
  let deviceStatusArray = [];
  let areaReaderErrorIndexes = [];
  // validate devices
  if (typeof currentStateData.device_list !== "undefined" && currentStateData.device_list.length !== 0) {
    currentStateData.device_list.forEach((device, index) => {
      if (device.active === true) {
        let checkRules = duplicateOctetCheck({ last_octet: device.last_octet }, index, false);
        // validate area reader info
        if (device.type === "Master Screen" || device.type === "Screen") {
          let checkAreaReaderInfo = duplicateOctetCheck({ last_octet: device.area_reader.last_octet }, index, true);

          if (checkAreaReaderInfo.error === true) {
            error = true;
            errorDetails = {
              ...errorDetails,
              [index]: checkAreaReaderInfo.errorMessage,
            };
            areaReaderErrorIndexes = [...areaReaderErrorIndexes, index];
          }
        }

        if (checkRules.error === true && device.type !== "Master Screen") {
          error = true;
          errorDetails = {
            ...errorDetails,
            [index]: checkRules.errorMessage,
          };
        }
        deviceStatusArray.push(device.active);
      }
    });
  }

  return {
    error,
    errorDetails,
    deviceStatusArray,
    areaReaderErrorIndexes,
  };
};

// validate duplicate ip's and save device info to backend database
export const saveDevicesInfo = async (storeId, storeActiveStatus = "") => {
  const currentStateData = store.getState().userSelections;
  // validate duplicate ip's based on last octet (because initia 3 octets are fixed being defined as store ip)
  const deviceInfo = typeof currentStateData.device_list !== "undefined" ? currentStateData.device_list : [];
  let deviceRequest = {
    screens: [],
    checkout_readers: [],
    checkin_readers: [],
    self_checkout_readers: [],
    instore_readers_without_screen: [],
  };

  if (storeActiveStatus !== "") {
    deviceRequest = {
      ...deviceRequest,
      store_active: storeActiveStatus,
    };
  }

  if (deviceInfo.length !== 0) {
    deviceInfo.forEach((device) => {
      // screens
      if (device.type === "Master Screen" || device.type === "Screen") {
        deviceRequest = {
          ...deviceRequest,
          screens: [
            ...deviceRequest.screens,
            {
              ...device,
              type: device.type,
              ip_address: device.ip_address,
              active: device.active,
              area_reader: {
                ...device.area_reader,
                ip_address: device.area_reader.ip_address,
                active: device.area_reader.active,
              },
            },
          ],
        };
      }
      // checkout readers
      if (device.type === "Checkout Reader") {
        deviceRequest = {
          ...deviceRequest,
          checkout_readers: [
            ...deviceRequest.checkout_readers,
            {
              ...device,
              ip_address: device.ip_address,
              // number_checkout: [99, 1, 2, 3, 4, 5, 99],
              active: device.active,
            },
          ],
        };
      }
      // checkin readers
      if (device.type === "Checkin Reader") {
        deviceRequest = {
          ...deviceRequest,
          checkin_readers: [
            ...deviceRequest.checkin_readers,
            {
              ...device,
              ip_address: device.ip_address,
              active: device.active,
            },
          ],
        };
      }
      // Self Checkout Reader
      if (device.type === "Self Checkout Reader") {
        deviceRequest = {
          ...deviceRequest,
          self_checkout_readers: [
            ...deviceRequest.self_checkout_readers,
            {
              ...device,
              ip_address: device.ip_address,
              active: device.active,
            },
          ],
        };
      }
      // instore_readers_without_screen
      if (device.type === "Instore Reader Without Screen") {
        deviceRequest = {
          ...deviceRequest,
          instore_readers_without_screen: [
            ...deviceRequest.instore_readers_without_screen,
            {
              ...device,
              ip_address: device.ip_address,
              active: device.active,
            },
          ],
        };
      }
    });

    const saveDeviceList = await sendApiRequest("stores/" + storeId + "/devices/save", deviceRequest, "POST");
    //evaluate api response
    if (typeof saveDeviceList.success !== "undefined" && saveDeviceList.success === true) {
      toast("Devices saved successfully", { type: "success" });

      // this function will reset and fetch devices from backend to get updated data.
      discardChanges(storeId);
      store.dispatch(
        updateStateData({
          locked_ip: true,
        })
      );
      return {
        success: true,
      };
    } else {
      toast("Sorry, something went wrong", { type: "error" });
      return {
        success: false,
      };
    }
  }
};

// fetch device list from backend
export const fetchDeviceList = async (storeId) => {
  const fetchDevices = await sendApiRequest("stores/" + storeId + "/devices", {}, "GET");
  //evaluate api response
  if (typeof fetchDevices.success !== "undefined" && fetchDevices.success === true) {
    let deviceInfoObj = {};
    let deviceListInfo = [];
    const staticResponse = fetchDevices.response;

    // set store ip info and device count info
    const splittedStoreIp = staticResponse.store_ip.split(".");
    deviceInfoObj = {
      store_ip_info: {
        second_octet: splittedStoreIp[1],
        third_octet: splittedStoreIp[2],
      },
      db_store_ip: {
        second_octet: splittedStoreIp[1],
        third_octet: splittedStoreIp[2],
        store_ip: staticResponse.store_ip,
      },
      device_count_info: {
        screen_area_reader: staticResponse.screens.length,
        checkout_reader: staticResponse.checkout_readers.length,
        checkin_reader: staticResponse.checkin_readers.length,
        self_checkout_reader: staticResponse.self_checkout_readers.length,
        instore_reader_without_screen: staticResponse.instore_readers_without_screen.length,
      },
      db_device_info: {
        screens: staticResponse.screens,
        checkout_readers: staticResponse.checkout_readers.sort((next, prev) => {
          var nextIpOctets = next.ip_address.split(".");
          var prevIpOctets = prev.ip_address.split(".");
          var nextIPLastOctet = parseInt(nextIpOctets[nextIpOctets.length - 1]);
          var prevIPLastOctet = parseInt(prevIpOctets[prevIpOctets.length - 1]);
          return nextIPLastOctet - prevIPLastOctet;
        }),
        checkin_readers: staticResponse.checkin_readers,
        self_checkout_readers: staticResponse.self_checkout_readers,
        instore_readers_without_screen: staticResponse.instore_readers_without_screen,
      },
    };
    // screens info
    if (typeof staticResponse.screens !== "undefined") {
      let screenReaders = [];
      staticResponse.screens.forEach((screenInfo) => {
        const lastOctetForScreen = screenInfo.ip_address.split(".");
        const lastOctetForAreaReader = screenInfo.area_reader.ip_address.split(".");

        screenReaders = [
          ...screenReaders,
          {
            id: screenInfo.id,
            type: screenInfo.type,
            store_ip: staticResponse.store_ip,
            ip_address: screenInfo.ip_address,
            last_octet: lastOctetForScreen[3],
            active: screenInfo.active,
            color_code: "#fff",
            area_reader: {
              type: "Area Reader",
              id: screenInfo.area_reader.id,
              store_ip: staticResponse.store_ip,
              ip_address: screenInfo.area_reader.ip_address,
              last_octet: lastOctetForAreaReader[3],
              active: screenInfo.area_reader.active,
            },
          },
        ].sort((a, b) => {
          return a.last_octet - b.last_octet;
        });
      });
      deviceListInfo = [...deviceListInfo, ...screenReaders];
    }
    // checkout readers
    if (typeof staticResponse.checkout_readers !== "undefined") {
      let checkoutReaders = [];
      staticResponse.checkout_readers.forEach((ReaderInfo) => {
        const lastOctetForCheckoutReader = ReaderInfo.ip_address.split(".");
        checkoutReaders = [
          ...checkoutReaders,
          {
            type: "Checkout Reader",
            id: ReaderInfo.id,
            store_ip: staticResponse.store_ip,
            ip_address: ReaderInfo.ip_address,
            last_octet: lastOctetForCheckoutReader[3],
            active: ReaderInfo.active,
            color_code: "#E6E6E6",
          },
        ].sort((a, b) => {
          return a.last_octet - b.last_octet;
        });
      });
      deviceListInfo = [...deviceListInfo, ...checkoutReaders];
    }
    // checkin reader
    if (typeof staticResponse.checkin_readers !== "undefined") {
      let checkInReaders = [];
      staticResponse.checkin_readers.forEach((ReaderInfo) => {
        const lastOctetForCheckInReader = ReaderInfo.ip_address.split(".");
        checkInReaders = [
          ...checkInReaders,
          {
            type: "Checkin Reader",
            id: ReaderInfo.id,
            store_ip: staticResponse.store_ip,
            ip_address: ReaderInfo.ip_address,
            last_octet: lastOctetForCheckInReader[3],
            active: ReaderInfo.active,
            color_code: "#fff",
          },
        ].sort((a, b) => {
          return a.last_octet - b.last_octet;
        });
      });
      deviceListInfo = [...deviceListInfo, ...checkInReaders];
    }
    // self checkout reader
    if (typeof staticResponse.self_checkout_readers !== "undefined") {
      let selfCheckoutReader = [];
      staticResponse.self_checkout_readers.forEach((ReaderInfo) => {
        const lastOctetForSelfCheckOutReader = ReaderInfo.ip_address.split(".");
        selfCheckoutReader = [
          ...selfCheckoutReader,
          {
            type: "Self Checkout Reader",
            id: ReaderInfo.id,
            store_ip: staticResponse.store_ip,
            ip_address: ReaderInfo.ip_address,
            last_octet: lastOctetForSelfCheckOutReader[3],
            active: ReaderInfo.active,
            color_code: "#E6E6E6",
          },
        ].sort((a, b) => {
          return a.last_octet - b.last_octet;
        });
      });
      deviceListInfo = [...deviceListInfo, ...selfCheckoutReader];
    }
    // instore reader without screen
    if (typeof staticResponse.instore_readers_without_screen !== "undefined") {
      let instoreReaders = [];
      staticResponse.instore_readers_without_screen.forEach((ReaderInfo) => {
        const lastOctetForInstoreReader = ReaderInfo.ip_address.split(".");
        instoreReaders = [
          ...instoreReaders,
          {
            type: "Instore Reader Without Screen",
            store_ip: staticResponse.store_ip,
            id: ReaderInfo.id,
            ip_address: ReaderInfo.ip_address,
            last_octet: lastOctetForInstoreReader[3],
            active: ReaderInfo.active,
            color_code: "#fff",
          },
        ].sort((a, b) => {
          return a.last_octet - b.last_octet;
        });
      });
      deviceListInfo = [...deviceListInfo, ...instoreReaders];
    }

    deviceInfoObj = {
      ...deviceInfoObj,
      device_list: deviceListInfo,
      db_device_count: deviceInfoObj.device_count_info,
      db_device_list: deviceListInfo,
    };

    store.dispatch(updateStateData(deviceInfoObj));

    return {
      success: true,
    };
  } else {
    // toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

export const fetchConfigureLocationData = (id) => {
  fetchStoreOverviewDetails(id).then((overviewResponse) => {
    fetchOpeningHours();
    fetchDeviceList(id).then((info) => {
      fetchCheckoutCounters(id);
      fetchCheckoutTypeList();
      fetchCheckoutMapping(id);
      fetchInfraNote(id);
      fetchCyreenNote(id);
    });
  });
};

//
export const reset_infrastructure_config_page_data = (id) => {
  store.dispatch(
    deleteItems([
      "device_list",
      "db_device_count",
      "store_overview",
      "db_device_info",
      "device_count_info",
      "store_ip_info",
      "db_store_ip",
      "checkout_count_info",
      "checkout_layout_list",
      "track_infra_changes",
      "infra_note",
      "infra_cyreen_note",
      "cyreen_note_id",
      "note_id",
      "multiplexer_device_ports",
      "assigned_multiplexer",
      "checkout_mapping",
      "new_store_loc_data",
      "checkout_type_list",
      "db_checkout_layout_list",
      "db_checkout_count_info",
      "db_device_list",
      "db_checkout_mapping",
      "temporary_checkout_info",
      "locked_ip",
    ])
  );

  if (typeof id !== "undefined" && id !== "") {
    fetchConfigureLocationData(id);
  }
};

export const discardChanges = (storeId) => {
  reset_infrastructure_config_page_data(storeId);
};

export const checkoutTypeId = (checkoutName) => {
  const userStateData = store.getState().userSelections;
  let checkoutTypeId = "";
  if (typeof userStateData.checkout_type_list !== "undefined") {
    userStateData.checkout_type_list.forEach((checkoutType) => {
      if (checkoutType.name === checkoutName) {
        checkoutTypeId = checkoutType.id;
      }
    });
  }
  return checkoutTypeId;
};

export const fetchCheckoutTypeList = async () => {
  const fetchList = await sendApiRequest("stores/counters/types", {}, "GET");
  //evaluate api response
  if (typeof fetchList.success !== "undefined" && fetchList.success === true) {
    store.dispatch(
      updateStateData({
        checkout_type_list: fetchList.response,
      })
    );

    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

export const fetchCheckoutCounters = async (storeId) => {
  const fetchCounters = await sendApiRequest("stores/" + storeId + "/counters", {}, "GET");
  //evaluate api response
  if (typeof fetchCounters.success !== "undefined" && fetchCounters.success === true) {
    let counterInfoInfoObj = [];

    fetchCounters.response.forEach((counterInfo) => {
      counterInfoInfoObj = [
        ...counterInfoInfoObj,
        {
          id: counterInfo.id,
          active: counterInfo.active,
          system_co_number: counterInfo.system_checkout_number,
          instore_co_number: counterInfo.store_checkout_number,
          checkout_type: counterInfo.checkout_type,
          checkout_type_id: counterInfo.checkout_type_id,
          description: counterInfo.description,
          cap_enabled: counterInfo.cap_enabled,
          active: counterInfo.active,
          edit_disabled: true,
        },
      ];
    });

    store.dispatch(
      updateStateData({
        checkout_layout_list: counterInfoInfoObj,
        checkout_count_info: counterInfoInfoObj.length,
        db_checkout_count_info: counterInfoInfoObj.length,
        db_checkout_layout_list: counterInfoInfoObj,
      })
    );

    // checkout counters are sorted based on instore number (by default ascending)
    sortCheckoutEntries(
      { instore_num: true, system_num: false },
      {
        instore_num: "asc",
      }
    );

    return {
      success: true,
    };
  } else {
    // toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

export const sortCheckoutEntries = (sortingParam, sortingMeasure) => {
  const userStateData = store.getState().userSelections;
  const activeRecords =
    typeof userStateData.checkout_layout_list !== "undefined" && userStateData.checkout_layout_list.length !== 0
      ? userStateData.checkout_layout_list.filter((checkoutObj) => {
        return checkoutObj.active === true ? true : false;
      })
      : [];
  const inActiveRecords =
    typeof userStateData.checkout_layout_list !== "undefined" && userStateData.checkout_layout_list.length !== 0
      ? userStateData.checkout_layout_list.filter((checkoutObj) => {
        return checkoutObj.active === false ? true : false;
      })
      : [];

  const sortedActiveList =
    activeRecords.length !== 0
      ? activeRecords.slice().sort((a, b) => {
        if (sortingParam.instore_num === true) {
          return sortingMeasure.instore_num === "asc"
            ? a.instore_co_number - b.instore_co_number
            : b.instore_co_number - a.instore_co_number;
        } else {
          //
          return sortingMeasure.system_num === "asc"
            ? a.system_co_number - b.system_co_number
            : b.system_co_number - a.system_co_number;
        }
      })
      : [];

  const sortedInActiveList =
    inActiveRecords.length !== 0
      ? inActiveRecords.slice().sort((a, b) => {
        if (sortingParam.instore_num === true) {
          return sortingMeasure.instore_num === "asc"
            ? a.instore_co_number - b.instore_co_number
            : b.instore_co_number - a.instore_co_number;
        } else {
          //
          return sortingMeasure.system_num === "asc"
            ? a.system_co_number - b.system_co_number
            : b.system_co_number - a.system_co_number;
        }
      })
      : [];

  store.dispatch(
    updateStateData({
      ...userStateData,
      checkout_layout_list: [...sortedActiveList, ...sortedInActiveList],
    })
  );
};

// to define checkout layout entries
export const defineCheckoutEntries = () => {
  const currentStateData = store.getState().userSelections;
  let checkoutEntries = [];
  const checkoutTypeName =
    typeof currentStateData.checkout_type_list !== "undefined"
      ? currentStateData.checkout_type_list[0].name
      : "regular checkout";
  const checkoutLayoutList =
    typeof currentStateData.checkout_layout_list !== "undefined" ? currentStateData.checkout_layout_list : [];

  let checkoutCounter =
    typeof currentStateData.temporary_checkout_info !== "undefined" ? currentStateData.temporary_checkout_info : 0;

  if (checkoutCounter !== 0) {
    // check existing count
    checkoutCounter =
      typeof currentStateData.checkout_count_info !== "undefined"
        ? parseInt(checkoutCounter) + parseInt(currentStateData.checkout_count_info)
        : parseInt(checkoutCounter);

    for (let index = 0; index < checkoutCounter; index++) {
      let checkoutObj = {};
      if (checkoutLayoutList.length !== 0 && typeof checkoutLayoutList[index] !== "undefined") {
        checkoutObj = {
          ...checkoutLayoutList[index],
        };
      } else {
        checkoutObj = {
          instore_co_number: "",
          system_co_number: "",
          checkout_type: checkoutTypeName,
          checkout_type_id: checkoutTypeId(checkoutTypeName),
          description: "",
          cap_enabled: true,
          active: true,
          edit_disabled: false,
        };
      }

      checkoutEntries.push(checkoutObj);
    }

    store.dispatch(
      updateStateData({
        checkout_layout_list: checkoutEntries,
        checkout_count_info: checkoutCounter,
        temporary_checkout_info: "",
      })
    );

    // sortCheckoutEntries(
    //   { instore_num: true, system_num: false },
    //   {
    //     instore_num: "asc",
    //   }
    // );
  }
};

// validate checkout layout entries
export const validateCheckoutEntries = () => {
  const currentStateData = store.getState().userSelections;
  let error = false;
  let errorDetails = {};
  let verifiedInstoreNum = [];
  let verifiedSystemNum = [];
  if (typeof currentStateData.checkout_layout_list !== "undefined" && currentStateData.checkout_layout_list.length !== 0) {
    currentStateData.checkout_layout_list.forEach((checkout, index) => {
      // validate instore checkout number
      const validateInstoreCheckout = validateNumbers(checkout.instore_co_number);
      const validateSystemCheckout = validateNumbers(checkout.system_co_number);

      // instore checkout
      if (validateInstoreCheckout.error === true) {
        error = true;
        errorDetails = {
          ...errorDetails,
          [index]: {
            ...errorDetails[index],
            instore_co_number: "Checkout Number cannot be negative, empty, or contain letters",
          },
        };
      } else {
        if (verifiedInstoreNum.includes(parseInt(checkout.instore_co_number))) {
          error = true;
          errorDetails = {
            ...errorDetails,
            [index]: {
              ...errorDetails[index],
              instore_co_number: "Each Checkout must have a unique In-Store Checkout Number",
            },
          };
        }
      }
      // system checkout
      if (validateSystemCheckout.error === true) {
        error = true;
        errorDetails = {
          ...errorDetails,
          [index]: {
            ...errorDetails[index],
            system_co_number: "Checkout Number cannot be negative, empty, or contain letters",
          },
        };
      } else {
        if (verifiedSystemNum.includes(parseInt(checkout.system_co_number))) {
          error = true;
          errorDetails = {
            ...errorDetails,
            [index]: {
              ...errorDetails[index],
              system_co_number: "Each Checkout must have a unique System Checkout Number",
            },
          };
        }
      }
      // description
      if (checkout.description !== "" && checkout.description !== null) {
        const validateDescription = validateAlphanumeric(checkout.description);
        if (validateDescription.error === true) {
          error = true;
          errorDetails = {
            ...errorDetails,
            [index]: {
              ...errorDetails[index],
              description: "Description may not have special characters",
            },
          };
        }
      }

      verifiedInstoreNum.push(parseInt(checkout.instore_co_number));
      verifiedSystemNum.push(parseInt(checkout.system_co_number));
    });
  }

  return {
    error,
    errorDetails,
  };
};

export const saveCheckoutLayoutInfo = async (storeId) => {
  const currentStateData = store.getState().userSelections;
  if (typeof currentStateData.checkout_layout_list !== "undefined" && currentStateData.checkout_layout_list.length !== 0) {
    let checkoutRequest = currentStateData.checkout_layout_list.map((checkout) => {
      return {
        ...checkout,
        checkout_type: checkout.checkout_type_id,
        store_checkout_number: parseInt(checkout.instore_co_number),
        system_checkout_number: parseInt(checkout.system_co_number),
        cap_enabled: checkout.cap_enabled,
        description: checkout.description,
        active: checkout.active, //default value for this field is true in database. it will be removed later. but needed for time being to make API work
      };
    });
    const saveCheckoutCounters = await sendApiRequest("stores/" + storeId + "/counters/save", checkoutRequest, "POST");
    //evaluate api response
    if (typeof saveCheckoutCounters.success !== "undefined" && saveCheckoutCounters.success === true) {
      toast("Checkout info saved successfully", { type: "success" });
      // this function will reset and fetch devices from backend to get updated data.
      discardCheckoutChanges(storeId);
      return {
        success: true,
      };
    } else {
      toast("Sorry, something went wrong", { type: "error" });
      return {
        success: false,
      };
    }
  }
};

export const discardCheckoutChanges = (storeId) => {
  reset_infrastructure_config_page_data(storeId);
};

export const checkSystemCheckoutNumber = (instoreNumber) => {
  const userStateData = store.getState().userSelections;
  let systemCoNumber = "";
  if (typeof userStateData.checkout_layout_list !== "undefined") {
    userStateData.checkout_layout_list.forEach((layoutInfo) => {
      if (layoutInfo.instore_co_number === instoreNumber) {
        systemCoNumber = layoutInfo.system_co_number;
      }
    });
  }

  return systemCoNumber;
};

export const saveCheckoutMapping = async (storeId) => {
  const userStateData = store.getState().userSelections;

  const checkoutMappingPorts =
    typeof userStateData.multiplexer_device_ports !== "undefined" ? userStateData.multiplexer_device_ports : [];

  const checkoutMappingInfo = typeof userStateData.checkout_mapping !== "undefined" ? userStateData.checkout_mapping : {};
  let mappingRequestBody = {};
  if (checkoutMappingPorts.length !== 0) {
    checkoutMappingPorts.forEach((mappingPort) => {
      let portObjs = {};
      mappingPort.device_ports.forEach((portName) => {
        portObjs = {
          ...portObjs,
          checkout_reader_id: mappingPort.checkout_reader_id,
          devices: {
            ...portObjs.devices,
            [portName.replace(": ", "_").replace(" ", "_")]:
              typeof checkoutMappingInfo[mappingPort.checkout_reader_id] !== "undefined" &&
                typeof checkoutMappingInfo[mappingPort.checkout_reader_id].devices !== "undefined" &&
                typeof checkoutMappingInfo[mappingPort.checkout_reader_id].devices[
                portName.replace(": ", "_").replace(" ", "_")
                ] !== "undefined" &&
                checkoutMappingInfo[mappingPort.checkout_reader_id].devices[portName.replace(": ", "_").replace(" ", "_")]
                  .instore_co_number !== "None"
                ? checkoutMappingInfo[mappingPort.checkout_reader_id].devices[portName.replace(": ", "_").replace(" ", "_")]
                : {
                  device_port: portName,
                  instore_co_number: 99,
                },
          },
        };
      });
      mappingRequestBody = {
        ...mappingRequestBody,
        [mappingPort.checkout_reader_id]: portObjs,
      };
    });
    // send request to save info
    const saveMapping = await sendApiRequest("stores/" + storeId + "/checkout-mapping/save", mappingRequestBody, "POST");
    //evaluate api response
    if (typeof saveMapping.success !== "undefined" && saveMapping.success === true) {
      toast("Checkout mapping saved successfully", { type: "success" });
      trackChanges(false);
      // this function will reset and fetch devices from backend to get updated data.
      discardCheckoutChanges(storeId);
      return {
        success: true,
      };
    } else {
      toast("Sorry, something went wrong", { type: "error" });
      return {
        success: false,
      };
    }
  } else {
    toast("Sorry, please provide sufficient data", { type: "error" });
    return {
      success: false,
    };
  }
};

export const addMappingEntries = () => {
  const userStateData = store.getState().userSelections;
  let mappingEntries = [];
  if (
    typeof userStateData.db_device_info !== "undefined" &&
    typeof userStateData.db_device_info.checkout_readers !== "undefined"
  ) {
    userStateData.db_device_info.checkout_readers.forEach((checkoutReader, index) => {
      let selectedMultiplexerOption =
        typeof userStateData.assigned_multiplexer["checkout_" + checkoutReader.id] !== "undefined"
          ? userStateData.assigned_multiplexer["checkout_" + checkoutReader.id]
          : [];
      let multiplexerOffsets = [];
      selectedMultiplexerOption.forEach((portValue) => {
        multiplexerOffsets = [...multiplexerOffsets, ...mappingOffsets[portValue]];
      });
      //  multiplexerOffsets = mappingOffsets[selectedMultiplexerOption];
      mappingEntries = [
        ...mappingEntries,
        {
          device_ports: [...["ANT1", "ANT2", "ANT3", "ANT4"], ...multiplexerOffsets],
          checkout_reader_ip: checkoutReader.ip_address,
          checkout_reader_id: checkoutReader.id,
        },
      ];
    });
    store.dispatch(
      updateStateData({
        multiplexer_device_ports: mappingEntries.sort((next, prev) => {
          var nextIpOctets = next.checkout_reader_ip.split(".");
          var prevIpOctets = prev.checkout_reader_ip.split(".");
          var nextIPLastOctet = parseInt(nextIpOctets[nextIpOctets.length - 1]);
          var prevIPLastOctet = parseInt(prevIpOctets[prevIpOctets.length - 1]);
          return nextIPLastOctet - prevIPLastOctet;
        }),
      })
    );

    return {
      success: true,
      mappingEntries,
    };
  } else {
    return {
      success: false,
    };
  }
};

export const fetchCheckoutMapping = async (storeId) => {
  const fetchMapping = await sendApiRequest("stores/" + storeId + "/checkout-mapping", {}, "GET");
  //evaluate api response
  if (typeof fetchMapping.success !== "undefined" && fetchMapping.success === true) {
    let assignedMultiplexers = {};
    if (typeof fetchMapping.response !== "undefined" && Object.keys(fetchMapping.response).length !== 0) {
      Object.keys(fetchMapping.response).forEach((mappingKey) => {
        assignedMultiplexers = {
          ...assignedMultiplexers,
          ["checkout_" + mappingKey]:
            typeof fetchMapping.response[mappingKey].multiplexer !== "undefined" &&
              fetchMapping.response[mappingKey].multiplexer.length !== 0
              ? fetchMapping.response[mappingKey].multiplexer
              : ["No Multiplexer"],
        };
      });
    }
    // selected checkout nums
    let selectedCheckoutNums = [];
    if (Object.keys(fetchMapping.response).length !== 0) {
      Object.values(fetchMapping.response).forEach((mappingInfo) => {
        if (typeof mappingInfo.devices !== "undefined") {
          Object.values(mappingInfo.devices).forEach((portInfo) => {
            selectedCheckoutNums.push(portInfo.instore_co_number);
          });
        }
      });
    } else {
      selectedCheckoutNums = [];
    }
    store.dispatch(
      updateStateData({
        checkout_mapping: fetchMapping.response,
        assigned_multiplexer: assignedMultiplexers,
        db_checkout_mapping: fetchMapping.response,
        selected_checkout_nums: [...selectedCheckoutNums],
      })
    );

    addMappingEntries();

    return {
      success: true,
    };
  } else {
    // toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

export const saveInfraNote = async (noteText, storeId, note_id, type = "normal") => {
  // send request to save info
  let notesRequestBody = { notes: noteText, type: type };
  if (note_id !== "") {
    notesRequestBody = {
      ...notesRequestBody,
      id: note_id,
    };
  }
  const saveNote = await sendApiRequest("stores/" + storeId + "/notes/save", notesRequestBody, "POST");
  //evaluate api response
  if (typeof saveNote.success !== "undefined" && saveNote.success === true) {
    toast("Note saved successfully", { type: "success" });
    // this function will reset and fetch new data from backend to get updated data.
    discardCheckoutChanges(storeId);
    return {
      success: true,
    };
  } else {
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

export const fetchInfraNote = async (storeId) => {
  // send request to save info
  const fetchNote = await sendApiRequest("stores/" + storeId + "/notes", {}, "GET");
  //evaluate api response
  if (typeof fetchNote.success !== "undefined" && fetchNote.success === true) {
    if (typeof fetchNote.response.notes !== "undefined") {
      store.dispatch(
        updateStateData({
          infra_note: fetchNote.response.notes,
          note_id: fetchNote.response.id,
        })
      );
    }

    return {
      success: true,
    };
  } else {
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

export const fetchCyreenNote = async (storeId) => {
  // send request to save info
  const fetchNote = await sendApiRequest("stores/" + storeId + "/notes/cyreen", {}, "GET");
  //evaluate api response
  if (typeof fetchNote.success !== "undefined" && fetchNote.success === true) {
    if (typeof fetchNote.response.notes !== "undefined") {
      store.dispatch(
        updateStateData({
          infra_cyreen_note: fetchNote.response.notes,
          cyreen_note_id: fetchNote.response.id,
        })
      );
    }

    return {
      success: true,
    };
  } else {
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

// to decide whether counter text will be visible on checkout layout section and confirmation popup while saving mapping details
export const checkCounterTextToShow = () => {
  const userStateData = store.getState().userSelections;
  const checkoutCounterObj =
    typeof userStateData.checkout_layout_list !== "undefined" ? userStateData.checkout_layout_list : [];
  let showtext = false;
  if (checkoutCounterObj.length !== 0) {
    checkoutCounterObj.forEach((counterObj) => {
      if (counterObj.active === true && counterObj.cap_enabled === true) {
        if (
          typeof userStateData.selected_checkout_nums !== "undefined" &&
          !userStateData.selected_checkout_nums.includes(counterObj.instore_co_number)
        )
          showtext = true;
      }
    });
  }
  return showtext;
};

export const duplicateIpCheck = async (storeId) => {
  const userStateData = store.getState().userSelections;
  // send request to save info
  const checkDuplicacy = await sendApiRequest(
    "stores/validate-ip/" + storeId,
    {
      store_ip: "10." + userStateData.store_ip_info.second_octet + "." + userStateData.store_ip_info.third_octet,
    },
    "POST"
  );

  //evaluate api response
  if (typeof checkDuplicacy.success !== "undefined" && checkDuplicacy.success === true) {
    if (typeof checkDuplicacy.response !== "undefined" && checkDuplicacy.response.success === false) {
      return {
        error: true,
        message: "Duplicate IP",
      };
    } else {
      return {
        error: false,
        message: "IP is valid",
      };
    }
  } else {
    return {
      error: false,
    };
  }
};
