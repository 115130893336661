import React, { useState } from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import { useDispatch, useSelector } from "react-redux";
import { clearfilters, downloadCampaignData, fetchCampaign, loadCampaigns } from "../../../api_calls/campaignRequests";
import {
  HtmlTooltip,
  HtmlTooltipDisabled,
  IconContainer,
  StyledDarkRedInformationTypography,
  StyledStack,
  ThumbnailText,
  ThumbnailWithText,
} from "../../../components/commonComponents/StyledComponents/styled";
import ProgressBar from "../../../components/commonComponents/Progress/ProgressBar";
import {
  AddShoppingCart,
  TrendingUp,
  Edit,
  HourglassTop,
  RoomService,
  Discount,
  Block,
  Visibility,
  Rule,
  QueryStats,
  IosShare,
  DisplaySettings,
  Download,
} from "@mui/icons-material";
import ActionButton, { DarkGreyButton, LightGreyButton } from "../../../components/commonComponents/Buttons/ActionButton";
import DisabledButton, { DisabledGreyButton } from "../../../components/commonComponents/Buttons/DisabledButton";
import {
  checkUserDetailsFromCookie,
  checkUserPermissions,
  checkUserRole,
  pageAccessAllowed,
} from "../../../api_calls/userManagement";
import { useNavigate } from "react-router-dom";
import AddProducts from "../ModalContent/AddProduct";
import AddNewMarketingActivity from "../ModalContent/AddMarketingActivity";
import AddNewCampaign from "../ModalContent/AddCampaign";
import ProductList from "../ModalContent/ProductsList";
import { fetchRequiredDetailsBeforePopup } from "../../../api_calls/marketingActivities";
import MarketingActivitiesList from "./MarketingActivitiesList";
import { resetCampaignSheetData, resetReportShareData } from "../../../api_calls/campaignSheet";
import { requestAnalyticsReport } from "../../../api_calls/campaigns";
import ShareReport from "../ModalContent/ShareReport";
import LoadMoreButton from "../../../components/commonComponents/LoadMore/LoadMoreButton";
import { loadMoreData } from "../../../api_calls/utils";
import { updateStateData } from "../../../Store/actions";
import platformConfig from "../../../platformConfig";
import { resetMarketingActivitiesData } from "../../../api_calls/marketingActivities";
import BrandReportFilter from "../ModalContent/BrandReportFilter";
import { fetchBrandFilters } from "../../../api_calls/brandReport";
import ReportSectionSettings from "../ModalContent/ReportSectionSettings";
import { fetchAvailableToRetailerIds } from "../../../api_calls/companyRequests";
import CampaignActions from "./campaignActions";
import NewProductModal from "../ModalContent/NewProductModal";

const CampaignList = () => {
  const campaignDetails = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const navigate = useNavigate();
  const userRole = checkUserRole();
  const downloadDataOptions = {
    "Impressions Data": 1,
    "Conversion Data - Stores": 3,
    "Conversion Data - Total": 2,
    "All Campaign Data": 4,
  };
  let listItems =
    typeof campaignDetails.campaign_list_filter_applied !== "undefined" &&
      campaignDetails.campaign_list_filter_applied === true
      ? campaignDetails.filtered_campaign_list.all_campaigns
      : typeof campaignDetails.campaign_list !== "undefined"
        ? campaignDetails.campaign_list.all_campaigns
        : [];
  let campaignListitems =
    listItems.length !== 0 ? listItems.slice(0, campaignDetails.initial_load_count_for_campaigns) : [];

  const checkRequestAccess = pageAccessAllowed(["campaign_reports_performance_can_request"], []);
  const checkEditAccess = pageAccessAllowed(["campaign_products_can_edit", "campaign_activities_can_edit", "campaign_list_can_edit", "campaign_can_share", "campaign_can_download", "campaign_reports_settings_can_view"], []);
  const isActionColumnAccessible = checkEditAccess;

  const checkUserDetails = checkUserDetailsFromCookie();
  const allowedAccessRights = checkUserPermissions();
  const permissionScope = allowedAccessRights.allowed_access;

  let userDetailObj = {};
  if (checkUserDetails.success === true) {
    userDetailObj = checkUserDetails.user_detail;
  }
  const [userInfo, setUserInfo] = useState(userDetailObj);
  const [popoverOpen, setPopoverOpen] = useState(null);
  const [options, setOptions] = useState([]);
  const [availableToList, setAvailableToList] = useState([]);
  const [downloadOptions, setDownloadOptions] = useState(false);
  const [openModal, setOpenModal] = useState({
    products: false,
    activities: false,
    activity_list: false,
    data: false,
    edit_campaign: false,
    product_list: false,
    share_report: false,
  });
  const [campaignInfo, setCampaignInfo] = useState({});

  //initially only 10 results will be loaded, further 10 will be loaded on click of load more button
  const loadMore = () => {
    loadMoreData("initial_load_count_for_campaigns");
  };

  // to display the action items in popover when an icon is clicked.
  const handlePopoverOpen = (event, newOptions) => {
    setOptions(newOptions);
    setPopoverOpen(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(null);
    setDownloadOptions(false);
  };

  // to handle the action when a specific icon is click from the action list.
  const handleOptionClick = (option) => {
    if (option === "Add new product") {
      setOpenModal({
        ...openModal,
        products: true,
      });
    }

    if (option === "Add new activity" || option === "List activities") {
      fetchRequiredDetailsBeforePopup(campaignInfo, navigate).then((info) => {
        setTimeout(() => {
          setOpenModal({
            ...openModal,
            activity_list: option === "List activities" ? true : false,
            activities: option === "Add new activity" ? true : false,
          });
        }, 1000);
      });
    }

    if (option === "List products") {
      setOpenModal({
        ...openModal,
        product_list: true,
      });
    }

    if (option === "Request Report") {
    }
    // download data option click
    if (Object.keys(downloadDataOptions).includes(option)) {
      downloadCampaignData(campaignInfo.campaign_name, downloadDataOptions[option]);
    }
    handlePopoverClose();
  };

  // return markup for performance available button (so many conditions making it complex to define in jsx, so defining a separate function to return the button wherever needed.)
  const performanceButtonMarkup = (campaign) => {
    return (
      <>
        {/* ------- no action can be performed --------  */}
        {[0, 1].includes(campaign.campaign_status_code) && (
          <>
            {checkRequestAccess.success === true ? (
              <StyledStack direction="row" spacing={2}>
                <DisabledButton
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  label={"Request"}
                  icon={<RoomService />}
                  ariaText={"report can not be requested for " + campaign.campaign_name}
                  toolTipContent={
                    <List>
                      <ListItem>Please add analytics products before requesting the report</ListItem>
                    </List>
                  }
                />
              </StyledStack>
            ) : (
              <StyledStack direction="row" spacing={2} sx={{ marginTop: "15px" }}>
                {/* ------- no action can be performed --------  */}
                <HtmlTooltip title={"No access"}>
                  <IconContainer>
                    <Block sx={{ color: "#AF3241" }} />
                  </IconContainer>
                </HtmlTooltip>
              </StyledStack>
            )}
          </>
        )}
        {/* ------- request report button >> product data is available ------ */}
        {campaign.campaign_status_code === 3 && (
          <>
            {checkRequestAccess.success === true ? (
              <StyledStack direction="row" spacing={2}>
                <DarkGreyButton
                  onClick={(e) => {
                    e.preventDefault();
                    // handlePopoverOpen(e, ["Request Report"]);
                    requestAnalyticsReport(campaign.campaign_id, campaign.campaign_name, campaign.retailer_company_id);
                  }}
                  label={"Request"}
                  ariaText={"report can be requested for " + campaign.campaign_name}
                  icon={<RoomService sx={{ color: "#fff" }} />}
                />
              </StyledStack>
            ) : (
              <StyledStack direction="row" spacing={2} sx={{ marginTop: "15px" }}>
                {/* ------- no action can be performed --------  */}
                <HtmlTooltip title={"No access"}>
                  <IconContainer>
                    <Block sx={{ color: "#AF3241" }} />
                  </IconContainer>
                </HtmlTooltip>
              </StyledStack>
            )}
          </>
        )}

        {/* ---- If data is processed and visualization also done >> show Report available button */}
        {campaign.campaign_status_code === 4 && (
          <StyledStack direction="row" spacing={2}>
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                navigate("/check-campaigns/campaign-analytics/" + campaign.campaign_id);
              }}
              label={"Available"}
              ariaText={"report available for " + campaign.campaign_name}
              icon={<TrendingUp sx={{ color: "#fff" }} />}
            />
          </StyledStack>
        )}
        {/* ------------ Report requested button -------- */}
        {campaign.campaign_status_code === 2 && (
          <StyledStack direction="row" spacing={2}>
            <DisabledGreyButton
              onClick={(e) => {
                e.preventDefault();
              }}
              label={"Requested"}
              ariaText={"report requested for " + campaign.campaign_name}
              icon={<HourglassTop sx={{ color: "#fff" }} />}
              toolTipContent={
                <List>
                  <ListItem>Your report is being prepared. This takes a maximum of one working day.</ListItem>
                </List>
              }
            />
          </StyledStack>
        )}
        {/* ---- completed campaigns, for which reporting not needed anymore ---- */}
        {campaign.campaign_status_code === 5 && (
          <StyledStack direction="row" spacing={2}>
            <Typography variant="body2">__</Typography>
          </StyledStack>
        )}
      </>
    );
  };

  React.useEffect(() => {
    loadCampaigns().then((res) => {
      fetchAvailableToRetailerIds();
    });

    dispatchAction(
      updateStateData({
        initial_load_count_for_campaigns: platformConfig.initial_load_count,
      })
    );
    clearfilters();
  }, []);

  return campaignListitems.length !== 0 ? (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          {/* ------ header row ------ */}
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="text-wrapper">
                #
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Name
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Progress
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Owner
              </StyledTableCell>
              {/* <StyledTableCell align="left" className="text-wrapper">
                Optimization
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Location
              </StyledTableCell> */}
              {/* {checkEditAccess.success === true && ( */}
              {/* ------- actions will be hidden for brand ------- */}
              {isActionColumnAccessible.success === true && (
                <StyledTableCell align="left" className="text-wrapper">
                  Action
                </StyledTableCell>
              )}
              {/* ------ impressions report column ------ */}
              {permissionScope.campaign_reports_impression_can_view === true && (
                <StyledTableCell align="left" className="text-wrapper">
                  Impressions
                </StyledTableCell>
              )}
              {/* ------ performance report column ------ */}
              {permissionScope.campaign_reports_performance_can_view === true && (
                <StyledTableCell align="left" className="text-wrapper">
                  Performance
                </StyledTableCell>
              )}

            </TableRow>
          </TableHead>
          <TableBody>
            {campaignListitems.map((campaign, index) => {
              return (
                <TableRow key={index + "_campaign_row"}>
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    {index + 1}
                  </StyledTableCell>
                  {/* ------ campaign name --------- */}
                  <StyledTableCell
                    aria-label={campaign.campaign_name}
                    component="td"
                    scope="row"
                    className="text-wrapper wrapLargeText"
                  >
                    <ThumbnailWithText>
                      {/* <ThumbnailImg src={campaign.img_src} alt={`Thumbnail for ${campaign.campaign_name}`} /> */}
                      <ThumbnailText>{campaign.campaign_name}</ThumbnailText>
                    </ThumbnailWithText>
                  </StyledTableCell>
                  {/* ------- campaign progress --------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    <ProgressBar startDate={campaign.campaign_start_date} endDate={campaign.campaign_end_date} />
                  </StyledTableCell>
                  {/* ---------- company retailer ---------- */}
                  <StyledTableCell component="td" scope="row" align="center">
                    <StyledStack spacing={1} direction={"row"}>
                      {campaign.retailer_company_name}
                    </StyledStack>
                  </StyledTableCell>
                  {/* ---------- Actions ------ */}
                  {campaignDetails && isActionColumnAccessible.success === true && (
                      <CampaignActions
                        campaign={campaign}
                        checkEditAccess={checkEditAccess}
                        campaignDetails={campaignDetails}
                        userInfo={userInfo}
                        userRole={userRole}
                        checkRequestAccess={checkRequestAccess}
                        handlePopoverOpen={handlePopoverOpen}
                        setCampaignInfo={setCampaignInfo}
                        setOpenModal={setOpenModal}
                        fetchCampaign={fetchCampaign}
                        setDownloadOptions={setDownloadOptions}
                        downloadDataOptions={downloadDataOptions}
                      />
                    )}

                  {/* ------ Impressions ------ */}
                  {
                    permissionScope.campaign_reports_impression_can_view === true && (
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction="row" spacing={2}>
                          {campaign.campaign_status !== "Scheduled" ? (
                            <>
                              {campaign.impression_report_status === 1 && (
                                <ActionButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/check-campaigns/campaign-analytics/" + campaign.campaign_id);
                                  }}
                                  ariaText={"impressions available for " + campaign.campaign_name}
                                  label={"Available"}
                                  icon={<Visibility />}
                                />
                              )}
                              {campaign.impression_report_status === 0 && (
                                <DisabledButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                  label={"Preparing"}
                                  icon={<Rule />}
                                  ariaText={"impressions awaited for " + campaign.campaign_name}
                                  toolTipContent={
                                    <List>
                                      <ListItem>Preparing your impressions reports. Will be available soon.</ListItem>
                                    </List>
                                  }
                                />
                              )}

                              {/* ---- completed campaigns, for which reporting not needed anymore ---- */}
                              {campaign.impression_report_status === 2 && <Typography variant="body2">__</Typography>}
                            </>
                          ) : (
                            <Typography variant="body2">__</Typography>
                          )}
                        </StyledStack>
                      </StyledTableCell>
                    )}
                  {/* ---------- Performance ----------- */}
                  {
                    permissionScope.campaign_reports_performance_can_view === true && (
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        {campaign.campaign_status !== "Scheduled" ? (
                          <>
                            {typeof userRole.user_role !== "undefined" && userRole.user_role.toLowerCase() === "brand" ? (
                              performanceButtonMarkup(campaign)
                            ) : typeof userInfo.current_workspace !== "undefined" &&
                              (userInfo.current_workspace.name === campaign.retailer_company_name ||
                                userInfo.user_type === "cyreen") ? (
                              performanceButtonMarkup(campaign)
                            ) : (
                              <StyledStack direction="row" spacing={2}>
                                <Typography variant="body2">__</Typography>
                              </StyledStack>
                            )}
                          </>
                        ) : (
                          <StyledStack direction="row" spacing={2}>
                            <Typography variant="body2">__</Typography>
                          </StyledStack>
                        )}
                      </StyledTableCell>
                    )
                  }

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* --------- record count info --------- */}
      <Box mt={2} id="infoText">
        <StyledDarkRedInformationTypography variant="body2">
          Showing {campaignListitems.length} out of {listItems.length} results.
        </StyledDarkRedInformationTypography>
      </Box>
      {/* ---------- Load more button ----------- */}
      <Box height={20} />
      {campaignListitems.length < listItems.length && <LoadMoreButton clickAction={loadMore} />}

      {/* ======== popover for the action items list =========== */}
      <Popover
        open={Boolean(popoverOpen)}
        anchorEl={popoverOpen}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          {options.map((option, index) => (
            <div key={index + "_popoverOptions"}>
              {downloadOptions === true ? (
                // some buttons will be disabled if performance report is not yet generated
                <ListItemButton
                  disabled={
                    ![4, 5].includes(campaignInfo.campaign_status_code) &&
                      ["Conversion Data - Stores", "Conversion Data - Total"].includes(option)
                      ? true
                      : false
                  }
                  key={index}
                  onClick={() => handleOptionClick(option)}
                >
                  <ListItemText primary={option} />
                </ListItemButton>
              ) : (
                <ListItemButton key={index} onClick={() => handleOptionClick(option)}>
                  <ListItemText primary={option} />
                </ListItemButton>
              )}
            </div>
          ))}
        </List>
      </Popover>
      {/* -------- add products modal --------- */}
      {openModal.products === true && (
        <NewProductModal
          open={openModal.products}
          onClose={() => {
            setOpenModal({
              ...openModal,
              products: false,
            });
            /*
                reset the campaign sheet data (process of creating an adlevel will start all over again 
                if user comes back from the upload campaign sheet page and revisit the page for a 
                different campaign or even for same campaign.
              */
            resetCampaignSheetData();
          }}
          size={"xl"}
          closeModal={() => {
            setOpenModal({
              ...openModal,
              products: false,
            });
            /*
                reset the campaign sheet data (process of creating an adlevel will start all over again 
                if user comes back from the upload campaign sheet page and revisit the page for a 
                different campaign or even for same campaign.
              */
            resetCampaignSheetData();
          }}
          usedFor={"add"}
          campaignRecord={campaignInfo}
        />
      )}
      {/*  products listing modal */}
      {openModal.product_list === true && (
        <ProductList
          open={openModal.product_list}
          onClose={() => {
            setOpenModal({
              ...openModal,
              product_list: false,
            });
            resetCampaignSheetData();
          }}
          size={"xl"}
          closeModal={() => {
            setOpenModal({
              ...openModal,
              product_list: false,
            });
            resetCampaignSheetData();
          }}
          campaignRecord={campaignInfo}
        />
      )}
      {/* ------- marketing activities list --------- */}
      {openModal.activity_list === true && (
        <MarketingActivitiesList
          open={openModal.activity_list}
          onClose={() => {
            setOpenModal({
              ...openModal,
              activity_list: false,
            });
            resetMarketingActivitiesData();
          }}
          size={"xl"}
          closeModal={() => {
            setOpenModal({
              ...openModal,
              activity_list: false,
            });
            resetMarketingActivitiesData();
          }}
          campaignRecord={campaignInfo}
        />
      )}
      {/* ----------- marketing activities popup --------- */}
      {openModal.activities === true && (
        <AddNewMarketingActivity
          open={openModal.activities}
          onClose={() => {
            setOpenModal({
              ...openModal,
              activities: false,
            });
            resetMarketingActivitiesData();
          }}
          size={"md"}
          closeModal={() => {
            setOpenModal({
              ...openModal,
              activities: false,
            });
            resetMarketingActivitiesData();
          }}
          usedFor={"add"}
          campaignRecord={campaignInfo}
        />
      )}
      {/* --------- edit campaign modal --------- */}
      {openModal.edit_campaign === true && (
        <AddNewCampaign
          open={openModal.edit_campaign}
          onClose={() => {
            setOpenModal({
              ...openModal,
              edit_campaign: false,
            });
            resetCampaignSheetData();
          }}
          size={"md"}
          closeModal={() => {
            setOpenModal({
              ...openModal,
              edit_campaign: false,
            });
            resetCampaignSheetData();
          }}
          usedFor={"update"}
          campaignRecord={campaignInfo}
        />
      )}
      {/* ------- share report modal --------- */}
      {openModal.share_report === true && (
        <BrandReportFilter
          open={openModal.share_report}
          onClose={() => {
            setOpenModal({
              ...openModal,
              share_report: false,
            });
            resetReportShareData();
          }}
          size={"md"}
          closeModal={() => {
            setOpenModal({
              ...openModal,
              share_report: false,
            });
            resetReportShareData();
          }}
          usedFor={"update"}
          campaignRecord={campaignInfo}
        />
      )}

      {/* ------- report section modal ---------- */}
      {openModal.report_settings === true && (
        <ReportSectionSettings
          open={openModal.report_settings}
          onClose={() => {
            setOpenModal({
              ...openModal,
              report_settings: false,
            });
          }}
          size={"md"}
          closeModal={() => {
            setOpenModal({
              ...openModal,
              report_settings: false,
            });
          }}
          usedFor={"update"}
          campaignRecord={campaignInfo}
        />
      )}
    </Box>
  ) : (
    <Box>
      {typeof campaignDetails.loading !== "undefined" && campaignDetails.loading === true ? (
        <p>Please wait while we are loading results...</p>
      ) : (
        <p> {typeof campaignDetails.campaign_list_filter_applied !== "undefined" &&
          campaignDetails.campaign_list_filter_applied === true ? "No Results" : "No campaigns found"} </p>
      )}
    </Box>
  );
};

export default CampaignList;
