/*
    This file has all the functions used in the campaign sheet feature.
*/
import { updateStateData, deleteItems } from "../Store/actions";
import store from "../Store/store";
import { validateAlphabets, validateEmpty, validateNumbers } from "./utils";

export const restructure_campaign_sheet_data = (campaignData, characterLength = 10, campaign_id, retailer) => {
  let restructuredData = [];
  let filterKeywords = [];
  let refactoredKeywords = [];
  //traverse all the records from campaignData
  campaignData.forEach((campaign) => {
    if (campaign.description !== "") {
      //create a keyword using initials from the description
      const productKeyword = campaign.description.substring(0, characterLength);
      campaign = {
        ...campaign,
        description: replaceUmlaouts(campaign.description),
        keyword: productKeyword,
        campaign: campaign_id,
        retailer: retailer,
      };
      restructuredData.push(campaign);
      filterKeywords.push(productKeyword);
    }
  });

  //need to refactor filter keyword to fit react-select module
  removewithfilter(filterKeywords).forEach((keyword) => {
    if (keyword !== "") {
      refactoredKeywords.push({ value: keyword, label: keyword });
    }
  });

  //save to redux
  store.dispatch(
    updateStateData({
      campaign_sheet_records: restructuredData,
      sheet_filter_keywords: refactoredKeywords,
    })
  );

  return {
    restructuredData,
    filterKeywords: refactoredKeywords,
  };
};

//to return only the unique elements from an array
const removewithfilter = (arr) => {
  let outputArray = arr.filter(function (v, i, self) {
    // It returns the index of the first
    // instance of each value
    return i == self.indexOf(v);
  });

  return outputArray;
};

//filter sheet results and save to redux state
export const filterSheetResults = (appliedfilters) => {
  const sheetResults = store.getState().userSelections.campaign_sheet_records;
  let filteredRecords = [];
  sheetResults.forEach((sheetRecord) => {
    if (appliedfilters.includes(sheetRecord.description)) {
      filteredRecords.push(sheetRecord);
    }
  });

  store.dispatch(
    updateStateData({
      filtered_sheet_records: filteredRecords,
      filter_applied: true,
    })
  );
};

// filter product adlevels
export const filterAdlevelResults = (appliedfilters) => {
  const adlevelResults = store.getState().userSelections.created_adlevels;
  let filteredRecords = [];
  adlevelResults.forEach((adlevelRecord, key) => {
    if (appliedfilters.includes(adlevelRecord.nameAdLevel)) {
      filteredRecords = [...filteredRecords, { ...adlevelRecord, actual_key: key }];
    }
  });

  store.dispatch(
    updateStateData({
      filtered_adlevels: filteredRecords,
      adlevel_filter_applied: true,
    })
  );
};

//to uncheck all the checkboxes
export const checkUncheckAllCheckboxes = (action = true) => {
  let cbs = document.getElementsByTagName("input");

  for (let i = 0; i < cbs.length; i++) {
    if (cbs[i].type == "checkbox") {
      cbs[i].checked = action;
    }
  }
};

//replace umlaout characters with english words
export const replaceUmlaouts = (str) => {
  const umlautMap = {
    "\u00dc": "UE",
    "\u00c4": "AE",
    "\u00d6": "OE",
    "\u00fc": "ue",
    "\u00e4": "ae",
    "\u00f6": "oe",
    "\u00df": "ss",
  };
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp("[" + Object.keys(umlautMap).join("|") + "]", "g"), (a) => umlautMap[a]);
};

//to reset everything related to campaign sheet and campaigns
export const resetCampaignSheetData = () => {
  store.dispatch(
    deleteItems([
      "campaign_sheet_records",
      "filter_applied",
      "sheet_filter_keywords",
      "filtered_sheet_records",
      "created_adlevels",
      "campaign_detail",
      "new_campaign_data",
      "adlevel_sheet_data",
      "uploaded_sheet_errors",
      // "campaign_list",
      // "campaign_list_filters",
      // "filtered_campaign_list",
      // "campaign_list_filter_applied",
    ])
  );
  store.dispatch(
    updateStateData({
      initial_load_count_for_analyis_group: 10,
    })
  );
};

// to reset report share data
export const resetReportShareData = () => {
  store.dispatch(deleteItems(["report_share_data"]));
};

export const restructure_product_information = (productInfoResponse, campaign_id) => {
  let restructuredData = [];
  //traverse all the records from campaignData
  productInfoResponse.forEach((product, index) => {
    if (product.description !== "") {
      let restructureProductInfo = {
        description: product.description,
        gtin: product.gtin,
        nameAdLevel: product.name_adlevel,
        numberAdLevel: product.number_adlevel,
        gtinBundle: product.gtin_bundle,
        bundleSize: product.bundle_size,
        nameCompany: product.name_company,
        campaign: campaign_id,
        id: product.id,
        record_index: index,
      };
      restructuredData.push(restructureProductInfo);
    }
  });
  //sort the data in ascending order
  // restructuredData = sortAdlevel(true, restructuredData);
  //save to redux
  store.dispatch(
    updateStateData({
      created_adlevels: restructuredData,
      pagination: {
        analysis_import_start: 0,
        analysis_import_end: 10,
      },
    })
  );

  return {
    restructuredData,
  };
};

export const fetch_adlevel_names = (productInfoResponse) => {
  let restructuredData = [];
  let checkForDuplicates = [];
  //traverse all the records from campaignData
  productInfoResponse.forEach((product) => {
    if (product.description !== "" && !checkForDuplicates.includes(product.nameAdLevel)) {
      checkForDuplicates.push(product.nameAdLevel);
      restructuredData.push({ value: product.nameAdLevel, label: product.nameAdLevel, adlevel_number: product.numberAdLevel });
    }
  });

  //save to redux
  store.dispatch(
    updateStateData({
      adlevel_names: restructuredData.sort((next, prev) => {
        return next.adlevel_number - prev.adlevel_number
      }),
    })
  );

  return {
    restructuredData,
  };
};

export const rangeOfNumbers = (counter) => {
  return Array.from({ length: counter }, (e, i) => i);
};

// rearrange redux state when adlevels are updated with or without filters
export const reArrangeAdlevelRedux = (updatedAdlevelRecords, originalRecords) => {
  const sheetDetails = store.getState().userSelections;
  if (typeof sheetDetails.adlevel_filter_applied !== "undefined" && sheetDetails.adlevel_filter_applied === true) {
    store.dispatch(
      updateStateData({
        filtered_adlevels: updatedAdlevelRecords,
      })
    );
    //update original records as well otherwise the changes won't reflect on filter reset
    if (typeof originalRecords !== "undefined" && originalRecords.length !== 0) {
      store.dispatch(
        updateStateData({
          created_adlevels: originalRecords,
        })
      );
    }
  } else {
    store.dispatch(
      updateStateData({
        created_adlevels: updatedAdlevelRecords,
      })
    );
  }
};

//to sort the adlevel records according to adlevel number.
export const sortAdlevel = (sortMeasureType, listItems) => {
  let adlevelArray = listItems;
  adlevelArray = adlevelArray.slice().sort((next, prev) => {
    if (sortMeasureType === true) {
      return next.numberAdLevel - prev.numberAdLevel;
    } else {
      return prev.numberAdLevel - next.numberAdLevel;
    }
  });

  //update redux state
  reArrangeAdlevelRedux(adlevelArray, []);
};

export const fetchGtinList = () => {
  let gtinList = [];
  let gtinDescription = [];
  let checkForDuplicates = [];
  const adlevelRecords = store.getState().userSelections.created_adlevels;
  //traverse all the records from campaignData
  if (adlevelRecords.length !== 0) {
    adlevelRecords.forEach((adlevel) => {
      if (adlevel.gtin !== "" && !checkForDuplicates.includes(adlevel.gtin)) {
        checkForDuplicates.push(adlevel.gtin);
        gtinList.push({ value: adlevel.gtin, label: adlevel.description + " - " + adlevel.gtin });
      }
    });
  }

  //save to redux
  store.dispatch(
    updateStateData({
      gtin_list: gtinList,
    })
  );

  return {
    gtinList,
    gtinDescription,
  };
};

export const validate_adlevel_for_all_checks = (adlevelItem) => {
  let error = false;
  let message = "";
  let messagesObj = {};
  if (
    typeof adlevelItem.description === "undefined" ||
    (typeof adlevelItem.description !== "undefined" && adlevelItem.description === "")
  ) {
    error = true;
    message += message.length !== 0 ? ", " : "";
    message += "Article description must not be empty";
    messagesObj = {
      ...messagesObj,
      description: "Article description must not be empty",
    };
  }
  //validate gtin = it must be number
  const validateGtin = validateNumbers(typeof adlevelItem.gtin !== "undefined" ? adlevelItem.gtin : "");
  if (validateGtin.error === true) {
    error = true;
    message += message.length !== 0 ? ", " : "";
    message += "GTIN must not be empty and may not contain letters";
    messagesObj = {
      ...messagesObj,
      gtin: "GTIN must not be empty and may not contain letters",
    };
  }

  //validate adlevel number = must be number and not greater than 20
  const validateNumberAdlevel = validateNumbers(
    typeof adlevelItem.numberAdLevel !== "undefined" ? adlevelItem.numberAdLevel : ""
  );
  if (validateNumberAdlevel.error === true) {
    error = true;
    message += message.length !== 0 ? ", " : "";
    message += "Analysis group number must not be empty and may not contain letters";
    messagesObj = {
      ...messagesObj,
      numberAdLevel: "Analysis group number must not be empty and may not contain letters",
    };
  } else {
    // if (adlevelItem.numberAdLevel > 20) {
    //   error = true;
    //   message += message.length !== 0 ? ", " : "";
    //   message += "Analysis group number can not be greater than 20";
    //   messagesObj = {
    //     ...messagesObj,
    //     numberAdLevel: "Analysis group number can not be greater than 20",
    //   };
    // }
  }
  //validate adlevel name =  only alphabets
  const validateNameAdlevel = validateEmpty(typeof adlevelItem.nameAdLevel !== "undefined" ? adlevelItem.nameAdLevel : "");
  if (validateNameAdlevel.error === true) {
    error = true;
    message += message.length !== 0 ? ", " : "";
    message += "Analysis group name must not be empty";
    messagesObj = {
      ...messagesObj,
      nameAdLevel: "Analysis group name must not be empty",
    };
  }

  // validate bundle size
  if (typeof adlevelItem.bundleSize !== "undefined") {
    const validateBundleSize = validateNumbers(adlevelItem.bundleSize);
    if (validateBundleSize.error === true) {
      error = true;
      message += message.length !== 0 ? ", " : "";
      message += "Bundle size cannot be empty and may not contain letters";
      messagesObj = {
        ...messagesObj,
        bundleSize: "Bundle size cannot be empty and may not contain letters",
      };
    } else {
      if (parseInt(adlevelItem.bundleSize) === 0) {
        error = true;
        message += message.length !== 0 ? ", " : "";
        message += "Bundle size cannot be 0";
        messagesObj = {
          ...messagesObj,
          bundleSize: "Bundle size cannot be 0",
        };
      }
    }
  }

  if (error === true) {
    return {
      error: true,
      message: message,
      messagesObj: messagesObj,
    };
  } else {
    return {
      error: false,
      message: "validation passed",
      messagesObj: {},
    };
  }
};

export const validate_adlevel_accuracy = (recordTocompare, recordsToMatchFrom) => {
  let verifiedAdlevelNumber =
    typeof recordsToMatchFrom[0] !== "undefined"
      ? { [recordsToMatchFrom[0].numberAdLevel]: recordsToMatchFrom[0].nameAdLevel }
      : {};
  let verifiedAdlevelName =
    typeof recordsToMatchFrom[0] !== "undefined"
      ? {
        [recordsToMatchFrom[0].nameAdLevel.replaceAll(" ", "_")]: recordsToMatchFrom[0].numberAdLevel,
      }
      : {};
  // let verifiedAdlevelGTINCombination = {};

  // const sortedData = sortAdlevel(true, listItems);
  let error = false;

  // ================================================
  let messagesObj = {};
  let compareableAdlevelNameKey = recordTocompare.nameAdLevel.trim().replaceAll(" ", "_");
  let comparableAdlevelDesc = recordTocompare.description.trim().replaceAll(" ", "_");
  let comparableBundleSizeAdlevelDesc = "_" + recordTocompare.bundleSize;

  let compareableCombinationKeyWithDesc =
    comparableAdlevelDesc +
    "-" +
    recordTocompare.gtin +
    "-" +
    recordTocompare.numberAdLevel +
    "-" +
    recordTocompare.nameAdLevel.trim() +
    "_" +
    recordTocompare.bundleSize;

  let compareableCombinationKeyWithoutDesc =
    recordTocompare.gtin +
    "-" +
    recordTocompare.numberAdLevel +
    "-" +
    recordTocompare.nameAdLevel.trim() +
    "_" +
    recordTocompare.bundleSize;

  let productsObj = {
    [comparableAdlevelDesc]: parseInt(recordTocompare.gtin),
  };
  let gtinsObj = {
    [recordTocompare.gtin]: comparableAdlevelDesc,
  };

  let gtinsBundleObj = {
    [recordTocompare.gtin]: comparableBundleSizeAdlevelDesc,
  };

  // ==================================================
  recordsToMatchFrom.forEach((adlevelItem, index) => {
    let adlevelNameKey = adlevelItem.nameAdLevel.trim().replaceAll(" ", "_");
    let adlevelDesc = adlevelItem.description.trim().replaceAll(" ", "_");
    let bundleSizeAdlevelDesc = "_" + adlevelItem.bundleSize;
    let combinationKeyWithDesc =
      adlevelDesc +
      "-" +
      adlevelItem.gtin +
      "-" +
      adlevelItem.numberAdLevel +
      "-" +
      adlevelItem.nameAdLevel.trim() +
      "_" +
      adlevelItem.bundleSize;
    let combinationKeyWithoutDesc =
      adlevelItem.gtin + "-" + adlevelItem.numberAdLevel + "-" + adlevelItem.nameAdLevel + "_" + adlevelItem.bundleSize;
    //check if any adlevel is already verified or not.
    if (Object.keys(verifiedAdlevelNumber).length) {
      // Check if the combination of GTIN, numberAdLevel, and nameAdLevel already exists
      // "Duplicate GTIN within analysis group " +
      //       adlevelItem.numberAdLevel +
      //       ". Only allowed once within same analysis group"
      if (
        combinationKeyWithDesc === compareableCombinationKeyWithDesc
      ) {
        error = true;
        messagesObj = {
          ...messagesObj,
          bundleSize: "Duplicate row",
          duplicate: true,
        };
      } else {
        // verify duplicate product name with same gtin

        if (Object.keys(productsObj).includes(adlevelDesc)) {
          if (productsObj[adlevelDesc] !== parseInt(adlevelItem.gtin)) {
            error = true;
            messagesObj = {
              ...messagesObj,
              gtin: "A specific GTIN must not be associated with multiple Article Descriptions",
            };
          }
        } else {
          // products unique array with gtin values
          if (typeof productsObj[adlevelDesc] === "undefined") {
            productsObj = {
              ...productsObj,
              [adlevelDesc]: adlevelItem.gtin,
            };
          }
        }
        // verify duplicate gtin name with same prod
        if (Object.keys(gtinsObj).includes(adlevelItem.gtin.toString())) {
          if (gtinsObj[adlevelItem.gtin] !== adlevelDesc) {
            error = true;
            messagesObj = {
              ...messagesObj,
              description: "A specific Article Description must not be associated with multiple GTINs",
            };
          }
        } else {
          if (typeof gtinsObj[adlevelItem.gtin] === "undefined") {
            gtinsObj = {
              ...gtinsObj,
              [adlevelItem.gtin]: adlevelDesc,
            };
          }
        }

        // verify duplicate gtin name with same prod and bundle size
        if (Object.keys(gtinsBundleObj).includes(adlevelItem.gtin.toString())) {
          if (gtinsBundleObj[adlevelItem.gtin] !== bundleSizeAdlevelDesc) {
            error = true;
            messagesObj = {
              ...messagesObj,
              bundleSize: "A specific GTIN must not be associated with multiple Bundle Size values",
            };
          }
        } else {
          if (typeof gtinsObj[adlevelItem.gtin] === "undefined") {
            gtinsBundleObj = {
              ...gtinsBundleObj,
              [adlevelItem.gtin]: bundleSizeAdlevelDesc,
            };
          }
        }
        // check if the adlevel number is already verified
        if (Object.keys(verifiedAdlevelNumber).includes(recordTocompare.numberAdLevel.toString())) {
          // check the accuracy of the adlevel number and name
          if (verifiedAdlevelNumber[recordTocompare.numberAdLevel] !== recordTocompare.nameAdLevel.trim()) {
            error = true;
            messagesObj = {
              ...messagesObj,
              nameAdLevel: "An Analysis Group Name must not be associated with multiple Group Numbers.",
            };
          }
        } else if (Object.keys(verifiedAdlevelName).includes(compareableAdlevelNameKey)) {
          // check the accuracy of the adlevel number and name
          if (verifiedAdlevelName[compareableAdlevelNameKey] !== recordTocompare.numberAdLevel) {
            error = true;
            messagesObj = {
              ...messagesObj,
              numberAdLevel: "A Group Number must not be associated with multiple Analysis Group Names.",
            };
          }
        } else {
          // adlevel item doesn't exist in the already verified items, so add a new record to verified variables
          verifiedAdlevelNumber = {
            ...verifiedAdlevelNumber,
            [adlevelItem.numberAdLevel]: adlevelItem.nameAdLevel,
          };
          verifiedAdlevelName = {
            ...verifiedAdlevelName,
            [adlevelNameKey]: adlevelItem.numberAdLevel,
          };
        }
      }
    } else {
      //verified adlevel is empty, add first record
      verifiedAdlevelNumber = {
        ...verifiedAdlevelNumber,
        [adlevelItem.numberAdLevel]: adlevelItem.nameAdLevel,
      };
      verifiedAdlevelName = {
        ...verifiedAdlevelName,
        [adlevelNameKey]: adlevelItem.numberAdLevel,
      };
      // products unique array with gtin values
      if (typeof productsObj[adlevelDesc] === "undefined") {
        productsObj = {
          ...productsObj,
          [adlevelDesc]: adlevelItem.gtin,
        };
      }
      if (typeof gtinsObj[adlevelItem.gtin] === "undefined") {
        gtinsObj = {
          ...gtinsObj,
          [adlevelItem.gtin]: adlevelDesc,
        };
      }
    }
  });

  return { error, messagesObj };
};
