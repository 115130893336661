import React from "react";
import { Box, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Add, ArrowDropDown, ArrowDropUp, Delete, Edit, PeopleAlt } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  HtmlTooltip,
  HtmlTooltipDisabled,
  IconContainer,
  StyledDarkRedInformationTypography,
  StyledMainBox,
  StyledStack,
} from "../../../components/commonComponents/StyledComponents/styled";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import CompanyListFilter from "../filters/CompanyListFilter";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import LoadMoreButton from "../../../components/commonComponents/LoadMore/LoadMoreButton";
import AddUser from "../modalContent/AddUser";
import AccessControl from "../../../components/commonComponents/Hoc/AccessControl";
import { deleteItems, updateStateData } from "../../../Store/actions";
import platformConfig from "../../../platformConfig";
import { loadMoreData } from "../../../api_calls/utils";
import { checkUserDetailsFromCookie, checkUserPermissions, deleteUser, fetchRoles } from "../../../api_calls/userManagement";
import Confirmation from "../../../components/commonComponents/Confirmation";
import { toast } from "react-toastify";
import { CustomTextInput } from "../../../components/commonComponents/TextInputs/style";

const UserList = ({ usedFor }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalDetails, setModalDetails] = React.useState({});
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [userToDelete, setUserToDelete] = React.useState({});
  const [searchVal, setSearchVal] = React.useState({ value: "" });
  const dispatchAction = useDispatch();
  const allowedAccessRights = checkUserPermissions();
  const permissionScope = allowedAccessRights.allowed_access;

  const checkUserDetails = checkUserDetailsFromCookie();
  const companyAllowedUserTypes = ["cyreen", "branch"];

  const currentStateData = useSelector((state) => state.userSelections);

  // sorting measures for files listing
  const [sortingMeasure, setSortingMeasure] = React.useState({
    username: "asc",
    email: "asc",
    company: "asc",
  });

  const [sortingParamName, setSortingParamName] = React.useState("username");

  const userRecords =
    usedFor === "cyreen"
      ? typeof currentStateData.cyreen_users !== "undefined" && currentStateData.cyreen_users.length !== 0
        ? currentStateData.cyreen_users
        : []
      : typeof currentStateData.users_list !== "undefined" && currentStateData.users_list.length !== 0
        ? currentStateData.users_list
        : [];

  const applyFilter = () => {
    if (userRecords.length !== 0) {
      return sortedResults(
        userRecords.filter((user) => {
          return (
            user.username.toLowerCase().includes(searchVal.value.toLowerCase().toString()) ||
            user.email.toLowerCase().toString().includes(searchVal.value.toLowerCase().toString()) ||
            user.assigned_company.name.toLowerCase().includes(searchVal.value.toLowerCase().toString()) ||
            user.roles.join(",").toLowerCase().includes(searchVal.value.toLowerCase().toString())
          );
        })
      );
    } else {
      return [];
    }
  };

  // to return the sorted list items
  const sortedResults = (toBeSortedList) => {
    return toBeSortedList.slice().sort((a, b) => {
      if (sortingParamName === "username") {
        return sortingMeasure.username === "asc"
          ? a.username.localeCompare(b.username)
          : b.username.localeCompare(a.username);
      } else if (sortingParamName === "email") {
        return sortingMeasure.email === "asc" ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email);
      } else if (sortingParamName === "company") {
        return sortingMeasure.company === "asc"
          ? a.assigned_company.name.localeCompare(b.assigned_company.name)
          : b.assigned_company.name.localeCompare(a.assigned_company.name);
      } else {
        return sortingMeasure.username === "asc"
          ? a.username.localeCompare(b.username)
          : b.username.localeCompare(a.username);
      }
    });
  };

  let listItems = typeof searchVal.value !== "undefined" && searchVal.value !== "" ? applyFilter() : userRecords;

  let userListitems =
    listItems.length !== 0 ? sortedResults(listItems).slice(0, currentStateData.initial_load_count_for_users) : [];

  const closeModal = () => {
    setModalDetails({});
    setIsModalOpen(false);
    dispatchAction(
      deleteItems([
        "cyreen_user",
        "username",
        "email",
        "roles",
        "user_assigned_company",
        "selected_company_id",
      ])
    );
  };

  const openModal = (modalFor, userData) => {
    setModalDetails({
      openFor: modalFor,
      user_data: userData,
    });
    setIsModalOpen(true);
  };

  //initially only 10 results will be loaded, further 10 will be loaded on click of load more button
  const loadMore = () => {
    loadMoreData("initial_load_count_for_users");
  };

  const editAction = (user) => {
    return (
      <HtmlTooltip title={<Typography variant="body4">Edit</Typography>}>
        <IconContainer>
          <a
            href="javacript:void(0)"
            aria-label={"edit user for " + user.username}
            onClick={(e) => {
              e.preventDefault();
              openModal("update", user);
            }}
          >
            <Edit sx={{ color: "#AF3241" }} />
          </a>
        </IconContainer>
      </HtmlTooltip>
    );
  };

  const deleteAction = (user) => {
    return (
      <HtmlTooltip title={<Typography variant="body4">Delete</Typography>}>
        <IconContainer>
          <a
            href="javacript:void(0)"
            aria-label={"delete user for " + user.username}
            onClick={(e) => {
              e.preventDefault();
              console.log(user);
              setShowConfirmation(true);
              setUserToDelete(user);
            }}
          >
            <Delete sx={{ color: "#AF3241" }} />
          </a>
        </IconContainer>
      </HtmlTooltip>
    );
  };

  const disabledEditAction = (user) => {
    return (
      <HtmlTooltipDisabled title={"No Access"}>
        <IconContainer>
          <a
            href="javacript:void(0)"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Edit sx={{ color: "#E6E6E6" }} />
          </a>
        </IconContainer>
      </HtmlTooltipDisabled>
    );
  };

  const disabledDeleteAction = (user) => {
    return (
      <HtmlTooltipDisabled title={"No Access"}>
        <IconContainer>
          <a
            href="javacript:void(0)"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Delete sx={{ color: "#E6E6E6" }} />
          </a>
        </IconContainer>
      </HtmlTooltipDisabled>
    );
  };

  const handleSortingParamChange = (paramName) => {
    setSortingMeasure((prevState) => {
      return {
        ...prevState,
        [paramName]: prevState[paramName] === "asc" ? "desc" : "asc",
      };
    });
    setSortingParamName(paramName);
  };

  React.useEffect(() => {
    //needed to validate if edit/delete actions are allowed. If the assigned user role is not in the list of allowed roles, then the actions will be disabled
    fetchRoles(false);

    dispatchAction(
      updateStateData({
        initial_load_count_for_users: platformConfig.initial_load_count,
      })
    );
  }, []);

  return (
    <>
      {/* ============ Action buttons =========== */}
      <Stack direction="row" spacing={2}>
        {usedFor !== "cyreen" &&
          (permissionScope.user_list_can_edit_brand === true || permissionScope.user_list_can_edit_external === true) ? (
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              openModal("add", {});
            }}
            label={"New User"}
            icon={<Add />}
          />
        ) : usedFor === "cyreen" && permissionScope.user_list_can_edit_cyreen === true ? (
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              openModal("add", {});
            }}
            label={"New Cyreen User"}
            icon={<Add />}
          />
        ) : (
          <></>
        )}
      </Stack>
      <Box height={30} />
      {/* {companyAllowedUserTypes.includes(checkUserDetails.user_detail.user_type) ? <CompanyListFilter /> : ""} */}
      <Box width={350}>
        <CustomTextInput
          fullWidth
          variant="standard"
          placeholder="Search"
          label="Search"
          autoComplete="off"
          type={"text"}
          value={searchVal.value}
          onChange={(e) => {
            setSearchVal({ value: e.target.value });
          }}
          onBlur={(e) => { }}
        />
      </Box>

      {/* ============== users listing ============ */}
      <Box height={30} />
      {userListitems.length !== 0 && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              {/* ------ header row ------ */}
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" className="text-wrapper">
                    <Stack direction={"row"} spacing={1}>
                      <div>#</div>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    <Stack direction={"row"} spacing={1}>
                      <div>Username</div>
                      {sortingMeasure.username === "asc" ? (
                        <ArrowDropDown
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleSortingParamChange("username");
                          }}
                        />
                      ) : (
                        <ArrowDropUp
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleSortingParamChange("username");
                          }}
                        />
                      )}
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    <Stack direction={"row"} spacing={1}>
                      <div>Email</div>
                      {sortingMeasure.email === "asc" ? (
                        <ArrowDropDown
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleSortingParamChange("email");
                          }}
                        />
                      ) : (
                        <ArrowDropUp
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleSortingParamChange("email");
                          }}
                        />
                      )}
                    </Stack>
                  </StyledTableCell>
                  {companyAllowedUserTypes.includes(checkUserDetails.user_detail.user_type) ? (
                    <StyledTableCell align="left" className="text-wrapper">
                      <Stack direction={"row"} spacing={1}>
                        <div>Company</div>
                        {sortingMeasure.Company === "asc" ? (
                          <ArrowDropDown
                            sx={{ cursor: "pointer" }}
                            onClick={(e) => {
                              handleSortingParamChange("company");
                            }}
                          />
                        ) : (
                          <ArrowDropUp
                            sx={{ cursor: "pointer" }}
                            onClick={(e) => {
                              handleSortingParamChange("company");
                            }}
                          />
                        )}
                      </Stack>
                    </StyledTableCell>
                  ) : (
                    ""
                  )}
                  <StyledTableCell align="left" className="text-wrapper">
                    Role
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userListitems.map((user, index) => {
                  // console.log("User", user)
                  if (user.user_type === "cyreen" && checkUserDetails.user_detail.user_type === "national") {
                    return null;
                  } else {
                    return (
                      <TableRow key={index + "_user_row"}>
                        {/* ---------- id --------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <Stack direction={"row"}>{index + 1}</Stack>
                        </StyledTableCell>
                        {/* ---------- username ---------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                          <Stack direction={"row"}>{user.username}</Stack>
                        </StyledTableCell>
                        {/* ---------- email --------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                          <Stack direction={"row"}>{user.email}</Stack>
                        </StyledTableCell>
                        {/* ---------- Company --------- */}
                        {companyAllowedUserTypes.includes(checkUserDetails.user_detail.user_type) ? (
                          <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                            <Stack direction={"row"}>
                              {user.user_type === "cyreen" ? "Cyreen" : user.assigned_company.name}
                            </Stack>
                          </StyledTableCell>
                        ) : (
                          ""
                        )}
                        {/* -------- role --------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"} sx={{ marginTop: "10px" }}>
                            <HtmlTooltip
                              title={
                                <Box width={200}>
                                  <ul>
                                    {user.roles.map((roleName) => {
                                      return (
                                        <li key={roleName} style={{ marginTop: "5px" }}>
                                          {roleName}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </Box>
                              }
                            >
                              <IconContainer>
                                <PeopleAlt sx={{ color: "#AF3241" }} />
                              </IconContainer>
                            </HtmlTooltip>
                          </StyledStack>
                        </StyledTableCell>

                        {/* -------- action -------- */}
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          {
                            typeof currentStateData.role_name_arr !== "undefined" && currentStateData.role_name_arr.length !== 0 && (
                              <StyledStack direction={"row"} sx={{ marginTop: "10px" }}>
                                {/* ---- edit/delete actions if allowed ------ */}
                                {usedFor === "cyreen" ? (
                                  <>
                                    {/* ----- edit ---- */}
                                    {permissionScope.user_list_can_edit_cyreen === true && user.roles.every(role => currentStateData.role_name_arr.includes(role)) === true
                                      ? editAction(user)
                                      : disabledEditAction(user)}
                                    {/* ----- delete ----- */}
                                    {permissionScope.user_list_can_delete_cyreen === true && user.roles.every(role => currentStateData.role_name_arr.includes(role)) === true
                                      ? deleteAction(user)
                                      : disabledDeleteAction(user)}
                                  </>
                                ) : (
                                  <>
                                    {user.user_type.toLowerCase() === "brand" ? (
                                      <>
                                        {/* ----- edit ---- */}
                                        {permissionScope.user_list_can_edit_brand === true && user.roles.every(role => currentStateData.role_name_arr.includes(role)) === true
                                          ? editAction(user)
                                          : disabledEditAction(user)}
                                        {/* ----- delete ----- */}
                                        {permissionScope.user_list_can_delete_brand === true && user.roles.every(role => currentStateData.role_name_arr.includes(role)) === true
                                          ? deleteAction(user)
                                          : disabledDeleteAction(user)}
                                      </>
                                    ) : (
                                      <>
                                        {/* ----- edit ---- */}
                                        {permissionScope.user_list_can_edit_external === true && user.roles.every(role => currentStateData.role_name_arr.includes(role)) === true
                                          ? editAction(user)
                                          : disabledEditAction(user)}
                                        {/* ----- delete ----- */}
                                        {permissionScope.user_list_can_delete_external === true && user.roles.every(role => currentStateData.role_name_arr.includes(role)) === true
                                          ? deleteAction(user)
                                          : disabledDeleteAction(user)}
                                      </>
                                    )}
                                  </>
                                )}
                              </StyledStack>
                            )
                          }

                        </StyledTableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* --------- record count info --------- */}
          <Box mt={2} id="infoText">
            <StyledDarkRedInformationTypography variant="body2">
              Showing {userListitems.length} out of {listItems.length} results.
            </StyledDarkRedInformationTypography>
          </Box>
          {/* ---------- Load more button ----------- */}
          <Box height={20} />
          {userListitems.length < listItems.length && <LoadMoreButton clickAction={loadMore} />}
        </>
      )}


      {/* ============ modal popup for add/update User ========== */}
      {isModalOpen && (
        <AddUser
          open={isModalOpen}
          onClose={closeModal}
          size={"md"}
          closeModal={closeModal}
          usedFor={modalDetails.openFor}
          userInfo={modalDetails.user_data}
          cyreenBoolean={usedFor === "cyreen" ? true : false}
        />
      )}
      {/* ---------- delete Confirmation ---------- */}
      {showConfirmation === true && (
        <Confirmation
          confirmationActionCall={() => {
            deleteUser(userToDelete).then((info) => {
              if (info.success === true) {
                setUserToDelete({});
                setShowConfirmation(false);
                toast("User deleted successfully ", { type: "success" });
              } else {
                toast("Sorry, something went wrong", { type: "error" });
              }
            });
          }}
          closePopup={(e) => {
            setUserToDelete({});
            setShowConfirmation(false);
          }}
          showConfirmationPopup={showConfirmation}
          confirmationTitle={"Delete User: " + userToDelete.username}
          confirmationMessage={
            <>
              <Typography variant="body2">
                You are deleting a user account. This action is permanent and cannot be reversed.
              </Typography>
              <Box height={20} />
              <Typography variant="body2">Are you sure you want to proceed?</Typography>
            </>
          }
        />
      )}
    </>
  );
};

export default UserList;
