import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteItems, updateStateData } from "../../../Store/actions";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { Box, Stack } from "@mui/material";
import { SpaceBetweenStack, StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { FilterAlt } from "@mui/icons-material";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";
import { filterPictures } from "../../../api_calls/pictureRequests";
import { checkUserRole } from "../../../api_calls/userManagement";
import { useNavigate } from "react-router-dom";

const PictureListFilters = (props) => {
  const Pictures = useSelector((state) => state.userSelections);
  const [picNameFilter, setPicNameFilter] = React.useState([]);
  const [picCompanyNameFilter, setPicCompanyNameFilter] = React.useState([]);
  const [picStoreNameFilter, setPicStoreNameFilter] = React.useState([]);

  const verifyUserRoleName = checkUserRole();
  const [statusFilter, setStatusFilter] = React.useState([]);
  const navigate = useNavigate();

  const t = useCustomTranslation();
  const durationFilterOptions = [
    {
      value: 1,
      label: "Active",
    },
    {
      value: 2,
      label: "Scheduled",
    },
    {
      value: 3,
      label: "Completed",
    },
    {
      value: 4,
      label: "Stopped",
    },
  ];

  const dispatchAction = useDispatch();

  const savePicFilters = (e) => {
    e.preventDefault();
    if (
      picNameFilter.length === 0 &&
      statusFilter.length === 0 &&
      picCompanyNameFilter.length === 0 &&
      picStoreNameFilter.length === 0
    ) {
      // toast("Please select filters before applying.", { type: "error" });
      clearfilters();
    } else {
      const filterKeywords = ["pic_name", "pic_company_filter", "status_filter", "pic_store_filter"];
      const selectedFilters = [picNameFilter, picCompanyNameFilter, statusFilter, picStoreNameFilter];
      let filterValues = {};
      /*
        There could be a simple straight forward way of adding values to the redux state.
        we have used map function here just to iterate through each record. Because otherwise,
        we get some error in browser console when we try to update a particular index in an array.
        However, when we use loop/map function then it doesn't show any error. 
        May be in future, we can figure out a better way to do this. But for time being, it's been
        implemented this way.
      */
      filterKeywords.map((keyword, index) => {
        filterValues = {
          ...filterValues,
          [keyword]: typeof selectedFilters[index] === "string" ? selectedFilters[index] : [...selectedFilters[index]],
        };
      });

      //save all selected filters to redux state (it will be accessed for generating powerpoint later)
      dispatchAction(
        updateStateData({
          pic_list_filters: filterValues,
        })
      );
      // filter records according to the selection
      filterPictures();
    }
  };

  const clearfilters = () => {
    // e.preventDefault();
    setPicNameFilter([]);
    setStatusFilter([]);

    //reset clips listing
    dispatchAction(deleteItems(["pic_list_filters", "filtered_pictures_list", "pics_list_filter_applied"]));
  };

  return (
    typeof Pictures.pic_names_list !== "undefined" && (
      <>
        {/* ----------- filter row 1 -------- */}
        <Box>
          <Stack direction={"row"} spacing={2}>
            {/* ------- picture name filters --------- */}
            <MultiSelect
              enableFullWidth={false}
              label={"Picture Name"}
              options={Pictures.pic_names_list}
              usedFor="filter"
              selectionCheck={(option, value) => option.id === value.id}
              defaultVal={picNameFilter}
              onChangeCall={(selectedOption) => {
                setPicNameFilter(selectedOption);
              }}
            />
            {/* ------------ clip duration status filter ------------- */}
            <MultiSelect
              label={"Picture Progress"}
              enableFullWidth={false}
              options={durationFilterOptions}
              usedFor="filter"
              selectionCheck={(option, value) => option.label === value.label}
              defaultVal={statusFilter}
              onChangeCall={(selectedOption) => {
                setStatusFilter(selectedOption);
              }}
            />
            {/* ------ company name filter --------- */}
            <MultiSelect
              enableFullWidth={false}
              label={"Owner"}
              options={Pictures.pic_company_name_list}
              usedFor="filter"
              selectionCheck={(option, value) => option.id === value.id}
              defaultVal={picCompanyNameFilter}
              onChangeCall={(selectedOption) => {
                setPicCompanyNameFilter(selectedOption);
              }}
            />
            {/* ------------ stores filter ----------  */}
            <MultiSelect
              enableFullWidth={false}
              label={"Stores"}
              options={Pictures.picture_stores_filter_list}
              usedFor="filter"
              selectionCheck={(option, value) => option.id === value.id}
              defaultVal={picStoreNameFilter}
              onChangeCall={(selectedOption) => {
                setPicStoreNameFilter(selectedOption);
              }}
            />

            <SpaceBetweenStack
              direction={"row"}
              spacing={2}
              width={"100%"}
              justifyContent={"space-between"}
              display={"flex"}
            >
              <ActionButton onClick={savePicFilters} label={t("apply_filter_button_text")} icon={<FilterAlt />} />
              {/* ------ overview should be hidden for brand user ----- */}
              {verifyUserRoleName.user_role !== "Brand" && (
                <ActionButton
                  onClick={(e) => {
                    // e.preventDefault();
                    navigate("/check-campaigns/picture-overview");
                  }}
                  label={t("Overview")}
                />
              )}
            </SpaceBetweenStack>
          </Stack>
          <Box height={20} />
        </Box>
      </>
    )
  );
};

export default PictureListFilters;
