import React from "react";
import { Box, Tab } from "@mui/material";
import PropTypes from "prop-types";
import { StyledMainBox, StyledTabs } from "../../components/commonComponents/StyledComponents/styled";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import EmbeddableTestDash from "./EmbeddedTest";
import EmbedImpressionFilter from "./filters/EmbedImpressionFilter";
import { useSelector } from "react-redux";

// to load tab content
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box mt={5}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const EmbeddedTabs = () => {
    const [value, setValue] = React.useState(0);
    const storeDetails = useSelector((state) => state.userSelections);
    let dashboardVariables = {
        "absolute value": typeof storeDetails.embedded_filter !== "undefined" && typeof storeDetails.embedded_filter.absolute_val !== "undefined" ? storeDetails.embedded_filter.absolute_val : false
    };

    if (typeof storeDetails.embedded_filter !== "undefined" && typeof storeDetails.embedded_filter.store !== "undefined" && storeDetails.embedded_filter.store !== "") {
        dashboardVariables = {
            ...dashboardVariables,
            "dropdown choice": storeDetails.embedded_filter.store,
        }
    }

    if (typeof storeDetails.embedded_filter !== "undefined" && typeof storeDetails.embedded_filter.from !== "undefined") {
        dashboardVariables = {
            ...dashboardVariables,
            'chosenTimeRange': {
                from: storeDetails.embedded_filter.from,
                to: storeDetails.embedded_filter.to
            },
        }
    }

    let tabsConfig = [{
        label: "Impressions",
        icon: "",
        unique_key: "impressions_embed",
        dashboard: <EmbeddableTestDash
            embedDashboardId="8e5fa0b6-dda1-4d54-903f-3c354e414430"
            variables={dashboardVariables}
            filterConfig={{
                "id_store": "3",
                // "id_store" : ["3", "4", "5", etc],
                "regions": ["us-east", "eu-west"]
            }}
        />,
        filter_component: <EmbedImpressionFilter />,
    }, {
        label: "Performance",
        icon: "",
        unique_key: "performanceEmbed",
        dashboard: <EmbeddableTestDash
            embedDashboardId="8395f127-ebf4-4499-9173-b84849263825"
            filterConfig={{
                "id_store": "3",
                // "id_store" : ["3", "4", "5", etc],
                "regions": ["us-east", "eu-west"]
            }} />,
        filter_component: <></>,
    }];


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <StyledMainBox component="main">
            <Box
                sx={{
                    display: "flex", // Make the Box a flex container
                    // borderBottom: 1,
                    // borderColor: "divider",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    backgroundColor: "#fff",
                }}
            >
                <Box component={"div"}>
                    <StyledTabs value={value} onChange={handleChange} aria-label="data-exchange" textColor={"#F04B55"}>
                        {tabsConfig.map((tabDetail) => {
                            return <Tab key={tabDetail.unique_key} icon={tabDetail.icon} label={tabDetail.label} {...a11yProps(0)} />;
                        })}
                    </StyledTabs>
                </Box>
                <Box
                    component={"div"}
                    width={600}
                    sx={{
                        flex: 1,
                    }}
                >
                    {tabsConfig[value].filter_component}
                </Box>
            </Box>
            {/* ----------- filters section ------------ */}
            {/* <Box
                sx={{
                    display: "flex", // Make the Box a flex container
                    // borderBottom: 1,
                    // borderColor: "divider",
                    position: "sticky",
                    flexWrap: "wrap",
                    top: 0,
                    zIndex: 999,
                    backgroundColor: "#fff",
                }}
            >
                
            </Box> */}
            {/* ============== tab contents =========== */}
            {tabsConfig.map((tabDetail, index) => {
                return (
                    <CustomTabPanel value={value} index={index}>
                        {tabDetail.dashboard}
                    </CustomTabPanel>
                );
            })}
            {/* ============== users listing ============ */}
            <Box height={30} />
        </StyledMainBox>
    );
};

export default AccessControl(EmbeddedTabs, ["overview_can_view"]);
