/*
    This file contains some functions that can be used for some common tasks throughout the application
*/
import { deleteItems, updateStateData } from "../Store/actions";
import store from "../Store/store";
import * as xlsx from "xlsx";
import platformConfig from "../platformConfig";
import moment from "moment-timezone";
// import moment from 'moment';

export const weekDayNames = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const weekDayOptions = [
  // { label: "All", value: "all" },
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];

export const weekDayNum = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

// to calculate the week number from the given date.
function getISOWeekNumber(date) {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(((date - startOfYear) / 86400000 + startOfYear.getDay() + 1) / 7);
  return weekNumber;
}

// to return an array of week numbers for a given range of dates.
export const fetchWeekNumbers = async (startDateStr, endDateStr) => {
  const startDate = moment(startDateStr).tz(platformConfig.timezone);
  const endDate = moment(endDateStr).tz(platformConfig.timezone);

  const weekNumbers = [];
  let weekNumbersArr = [];
  let currentDate = startDate.clone();

  while (currentDate.isSameOrBefore(endDate)) {
    const weekNumber = currentDate.isoWeek();
    if (!weekNumbersArr.includes(weekNumber)) {
      weekNumbers.push({ value: weekNumber, label: weekNumber.toString() });
      weekNumbersArr.push(weekNumber);
    }

    currentDate.add(1, "days");
  }

  store.dispatch(
    updateStateData({
      campaign_weeks: weekNumbers,
    })
  );
  return weekNumbers;
};

// validate if the provided field is empty
export const validateEmpty = (fieldValue, isArray = false) => {
  let error = false;
  if (fieldValue === "") {
    error = true;
  } else {
    if (isArray === true) {
      if (fieldValue !== "" && fieldValue.length === 0) {
        error = true;
      }
    }
  }

  return {
    error: error,
  };
};

//only letters validation
export const validateAlphabets = (value, lengthCheck = false, charLength = 0, allowSlash = false) => {
  let error = false;
  let transformedValue = value.toString();
  // Modify the regex pattern based on allowSlash parameter
  let letters = allowSlash ? /^[A-Za-z /]+$/ : /^[A-Za-z ]+$/;

  //value must not be empty
  if (transformedValue.trim() === "") {
    error = true;
  } else {
    if (!transformedValue.match(letters)) {
      error = true;
    }
  }

  if (error === false && lengthCheck === true) {
    if (transformedValue.length > charLength) {
      error = true;
    }
  }

  return {
    error: error,
  };
};

//only number validation
export const validateNumbers = (value, floatValues = false) => {
  let transformedValue = value.toString();
  let error = false;
  let validNumbers = floatValues === false ? /^[0-9]+$/ : /^[0-9]+(\.[0-9]+)?$/;
  //value must not be empty
  if (transformedValue.trim() === "") {
    error = true;
  } else {
    if (!transformedValue.match(validNumbers)) {
      error = true;
    }
  }
  return {
    error: error,
  };
};

export const validateNegativeDecimalNumbers = (value) => {
  let transformedValue = value.toString();
  let error = false;
  let validNumbers = /^-?[0-9]+(\.[0-9]+)?$/;
  //value must not be empty
  if (transformedValue.trim() === "") {
    error = true;
  } else {
    if (!transformedValue.match(validNumbers)) {
      error = true;
    }
  }
  return {
    error: error,
  };
};

export const validateAlphanumeric = (value) => {
  let error = false;
  // Regular expression to match alphanumeric characters
  var alphanumericRegex = /^[0-9a-zA-Z\s]+$/;

  // Value must not be empty
  if (value.trim() === "") {
    error = true;
  } else {
    // Check if the value contains only alphanumeric characters
    if (!value.match(alphanumericRegex)) {
      error = true;
    }
  }

  return {
    error: error,
  };
};

// Function to check if third-party cookies are enabled
export const areThirdPartyCookiesEnabled = () => {
  // Replace 'https://example.com' with the target third-party domain
  const targetDomain = "https://www.airbnb.co.uk/";

  // Create an iframe with the target domain
  const iframe = document.createElement("iframe");
  iframe.src = targetDomain;
  iframe.style.display = "none";

  // Add the iframe to the document
  document.body.appendChild(iframe);

  // Wait for a brief moment and then check if document.cookie is accessible
  return checkCookieExistance(iframe);
};

function checkCookieExistance(iframe) {
  try {
    // Attempt to read a cookie from the iframe's document
    const cookieValue = iframe.contentWindow.document.cookie;
    // If we were able to read the cookie, third-party cookies are enabled
    if (cookieValue) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    // If reading the cookie triggered a security error, third-party cookies are likely disabled
    return false;
  } finally {
    // Remove the iframe from the document
    document.body.removeChild(iframe);
  }
}

export const downloadExcelFile = (data, filename) => {
  // Create a new workbook and add a worksheet
  const workbook = xlsx.utils.book_new();
  const worksheet = xlsx.utils.aoa_to_sheet(data);

  // Add the worksheet to the workbook
  xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Save the workbook to an Excel file
  xlsx.writeFile(workbook, filename);
};

export const formatToStandardDate = (dateToConvert) => {
  const momentObject = moment.tz(dateToConvert, platformConfig.timezone).startOf("day");

  const year = momentObject.year();
  const month = momentObject.month();
  const day = momentObject.date();
  const hours = momentObject.hours();
  const minutes = momentObject.minutes();
  const seconds = momentObject.seconds();

  const dateInSpecifiedTimezone = new Date(year, month, day, hours, minutes, seconds);
  // console.log("dateInSpecifiedTimezone", dateInSpecifiedTimezone)
  return dateInSpecifiedTimezone;
};

export const formatToStandardDateForRangeFilter = (dateToConvert) => {
  const momentObject = moment.tz(dateToConvert, platformConfig.timezone).startOf("day");

  const year = momentObject.year();
  const month = momentObject.month();
  const day = momentObject.date();
  const hours = momentObject.hours();
  const minutes = momentObject.minutes();
  const seconds = momentObject.seconds();

  const dateInSpecifiedTimezone = new Date(year, month, day, 1, minutes, seconds);

  return dateInSpecifiedTimezone;
};

export const formatDate = (date) => {
  const formattedDate = moment.tz(date, platformConfig.timezone).format("DD/MM/YYYY");
  return formattedDate;
};

/*
  // this function is specifically used in date range filter component.
  // Reason: sometimes the dates returned from "formatDate" function above are being fluctuated as 1 day earlier 
  //  or 1 day later than the actual date supplied. 
  // After debugging, we find out that it keeps on converting the already converted dates back and forth which results in fluctuations. 
  // So, we defined this function to avoid un-necessary date conversions which then leads to other issues.
*/
export const formatDateWithoutTimeZoneConversion = (date) => {
  const formattedDate = moment(date).format("DD/MM/YYYY");
  return formattedDate;
};

export const dbFormatDate = (date) => {
  let newDate = date;
  if (typeof date === "object") {
    // Get the day, month, and year components of the date
    const day = date.getDate().toString().padStart(2, "0"); // Ensure two digits with leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1
    const year = date.getFullYear();
    // Format the date as "DD/MM/YYYY"
    newDate = `${year}-${month}-${day}`;
  }
  const formattedDate = moment.tz(newDate, platformConfig.timezone).format("YYYY-MM-DD");
  return formattedDate;
};

export const dateObjForToday = (dateObj) => {
  const day = dateObj.getDate().toString().padStart(2, "0"); // Ensure two digits with leading zero if needed
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1
  const year = dateObj.getFullYear();
  // Format the date as "DD/MM/YYYY"
  return `${year}-${month}-${day}`;
};

export const checkStatus = (startDate, endDate) => {
  const todayDateObj = dateObjForToday(new Date());
  const today = moment.tz(todayDateObj, platformConfig.timezone).startOf("day");
  // const campaignStartDate = moment(startDate, "YYYY-MM-DD").tz(platformConfig.timezone).startOf("day");
  // const campaignEndDate = moment(endDate, "YYYY-MM-DD").tz(platformConfig.timezone).startOf("day");

  const campaignStartDate = moment.tz(startDate, platformConfig.timezone).startOf("day");
  const campaignEndDate = moment.tz(endDate, platformConfig.timezone).startOf("day");

  if (today.isBefore(campaignStartDate)) {
    return "Scheduled";
  } else if (today.isSameOrAfter(campaignStartDate) && today.isSameOrBefore(campaignEndDate)) {
    return "Active";
  } else {
    return "Completed";
  }
};

export const checkDaysInformation = (startDate, endDate) => {
  const todayDateObj = dateObjForToday(new Date());
  const today = moment.tz(todayDateObj, platformConfig.timezone).startOf("day");
  // const campaignStartDate = moment(startDate, "YYYY-MM-DD").tz(platformConfig.timezone).startOf("day");
  // const campaignEndDate = moment(endDate, "YYYY-MM-DD").tz(platformConfig.timezone).startOf("day");

  const campaignStartDate = moment.tz(startDate, platformConfig.timezone).startOf("day");
  const campaignEndDate = moment.tz(endDate, platformConfig.timezone).startOf("day");

  if (today.isBefore(campaignStartDate)) {
    // scheduled
    const daysUntilStart = campaignStartDate.diff(today, "days");
    return daysUntilStart === 1 ? "Starting in " + daysUntilStart + " day" : "Starting in " + daysUntilStart + " days";
  } else if (today.isSameOrAfter(campaignStartDate) && today.isSameOrBefore(campaignEndDate)) {
    // active
    const daysLeft = campaignEndDate.diff(today, "days");
    return daysLeft === 0
      ? "Ending today"
      : daysLeft === 1
      ? "Ending in " + daysLeft + " day"
      : "Ending in " + daysLeft + " days";
  } else {
    // completed
    const daysAgo = today.diff(campaignEndDate, "days");
    // return "Ended " + daysAgo + " days ago";
    return daysAgo === 1 ? "Ended 1 day ago" : "Ended " + daysAgo + " days ago";
  }
};

export const swapKeyValues = (obj) => {
  const swappedObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      swappedObj[obj[key]] = key;
    }
  }

  return swappedObj;
};

export const checkProgress = (startDate, endDate) => {
  const today = moment().startOf("day");
  // const campaignStartDate = moment(startDate, "YYYY-MM-DD").tz(platformConfig.timezone).startOf("day");
  // const campaignEndDate = moment(endDate, "YYYY-MM-DD").tz(platformConfig.timezone).startOf("day");

  const campaignStartDate = moment.tz(startDate, platformConfig.timezone).startOf("day");
  const campaignEndDate = moment.tz(endDate, platformConfig.timezone).startOf("day");

  if (today.isBefore(campaignStartDate)) {
    return 0;
  } else if (today.isSameOrAfter(campaignStartDate) && today.isSameOrBefore(campaignEndDate)) {
    const daysLeft = campaignEndDate.diff(today, "days");
    const totalDays = campaignEndDate.diff(campaignStartDate, "days");
    const completionProgress = Math.round(((totalDays - daysLeft) / totalDays) * 100);
    return completionProgress;
  } else {
    return 100;
  }
};

// to return a random number
export const generateRandomNumber = (digits) => {
  const min = 10 * (digits - 1);
  const max = 10 * digits - 1;

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// to replace _ with space and capitalize the first character of the string
export const capitalizeAndReplaceUnderScore = (inputString, capitalize = true) => {
  let result = inputString
    .replace(/_/g, " ") // Replace underscores with spaces
    .toLowerCase(); // Convert the entire string to lowercase

  if (capitalize === true) {
    // Capitalize the first character of each word
    result = result.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
  }
  return result;
};

export const allNumbersBetweenRange = (start, end) => {
  const result = [];

  for (let i = start; i <= end; i++) {
    result.push(i);
  }

  return result;
};

// validate email format
export const validateEmailFormat = (email) => {
  // Regular expression for a basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
};

// to load more results (Paginated) for the table listings
export const loadMoreData = (reduxKeyword, usedFor) => {
  const currentStateData = store.getState().userSelections;
  store.dispatch(
    updateStateData({
      [reduxKeyword]: currentStateData[reduxKeyword] + platformConfig.initial_load_count,
    })
  );
  // Smooth scroll to a specific element or position on the page
  const yOffset = 100; // Adjust this value as needed
  const elementToScrollTo = document.getElementById("infoText");

  if (typeof usedFor !== "undefined" && usedFor === "modal") {
    const modalContent = document.querySelector(".MuiDialogContent-root");

    if (modalContent) {
      modalContent.scrollTo({
        top: document.getElementById("infoModalText").offsetTop - yOffset,
        behavior: "smooth",
      });
    }
  } else {
    window.scrollTo({
      top: elementToScrollTo.offsetTop - yOffset,
      behavior: "smooth",
    });
  }
};

export const isValidFileName = (fileName) => {
  // Split the fileName into name and extension based on the last dot
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
    // There should be at least one character before and after the dot
    return false;
  }

  const name = fileName.slice(0, lastDotIndex);
  const extension = fileName.slice(lastDotIndex + 1);

  // Validate the file name part
  const isNameValid = /^[a-zA-Z0-9_]+$/.test(name);

  // Validate the file extension part
  const isExtensionValid = /^[a-zA-Z0-9]+$/.test(extension);

  // Return true only if both parts are valid
  return isNameValid && isExtensionValid;
};

// return the date in correct timezone
export const dateInCorrectTimezone = (selectedDate) => {
  return moment.tz(selectedDate, platformConfig.timezone).startOf("day").format("DD/MM/YYY");
};

// return today date
export const checkTodayDate = () => {
  return moment().startOf("day").format("DD/MM/YYY");
};

export const standardFormatToday = () => {
  const momentObject = moment().startOf("day");

  const year = momentObject.year();
  const month = momentObject.month();
  const day = momentObject.date();
  const hours = momentObject.hours();
  const minutes = momentObject.minutes();
  const seconds = momentObject.seconds();

  const todayInSpecifiedTimezone = new Date(year, month, day, hours, minutes, seconds);

  return todayInSpecifiedTimezone;
};

export const findStoreIds = (storeNames) => {
  const storeDetails = store.getState().userSelections;
  let storeIds =
    typeof storeDetails.stores_list !== "undefined" && storeDetails.stores_list.length !== 0
      ? [
          ...storeDetails.stores_list
            .filter((storeRecord) => {
              return storeNames.includes(storeRecord.name);
            })
            .map((storeInfo) => {
              return storeInfo.id;
            }),
        ]
      : [];
  return storeIds;
};

// fetch store list options for tableau filters
export const storeListOptionsForTableauFilter = (usedFor, singleValue = false) => {
  const storeDetails = store.getState().userSelections;
  let storeList =
    typeof usedFor !== "undefined" && usedFor === "campaign"
      ? typeof storeDetails.campaign_detail !== "undefined" && storeDetails.campaign_detail.campaign_stores.length !== 0
        ? [...storeDetails.campaign_detail.campaign_stores].sort()
        : []
      : typeof storeDetails.store_dates !== "undefined" && storeDetails.store_dates.length !== 0
      ? [
          ...storeDetails.store_dates.map((storeInfo) => {
            return storeInfo.name;
          }),
        ].sort()
      : [];

  if (singleValue === true) {
    const removeAllOption = storeList.sort().filter((item) => item !== "(All)");
    return removeAllOption[0];
  } else {
    return storeList;
  }
};

export const independentStoreListOptions = (singleValue = false) => {
  const storeDetails = store.getState().userSelections;
  let storeList =
    typeof storeDetails.stores_list !== "undefined" && storeDetails.stores_list.length !== 0
      ? [
          ...storeDetails.stores_list
            .filter((store) => {
              return store.active === true;
            })
            .map((storeInfo) => {
              return storeInfo.name;
            }),
        ].sort()
      : [];

  if (singleValue === true) {
    const removeAllOption = storeList.sort().filter((item) => item !== "(All)");
    return removeAllOption[0];
  } else {
    return storeList;
  }
};

export const yesterdayDate = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  // set end date
  return dbFormatDate(yesterday);
};

// reset brand report view
export const resetBrandReportView = () => {
  store.dispatch(
    updateStateData({
      brand_report_view: false,
    })
  );
};

export const clearCampaignDetails = () => {
  store.dispatch(
    deleteItems([
      "adlevel_names",
      "activities_data",
      "manual_activity_list",
      "activityOptions",
      "campaign_weeks",
      "stores_list",
      "brand_filters",
      "retailer_filters",
      "campaign_detail",
    ])
  );
};

export const erase_duplicates = (options) => {
  var obj = [{}];
  var arr = [];
  options.forEach((option) => {
    if (!arr.includes(option.label)) {
      obj.push(option);
      arr.push(option.label);
    }
  });
  const filteredObj = obj.filter((item) => Object.keys(item).length > 0);
  return filteredObj;
};

export const getFirstDayTwoMonthsAgo = () => {
  return moment().subtract(2, "months").startOf("month").format("YYYY-MM-DD");
};

export const verifyBrowserName = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Chrome") && !userAgent.includes("Edg") && !userAgent.includes("OPR")) {
    return "Google Chrome";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return "Safari";
  } else if (userAgent.includes("Firefox")) {
    return "Mozilla Firefox";
  } else if (userAgent.includes("Edg")) {
    return "Microsoft Edge";
  } else if (userAgent.includes("OPR") || userAgent.includes("Opera")) {
    return "Opera";
  } else if (userAgent.includes("Trident") || userAgent.includes("MSIE")) {
    return "Internet Explorer";
  } else {
    return "Unknown Browser";
  }
};
