import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";
import {
  Box,
  Typography,
  Stack,
  Tabs,
  ListItemText,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  Paper,
  Drawer,
  Pagination,
  TablePagination,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const StyledMainBox = styled(Box)(({ theme }) => ({
  flexGrow: "1",
  minHeight: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "10px 16px",
}));

// styling for the logo image
export const LogoImage = styled("img")({
  maxWidth: "100%",
  height: "auto",
  padding: "10px",
});

// Create a styled component for the ToggleButtonGroup
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: "10px",
  maxHeight: "40px",
  marginTop: "15px",
}));

// Create a styled component for the individual ToggleButtons
export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: "4px",
  fontSize: "10px",
  fontWeight: "bold",
  "&.MuiToggleButton-root.Mui-selected": {
    color: "#fff",
    backgroundColor: "#AF3241",
  },
}));

// Styled component for a ButtonGroup that looks like a toggle group but without toggle functionality
export const StyledReportButtonGroup = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: "10px",
  overflow: "hidden",
  marginTop: "15px",
}));

export const StyledDarkRedInformationTypography = styled(Typography)(({ theme }) => ({
  display: "flex", // Use flex display to align items in a row
  alignItems: "center", // Align items vertically in the center
  color: "#AF3241",
  fontWeight: "bold",
}));

export const StyledBlackInformationTypography = styled(Typography)(({ theme }) => ({
  display: "flex", // Use flex display to align items in a row
  alignItems: "center", // Align items vertically in the center
  // color: "#2D2D37",
  fontWeight: "bold",
  marginLeft: "20px",
  marginTop: "5px",
}));

export const StyledLightRedInformationTypography = styled(Typography)(({ theme }) => ({
  display: "flex", // Use flex display to align items in a row
  alignItems: "center", // Align items vertically in the center
  color: "#F04B55",
  fontWeight: "bold",
  // marginLeft: "20px",
  // marginTop: "5px",
}));

export const StyledInformationSpan = styled("span")(({ theme }) => ({
  color: "#2D2D37",
  fontWeight: "bold",
  marginLeft: "5px",
  fontSize: "12px",
  textWrap: "balance",
}));

// Styled component for individual buttons in the ButtonGroup
export const StyledReportButton = styled("button")(({ theme }) => ({
  flex: 1,
  border: "none",
  borderRadius: "4px",
  fontSize: "10px",
  fontWeight: "bold",
  padding: "8px 12px",
  cursor: "pointer",
  backgroundColor: "#E0E0E0",
  color: "#333",
  "&:hover": {
    backgroundColor: "#CCCCCC",
  },
}));

// styled tooltip definition
export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#AF3241",
    color: "#fff",
    maxWidth: "none",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #AF3241",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#AF3241",
  },
}));

export const HtmlTooltipLightRed = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F04B55",
    color: "#fff",
    maxWidth: "none",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #F04B55",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#F04B55",
  },
}));

export const HtmlTooltipLightWhite = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "#1E1E28",
    maxWidth: "none",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #F04B55",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#F04B55",
  },
}));

// styled tooltip definition
export const HtmlTooltipDisabled = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#62626E",
    color: "#fff",
    maxWidth: "none",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #62626E",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#62626E",
  },
}));

// progress bar component
export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#AF3241",
  },
}));

// container for icons
export const IconContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  // textAlign: "center",
  alignItems: "center",
  padding: "2px",
  width: "30px",
  height: "37px",
  borderRadius: "15%",
});

// container for text below the icons
export const IconText = styled(Typography)({
  color: "#AF3241",
  fontSize: "12px",
  fontWeight: "bold",
});

export const IconTextDisabled = styled(Typography)({
  color: "#E6E6E6",
  fontSize: "12px",
  fontWeight: "bold",
  // marginRight: "6px",
});

// customized container for thumbnail text
export const ThumbnailWithText = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "row",
  gap: "30px",
});

//customized component for thumbnail image
export const ThumbnailImg = styled("img")({
  maxWidth: "70px",
  maxHeight: "70px",
  order: 0,
});

// customized container for thumbnail text
export const ThumbnailText = styled("div")({
  flex: 1,
  // maxWidth: "200px",
  wordWrap: "overflow-wrap",
});

//center aligned stack component
export const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
}));

export const SpaceBetweenStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "space-between",
}));

export const StyledRightAlignedStack = styled(Stack)(({ theme }) => ({
  alignItems: "right",
  justifyContent: "right",
  marginRight: "25px",
}));

export const StyledLeftAlignedStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "left",
  marginRight: "25px",
}));

export const StyledDifferentColorSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": { color: "#F04B55" },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#F04B55",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#AF3241",
    "&:hover": {
      backgroundColor: alpha("#AF3241", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#AF3241",
  },
}));

// switch toggle button
export const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#AF3241",
    "&:hover": {
      backgroundColor: alpha("#AF3241", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#AF3241",
  },
  "& .MuiButtonBase-root .MuiSwitch-switchBase .Mui-checked+.Mui-disabled": {
    backgroundColor: "rgb(245, 228, 230, 1)",
  },
  "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
    color: "#f7b7be",
  },
}));

export const StyledWhiteSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#fff",
    "&:hover": {
      backgroundColor: alpha("#fff", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#fff",
  },
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTab-root": {
    "&:hover": {
      color: "#F04B55",
      backgroundColor: alpha("#AF3241", theme.palette.action.hoverOpacity),
    },
  },
  "& .Mui-selected": {
    color: "#F04B55",
    backgroundColor: alpha("#AF3241", theme.palette.action.hoverOpacity),
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#F04B55", // Change this to your desired color
  },
}));

export const StyledNestedTabs = styled(Tabs)(({ theme }) => ({
  "& .Mui-selected": {
    color: "#F04B55",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#F04B55", // Change this to your desired color
  },
}));

// Stack component with sticky property
export const StickyTopRowStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "#fff",
  justifyContent: "flex-end",
  padding: "15px",
  marginRight: "20px",
}));

export const StickyTopRowStackSpaceBetween = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  position: "sticky",
  top: 0,
  zIndex: 1,
  backgroundColor: "#fff",
  justifyContent: "space-between",
  padding: "15px",
  marginRight: "20px",
  flexWrap: "nowrap",
}));

// styled menu items for select dropdowns
export const StyledMenuItemContainer = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    marginRight: "5px",
    padding: "5px",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: alpha("#F04B55", theme.palette.action.hoverOpacity),
    },
  },
  "&.Mui-selected": {
    color: "#AF3241",
    backgroundColor: alpha("#AF3241", theme.palette.action.hoverOpacity),
  },
  "&.MuiSvgIcon-root.MuiSelect-iconStandard": {
    color: "red",
  },
}));

export const StyledSelectBox = styled(Select)(({ theme }) => ({
  "&.MuiInput-underline::before": {
    borderColor: "#AF3241",
    "&:hover": {
      borderColor: "#AF3241",
    },
  },
  "&.MuiInput-underline::after": {
    borderColor: "#AF3241",
    "&:hover": {
      borderColor: "#AF3241",
    },
  },
  "&.MuiSelect-iconStandard": {
    color: "red",
  },
  "&.Mui-readOnly": {
    color: "#BEBEBE",
  },
}));

export const StyledInputLabel = styled(InputLabel)((theme) => ({
  fontSize: "13px",
  "&.MuiInputLabel-root": {
    color: "#AF3241",
  },
  "&.MuiInputLabel-root.Mui-focused": {
    color: "#AF3241",
  },
}));

export const StyledMenuListItemText = styled(ListItemText)(({ theme }) => ({
  "& .MuiListItemText-primary": {
    fontSize: "13px",
    padding: "2px",
  },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.MuiCheckbox-root": {
    color: "#AF3241",
    padding: "2px",
    "&:hover": {
      color: "#AF3241",
      backgroundColor: alpha("#AF3241", theme.palette.action.hoverOpacity),
    },
  },
  "&.Mui-checked": {
    color: "#AF3241",
    backgroundColor: alpha("#AF3241", theme.palette.action.hoverOpacity),
  },
}));

export const StyledWhiteCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.MuiCheckbox-root": {
    color: "#fff",
    padding: "2px",
    "&:hover": {
      color: "#fff",
      backgroundColor: alpha("#fff", theme.palette.action.hoverOpacity),
    },
  },
  "&.Mui-checked": {
    color: "#fff",
    backgroundColor: alpha("#fff", theme.palette.action.hoverOpacity),
  },
}));

export const StyledInfoBox = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(240, 75, 85, 0.09)",
  color: "#1E1E28",
  border: "1px solid rgba(240, 75, 85, 0.89)",
  borderRadius: 5,
  padding: theme.spacing(2), // Adjust padding as needed
  margin: 0, // Adjust margin as needed
  boxShadow: "0 2px 4px rgba(240, 75, 85, 0.09)",
}));

export const StyledGreyInfoBox = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "#1E1E28",
  border: "1px solid rgba(30, 30, 40, 0.7)",
  borderRadius: 5,
  padding: theme.spacing(2), // Adjust padding as needed
  margin: 0, // Adjust margin as needed
  boxShadow: "0 2px 4px rgba(30, 30, 40, 0.09)",
}));

export const StyledRolesDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowY: "hidden",
  },

}));
// styled pagination component
export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  color: "#AF3241",
  fontWeight: "bold",
  paddingLeft: "0px",
  "&.MuiToolbar-regular": {
    padding: 0,
  },
}));
