import { toast } from "react-toastify";
import { updateStateData } from "../Store/actions";
import store from "../Store/store";
import { sendApiRequest, sendApiRequestWithFile } from "./requestConfig";
import { allowedNavOptions, checkUserDetailsFromCookie } from "./userManagement";

export const fetchCompanies = async () => {
  const checkUserDetails = checkUserDetailsFromCookie();
  const checkCompanies = await sendApiRequest("companies/share-list", {}, "GET");

  let response = {};

  if (checkCompanies.success === true) {
    let companyList = [];
    let companyIdOptionObj = {};

    let companyNameList = [];
    if (checkCompanies.response.length !== 0) {
      checkCompanies.response.forEach((company) => {
        companyNameList.push({
          id: company.id,
          label: company.name,
        });
        companyList.push({
          id: company.id,
          name: company.name,
          level: company.level,
          tree_path: company.tree_path,
        });
        // to fetch company details based on company idF
        companyIdOptionObj = {
          ...companyIdOptionObj,
          [company.id]: {
            id: company.id,
            name: company.name,
            level: company.level,
            tree_path: company.tree_path,
          },
        };
      });

      store.dispatch(
        updateStateData({
          company_name_list: companyNameList,
          company_list: companyList,
          company_id_option_obj: companyIdOptionObj,
        })
      );
    }
    response = {
      success: true,
    };
  } else {
    response = {
      success: false,
    };
  }
  return response;
};

//to match campaign list records with the filters applied.
const matchClipRecords = (item) => {
  const currentStateData = store.getState().userSelections;
  const appliedFilters = currentStateData.company_list_filters;

  let itemMatch = false;

  // company name filter
  if (appliedFilters.company_name.length !== 0) {
    appliedFilters.company_name.forEach((filterWord) => {
      if (item.label === filterWord.label) {
        itemMatch = true;
      }
    });
  }

  return itemMatch;
};

export const filterCompanies = () => {
  const currentStateData = store.getState().userSelections;
  let filteredData = [];

  if (typeof currentStateData.company_name_list !== "undefined" && currentStateData.company_name_list.length !== 0) {
    currentStateData.company_name_list.forEach((item) => {
      const checkMatch = matchClipRecords(item);
      if (checkMatch === true) {
        filteredData.push(item);
      }
    });
  }

  store.dispatch(
    updateStateData({
      filtered_company_list: {
        company_list: filteredData,
      },
      company_list_filter_applied: true,
    })
  );

  return true;
};

export const fetchAvailableToRetailerIds = async () => {
  const getAvailableToRetailerIds = await sendApiRequest("companies/share-list", {}, "GET");
  // console.log("Retailer Ids: ", getAvailableToRetailerIds)
  if (typeof getAvailableToRetailerIds.success !== "undefined" && getAvailableToRetailerIds.success === true) {
    const availableRetailerList = getAvailableToRetailerIds.response;
    store.dispatch(
      updateStateData({
        available_to_list: availableRetailerList,
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
      message: "Sorry, something went wrong",
    };
  }
};
