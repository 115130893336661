import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HtmlTooltip,
  StyledBlackInformationTypography,
  StyledInformationSpan,
  StyledLeftAlignedStack,
  StyledMainBox,
  StyledStack,
} from "../../../../components/commonComponents/StyledComponents/styled";
import { Box, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SelectBox from "../../../../components/commonComponents/Selectors/SelectBox";
import { updateStateData } from "../../../../Store/actions";
import ActionButton, { DarkGreyButton } from "../../../../components/commonComponents/Buttons/ActionButton";
import { Add, Close, Download, Info, Save } from "@mui/icons-material";
import {
  LeftStyledTableCell,
  SmallWidthStyledTableCell,
  StyledTableCell,
} from "../../../../components/commonComponents/TabularLists/styles";
import { CustomTextInput } from "../../../../components/commonComponents/TextInputs/style";
import CloseButton from "../../../../components/commonComponents/Buttons/CloseButton";
import {
  addMappingEntries,
  checkCounterTextToShow,
  checkSystemCheckoutNumber,
  discardCheckoutChanges,
  saveCheckoutMapping,
  trackChanges,
} from "../../../../api_calls/infrastructure";
import Confirmation from "../../ModalContent/Confirmation";
import * as xlsx from "xlsx";
import moment from "moment-timezone";
import SingleSelect from "../../../../components/commonComponents/Selectors/SingleSelect";
import MultiSelect from "../../../../components/commonComponents/Selectors/MultiSelect";

const CheckoutMapping = () => {
  const userStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const checkoutReaderCount =
    typeof userStateData.db_device_info !== "undefined" &&
    typeof userStateData.db_device_info.checkout_readers !== "undefined" &&
    userStateData.db_device_info.checkout_readers.length !== 0
      ? userStateData.db_device_info.checkout_readers.length
      : 0;
  const checkoutLayoutCount =
    typeof userStateData.db_checkout_count_info !== "undefined" ? userStateData.db_checkout_count_info : 0;
  const selectedCheckoutNums =
    typeof userStateData.selected_checkout_nums !== "undefined" ? userStateData.selected_checkout_nums : [];
  const { id } = useParams();
  const trackInfraChanges =
    typeof userStateData.track_infra_changes !== "undefined" ? userStateData.track_infra_changes : false;
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showCounterConfirmationText, setShowCounterConfirmationText] = React.useState(false);

  const [showCancelConfirmation, setShowCancelConfirmation] = React.useState(false);
  const assignmentSectionStatusFlags = userStateData?.db_device_info?.checkout_readers.map((readerInfo) => {
    return readerInfo.active;
  });

  const handleMultiplexerAssignment = (multiplexerValue, index, checkoutReaderId) => {
    const assignedMultiplexers =
      typeof userStateData.assigned_multiplexer !== "undefined" ? userStateData.assigned_multiplexer : {};
    let checkoutMappingInfo = typeof userStateData.checkout_mapping !== "undefined" ? userStateData.checkout_mapping : {};
    const updatedAssignedMultiplexer = {
      ...assignedMultiplexers,
      [index]: multiplexerValue.length !== 0 ? multiplexerValue.sort() : ["No Multiplexer"],
    };

    dispatchAction(
      updateStateData({
        assigned_multiplexer: updatedAssignedMultiplexer,
      })
    );
    trackChanges(true);
    // define multiplexer ports based on selection
    const addMappingDevicePorts = addMappingEntries();
    // reset checkout mapping entry for the specific checkout reader id and selected checkout nums
    if (
      addMappingDevicePorts.success === true &&
      Object.keys(checkoutMappingInfo).length !== 0 &&
      typeof checkoutMappingInfo[checkoutReaderId] !== "undefined"
    ) {
      const mappingEntries = addMappingDevicePorts.mappingEntries;
      let newCheckoutMapping = { ...checkoutMappingInfo };
      mappingEntries.forEach((portDetail) => {
        if (portDetail.checkout_reader_id === checkoutReaderId) {
          let devices = {};
          portDetail.device_ports.forEach((portName) => {
            if (
              typeof newCheckoutMapping[checkoutReaderId].devices[portName.replace(": ", "_").replace(" ", "_")] !==
                "undefined" &&
              !updatedAssignedMultiplexer["checkout_" + checkoutReaderId].includes(portName)
            ) {
              devices = {
                ...devices,
                [portName.replace(": ", "_").replace(" ", "_")]:
                  newCheckoutMapping[checkoutReaderId].devices[portName.replace(": ", "_").replace(" ", "_")],
              };
            } else {
              devices = {
                ...devices,
                [portName.replace(": ", "_").replace(" ", "_")]: {
                  device_port: portName,
                  instore_co_number: 99,
                },
              };
            }
          });
          newCheckoutMapping[checkoutReaderId] = {
            ...newCheckoutMapping[checkoutReaderId],
            devices: devices,
            multiplexer: updatedAssignedMultiplexer["checkout_" + checkoutReaderId],
          };
        }
      });

      // const { [checkoutReaderId]: _, ...updatedCheckoutMappingInfo } = checkoutMappingInfo;
      dispatchAction(
        updateStateData({
          checkout_mapping: newCheckoutMapping,
        })
      );
      updateSelectedCheckoutNums(newCheckoutMapping);
    }
  };

  const updateSelectedCheckoutNums = (mappingRecords) => {
    const checkoutMappingInfo = typeof mappingRecords !== "undefined" ? mappingRecords : {};
    let selectedCheckoutNums = [];
    if (Object.keys(checkoutMappingInfo).length !== 0) {
      Object.values(checkoutMappingInfo).forEach((mappingInfo) => {
        if (typeof mappingInfo.devices !== "undefined") {
          Object.values(mappingInfo.devices).forEach((portInfo) => {
            selectedCheckoutNums.push(portInfo.instore_co_number);
          });
        }
      });
    } else {
      selectedCheckoutNums = [];
    }

    dispatchAction(
      updateStateData({
        selected_checkout_nums: [...selectedCheckoutNums],
      })
    );
  };

  const handleMappingFieldChange = (mappingInfo, checkoutReaderId) => {
    const checkoutMappingInfo = typeof userStateData.checkout_mapping !== "undefined" ? userStateData.checkout_mapping : {};
    const newVal =
      typeof checkoutMappingInfo[checkoutReaderId] !== "undefined" &&
      typeof checkoutMappingInfo[checkoutReaderId].devices !== "undefined" &&
      typeof checkoutMappingInfo[checkoutReaderId].devices[mappingInfo.device_port.replace(": ", "_").replace(" ", "_")] !==
        "undefined"
        ? {
            ...checkoutMappingInfo[checkoutReaderId][mappingInfo.device_port.replace(": ", "_").replace(" ", "_")],
            ...mappingInfo,
          }
        : mappingInfo;

    const valueToUpdate = {
      ...checkoutMappingInfo,
      [checkoutReaderId]: {
        ...checkoutMappingInfo[checkoutReaderId],
        checkout_reader_id: checkoutReaderId,
        devices: {
          ...checkoutMappingInfo[checkoutReaderId]?.devices,
          [newVal.device_port.replace(": ", "_").replace(" ", "_")]: newVal,
        },
      },
    };
    dispatchAction(
      updateStateData({
        checkout_mapping: valueToUpdate,
      })
    );

    trackChanges(true);
    //
    updateSelectedCheckoutNums(valueToUpdate);
  };

  const saveCheckoutMappingInfo = () => {
    // saveCheckoutMapping(id);
    const checkCounterStatus = checkCounterTextToShow();
    if (checkCounterStatus === true) {
      setShowCounterConfirmationText(true);
    } else {
      setShowCounterConfirmationText(false);
    }
    setShowConfirmation(true);
  };

  const cancelModifications = (e) => {
    e.preventDefault();
    if (trackInfraChanges === true) {
      setShowCancelConfirmation(true);
    }
  };

  const exportToExcel = (checkoutMappingObj) => {
    const storeName = typeof userStateData.store_overview !== "undefined" ? userStateData.store_overview?.name : "";
    // Convert JSON data to an array of arrays
    const data = [];

    data.push(["Device Port", "In-Store Checkout Number"]); // Add headers

    Object.values(checkoutMappingObj).forEach((mappingInfo) => {
      const row = [];
      userStateData.db_device_info.checkout_readers.forEach((checkoutReader) => {
        if (checkoutReader.id === mappingInfo.checkout_reader_id) {
          row.push("Checkout Mapping: " + checkoutReader.ip_address, "");
          data.push(row);
        }
      });

      Object.keys(mappingInfo.devices).forEach((portInfo) => {
        const antRow = [];
        antRow.push(
          checkoutMappingObj[mappingInfo.checkout_reader_id].devices[portInfo].device_port,
          checkoutMappingObj[mappingInfo.checkout_reader_id].devices[portInfo].instore_co_number
        );
        data.push(antRow);
      });
    });

    // Create a new workbook and add a worksheet
    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.aoa_to_sheet(data);

    // Add the worksheet to the workbook
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    let exportFileName = moment().startOf("day").format("YYYYMMDD") + "_" + id + "_" + storeName + "_CheckoutMapping.xlsx";

    // Save the workbook to an Excel file
    xlsx.writeFile(workbook, exportFileName);
  };

  React.useEffect(() => {
    if (typeof userStateData.checkout_mapping !== "undefined") {
      updateSelectedCheckoutNums(userStateData.checkout_mapping);
    }
  }, []);

  return (
    <>
      {/* ------- info text --------- */}
      {checkoutReaderCount === 0 && (
        <StyledInformationSpan sx={{ fontSize: "15px" }}>
          Please add at least one checkout reader for this store location to proceed with creating a checkout mapping.
        </StyledInformationSpan>
      )}
      {checkoutReaderCount !== 0 && checkoutLayoutCount === 0 && (
        <StyledInformationSpan sx={{ fontSize: "15px" }}>
          Please add a checkout layout for this store location to proceed with creating a checkout mapping.
        </StyledInformationSpan>
      )}

      {checkoutReaderCount !== 0 && checkoutLayoutCount !== 0 && (
        <>
          <h3>Multiplexer Assignment</h3>
          <Box height={5} />
          {userStateData.db_device_info.checkout_readers.map((checkoutReader, index) => {
            return (
              <Box>
                <StyledLeftAlignedStack direction={"row"} spacing={4}>
                  <Box width={assignmentSectionStatusFlags.includes(false) ? 350 : 300}>
                    <StyledBlackInformationTypography sx={{ marginLeft: "0px" }}>
                      {checkoutReader.active === true ? (
                        <div style={{ marginTop: "15px" }}>Checkout Reader ({checkoutReader.ip_address}) Port:</div>
                      ) : (
                        <div style={{ marginTop: "15px" }}>
                          Checkout Reader ({checkoutReader.ip_address} - Inactive) Port:
                        </div>
                      )}
                    </StyledBlackInformationTypography>
                  </Box>
                  <Box width={260}>
                    <MultiSelect
                      enableFullWidth={true}
                      elemId={"multiplexer_dropdown_" + index}
                      label={"Multiplexer"}
                      showSelectedValues={true}
                      options={["ANT1", "ANT2", "ANT3", "ANT4"].map((multiplexerVal) => {
                        return { value: multiplexerVal, label: multiplexerVal };
                      })}
                      defaultVal={
                        typeof userStateData.assigned_multiplexer !== "undefined" &&
                        typeof userStateData.assigned_multiplexer["checkout_" + checkoutReader.id] !== "undefined"
                          ? userStateData.assigned_multiplexer["checkout_" + checkoutReader.id]
                              .filter((multiplexer) => {
                                return multiplexer !== "No Multiplexer";
                              })
                              .map((multiplexerVal) => {
                                return { value: multiplexerVal, label: multiplexerVal };
                              })
                          : ["No Multiplexer"].map((multiplexerVal) => {
                              return { value: multiplexerVal, label: multiplexerVal };
                            })
                      }
                      selectionCheck={(option, value) => option.label === value.label}
                      usedFor="filter"
                      onChangeCall={(selectedOption) => {
                        let userChoices = [];
                        selectedOption.forEach((chosen) => {
                          if (chosen.label !== "(All)") {
                            userChoices.push(chosen.label);
                          } else {
                            userChoices.push(["ANT1", "ANT2", "ANT3", "ANT4"]);
                          }
                        });
                        handleMultiplexerAssignment(userChoices, "checkout_" + checkoutReader.id, checkoutReader.id);
                      }}
                    />
                  </Box>
                </StyledLeftAlignedStack>
                <Box height={5} />
              </Box>
            );
          })}

          {/* <Box width={200}>
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                addMappingEntries();
              }}
              label={"Add"}
              icon={<Add />}
            />
          </Box> */}
        </>
      )}
      <Box height={30} />
      {/* --------- multiplexer device port tables ------------ */}
      {typeof userStateData.multiplexer_device_ports !== "undefined" &&
        userStateData.multiplexer_device_ports.length !== 0 &&
        userStateData.multiplexer_device_ports.map((multiplexerDevices, index) => {
          return (
            <>
              <StyledBlackInformationTypography variant="body3" sx={{ marginLeft: "0px" }}>
                {" "}
                Checkout Mapping: {multiplexerDevices.checkout_reader_ip}
                {userStateData.db_device_info.checkout_readers.map((dbCheckoutReader) => {
                  return (
                    <>
                      {/* append reader status */}
                      {dbCheckoutReader.id === multiplexerDevices.checkout_reader_id &&
                        dbCheckoutReader.active === false &&
                        " (Inactive)"}
                    </>
                  );
                })}
              </StyledBlackInformationTypography>
              <Box height={10} />
              <TableContainer sx={{ maxWidth: "800px" }} component={Paper} key={index + "_multiplexer_table"}>
                <Table sx={{ maxWidth: "800px" }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <SmallWidthStyledTableCell align="left" className="text-wrapper" sx={{ maxWidth: "80px" }}>
                        Device Port
                      </SmallWidthStyledTableCell>
                      <SmallWidthStyledTableCell align="left" className="text-wrapper" sx={{ maxWidth: "80px" }}>
                        <Stack spacing={2} direction={"row"}>
                          <div>In-Store Checkout Number</div>
                          <HtmlTooltip
                            title={
                              <Box width={220}>
                                <StyledMainBox>
                                  <Typography variant="body2">
                                    Refers to the checkout number which is visible at the checkout counter inside the store
                                  </Typography>
                                </StyledMainBox>
                              </Box>
                            }
                          >
                            <Info sx={{ fontSize: "18px" }} />
                          </HtmlTooltip>
                        </Stack>
                      </SmallWidthStyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {typeof multiplexerDevices.device_ports !== "undefined" &&
                      multiplexerDevices.device_ports.map((multiplexerDeviceVal, device_index) => {
                        return (
                          <TableRow key={device_index + "_device_row"}>
                            {/* ---------- Device Port ----------- */}
                            <SmallWidthStyledTableCell
                              component="td"
                              scope="row"
                              className="text-wrapper"
                              sx={{ maxWidth: "20px" }}
                            >
                              {multiplexerDeviceVal}
                            </SmallWidthStyledTableCell>
                            {/* ---------- Instore Checkout Number------------- */}
                            <LeftStyledTableCell
                              component="td"
                              scope="row"
                              className="text-wrapper"
                              // sx={{ minWidth: "80px" }}
                            >
                              {typeof userStateData.assigned_multiplexer !== "undefined" &&
                              typeof userStateData.assigned_multiplexer[
                                "checkout_" + multiplexerDevices.checkout_reader_id
                              ] !== "undefined" &&
                              userStateData.assigned_multiplexer[
                                "checkout_" + multiplexerDevices.checkout_reader_id
                              ].includes(multiplexerDeviceVal) ? (
                                <SingleSelect
                                  enableFullWidth={true}
                                  label="In-Store Checkout Number"
                                  elemId={"instore_checkout_num_for_" + multiplexerDeviceVal}
                                  readOnlyFlag={true}
                                  options={
                                    typeof userStateData.db_checkout_layout_list !== "undefined"
                                      ? [
                                          "Multiplexer",
                                          ...userStateData.db_checkout_layout_list
                                            .filter((coObj) => {
                                              return coObj.cap_enabled === true &&
                                                coObj.active === true &&
                                                !selectedCheckoutNums.includes(coObj.instore_co_number)
                                                ? true
                                                : false;
                                            })
                                            .map((coNumber) => {
                                              return {
                                                label: coNumber.instore_co_number,
                                                value: coNumber.instore_co_number,
                                              };
                                            }),
                                        ]
                                      : []
                                  }
                                  defaultVal={{ label: "Multiplexer", value: "Multiplexer" }}
                                  selectionCheck={(option, value) => option.value === value.value}
                                  onChangeCall={(selectedOption) => {
                                    handleMappingFieldChange(
                                      {
                                        device_port: multiplexerDeviceVal,
                                        instore_co_number: selectedOption.value,
                                      },
                                      multiplexerDevices.checkout_reader_id
                                    );
                                  }}
                                />
                              ) : (
                                <SingleSelect
                                  enableFullWidth={true}
                                  label="In-Store Checkout Number"
                                  elemId={
                                    "instore_checkout_num_for_" + multiplexerDeviceVal.replace(": ", "_").replace(" ", "_")
                                  }
                                  readOnlyFlag={false}
                                  options={
                                    typeof userStateData.db_checkout_layout_list !== "undefined"
                                      ? [
                                          {
                                            label: "None",
                                            value: "None",
                                          },
                                          ...userStateData.db_checkout_layout_list
                                            .filter((coObj) => {
                                              return coObj.cap_enabled === true &&
                                                coObj.active === true &&
                                                !selectedCheckoutNums.includes(coObj.instore_co_number)
                                                ? true
                                                : false;
                                            })
                                            .map((coNumber) => {
                                              return {
                                                label: coNumber.instore_co_number,
                                                value: coNumber.instore_co_number,
                                              };
                                            }),
                                        ]
                                      : []
                                  }
                                  defaultVal={
                                    typeof userStateData.checkout_mapping !== "undefined" &&
                                    typeof userStateData.checkout_mapping[multiplexerDevices.checkout_reader_id] !==
                                      "undefined" &&
                                    typeof typeof userStateData.checkout_mapping[multiplexerDevices.checkout_reader_id]
                                      .devices !== "undefined" &&
                                    typeof userStateData.checkout_mapping[multiplexerDevices.checkout_reader_id].devices[
                                      multiplexerDeviceVal.replace(": ", "_").replace(" ", "_")
                                    ] !== "undefined" &&
                                    typeof userStateData.checkout_mapping[multiplexerDevices.checkout_reader_id].devices[
                                      multiplexerDeviceVal.replace(": ", "_").replace(" ", "_")
                                    ].instore_co_number !== "undefined"
                                      ? userStateData.checkout_mapping[multiplexerDevices.checkout_reader_id].devices[
                                          multiplexerDeviceVal.replace(": ", "_").replace(" ", "_")
                                        ].instore_co_number === 99
                                        ? {
                                            label: "None",
                                            value: "None",
                                          }
                                        : {
                                            label:
                                              userStateData.checkout_mapping[multiplexerDevices.checkout_reader_id].devices[
                                                multiplexerDeviceVal.replace(": ", "_").replace(" ", "_")
                                              ].instore_co_number,
                                            value:
                                              userStateData.checkout_mapping[multiplexerDevices.checkout_reader_id].devices[
                                                multiplexerDeviceVal.replace(": ", "_").replace(" ", "_")
                                              ].instore_co_number,
                                          }
                                      : {
                                          label: "None",
                                          value: "None",
                                        }
                                  }
                                  selectionCheck={(option, value) => option.value === value.value}
                                  onChangeCall={(selectedOption) => {
                                    handleMappingFieldChange(
                                      {
                                        device_port: multiplexerDeviceVal,
                                        instore_co_number: selectedOption.value,
                                      },
                                      multiplexerDevices.checkout_reader_id
                                    );
                                  }}
                                />
                              )}
                            </LeftStyledTableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box height={20} />
            </>
          );
        })}

      {/* ---------action buttons ---------- */}
      {typeof userStateData.multiplexer_device_ports !== "undefined" &&
        userStateData.multiplexer_device_ports.length !== 0 && (
          <Box width={800} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box>
              <CloseButton onClick={cancelModifications} label="Cancel" icon={<Close />} />
            </Box>
            <Box display="flex" alignItems="center">
              {typeof userStateData.db_checkout_mapping !== "undefined" && (
                <DarkGreyButton
                  onClick={(e) => {
                    e.preventDefault();
                    exportToExcel(userStateData.db_checkout_mapping);
                  }}
                  label="Download XLSX"
                  icon={<Download />}
                />
              )}

              <Box ml={2}>
                {/* Add margin between the buttons */}
                <ActionButton
                  onClick={(e) => {
                    e.preventDefault();
                    saveCheckoutMappingInfo();
                  }}
                  label="Save"
                  icon={<Save />}
                />
              </Box>
            </Box>
          </Box>
        )}
      {/* ------- confirmation popup (on click of save button) -------- */}
      {showConfirmation && (
        <Confirmation
          showConfirmationPopup={showConfirmation}
          closePopup={(e) => {
            setShowConfirmation(false);
          }}
          confirmationActionCall={() => {
            saveCheckoutMapping(id);
          }}
          confirmationMessage={
            showCounterConfirmationText === true
              ? "Not all C.A.P. Enabled checkouts have been assigned to a device port. To ensure the system functions correctly, all C.A.P. checkouts should be assigned to a device port. Your changes are still saved."
              : "Are you sure you want to confirm and apply all the changes you have made?"
          }
        />
      )}
      {/* cancel confirmation  */}
      {showCancelConfirmation && (
        <Confirmation
          showConfirmationPopup={showCancelConfirmation}
          closePopup={(e) => {
            setShowCancelConfirmation(false);
          }}
          confirmationActionCall={() => {
            trackChanges(false);
            discardCheckoutChanges(id);
          }}
          confirmationMessage="Are you certain you want to discard all the changes you have made?"
        />
      )}
    </>
  );
};

export default CheckoutMapping;
