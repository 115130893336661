import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { CustomTextInput } from "../TextInputs/style";
import { HtmlTooltip, StyledCheckbox, StyledMenuItemContainer, StyledMenuListItemText } from "../StyledComponents/styled";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Chip, Typography } from "@mui/material";
import { erase_duplicates } from "../../../api_calls/utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function GroupedMultiSelect({
  label,
  defaultVal,
  elemId,
  options,
  onChangeCall,
  fieldError,
  enableFullWidth,
  selectionCheck,
  usedFor,
  onBlurCall,
  showSelectedValues,
  readOnlyFlag,
  groupParam,
  disabledIfSelectedOption,
  showGroupNames
}) {
  const [selectedValues, setSelectedValues] = React.useState(defaultVal);
  // Use useEffect to update selectedValue when defaultVal changes
  React.useEffect(() => {
    setSelectedValues(defaultVal);
  }, [defaultVal]);

  return (
    <Autocomplete
      id={elemId}
      size="small"
      autoFocus={false}
      multiple
      limitTags={3}
      disableCloseOnSelect
      fullWidth={enableFullWidth}
      disabled={typeof readOnlyFlag !== "undefined" ? readOnlyFlag : false}
      componentsProps={{ paper: { sx: { wordBreak: "break-word" } } }}
      sx={enableFullWidth ? { maxWidth: "100%" } : { maxWidth: "220px", width: "100%" }}
      options={erase_duplicates(options)}
      filterOptions={(options, params) => {
        const filter = createFilterOptions();
        const filtered = filter(options, params);
        return [
          // { label: "(All)", all: true },
          ...filtered.sort((a, b) => {
            return a[groupParam].localeCompare(b[groupParam]);
          }),
        ];
      }}
      value={selectedValues}
      getOptionLabel={(option) => option?.label || ""}
      isOptionEqualToValue={(option, value) => selectionCheck(option, value)}
      onChange={(e, selectedOptions) => {
        const hasSelectAll = selectedOptions.some((option) => option.all);
        // If "Select All" is selected, set all options except "Select All"
        if (hasSelectAll) {
          if (selectedValues.length === options.length) {
            setSelectedValues([]);
            onChangeCall([]);
          } else {
            setSelectedValues(options.filter((option) => !option.all));
            onChangeCall(options.filter((option) => !option.all));
          }
        } else {
          setSelectedValues(selectedOptions);
          onChangeCall(selectedOptions);
        }
      }}
      onBlur={onBlurCall}
      groupBy={showGroupNames === true ? (option) => option[groupParam] : ""}
      renderGroup={(params) => (
        <li key={params.key}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold", padding: "5px 10px", backgroundColor: "#f5f5f5" }}>
            {params.group}
          </Typography>
          <ul style={{ padding: 0 }}>{params.children}</ul>
        </li>
      )}
      getOptionDisabled={(option) => {
        // Check if selectedValues contain any value outside the disabled list
        const hasOtherSelected = selectedValues?.some(
          (selectedOption) => !disabledIfSelectedOption?.some((disabledOption) => disabledOption === selectedOption.label)
        );

        // if selected values has an option defined in "disabledIfSelectedOption" then all the options in the dropdown will be disabled except the one available in "disabledIfSelectedOption"
        const isDisabled =
          hasOtherSelected && disabledIfSelectedOption.includes(option.label)
            ? true
            : disabledIfSelectedOption?.some((disabledOption) =>
              selectedValues.some(
                (selectedOption) => selectedOption.label === disabledOption && option.label !== disabledOption
              )
            );

        return isDisabled;
      }}
      renderOption={(props, option, { selected }) => {
        return props["aria-disabled"] ? (
          <HtmlTooltip title="test">
            <li {...props}>
              <StyledCheckbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={option.all ? !!(selectedValues.length === options.length) : selected}
              />
              <Typography variant="body2" className="selectorOptions">
                {option.label}
              </Typography>
            </li>
          </HtmlTooltip>
        ) : (
          <li {...props}>
            <StyledCheckbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={option.all ? !!(selectedValues.length === options.length) : selected}
            />
            <Typography variant="body2" className="selectorOptions">
              {option.label}
            </Typography>
          </li>
        );
      }}
      renderInput={(params) => (
        <CustomTextInput
          {...params}
          autoFocus={false}
          autoComplete="off"
          id={elemId + "_cutomTextForDropdown"}
          fullWidth={enableFullWidth}
          sx={enableFullWidth ? { maxWidth: "100%" } : { maxWidth: "220px", width: "100%" }}
          label={label}
          variant="standard"
          error={fieldError !== "" ? true : false}
          helperText={fieldError !== "" ? fieldError : ""}
        // value={getDisplayValue()}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={option.label}
            {...getTagProps({ index })}
            style={{ margin: "2px" }}
            // color="primary"
            variant="outlined"
          />
        ))
      }
    />
  );
}
