import React from "react";
import { Box, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import { filterRangeValue } from "../../../api_calls/TableauVisuals";
import StoreFilter from "../../../components/commonComponents/Filters/StoreFilter";
import DateRangeFilter from "../../../components/commonComponents/Filters/DateRangeFilter";
import { StickyTopRowStack, StyledDifferentColorSwitch } from "../../../components/commonComponents/StyledComponents/styled";
import { useDispatch, useSelector } from "react-redux";
import { dbFormatDate, formatToStandardDate, independentStoreListOptions, standardFormatToday, storeListOptionsForTableauFilter } from "../../../api_calls/utils";
import RankByFilter from "../../../components/commonComponents/Filters/RankByFilter";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";
import SingleSelect from "../../../components/commonComponents/Selectors/SingleSelect";
import { updateStateData } from "../../../Store/actions";
import EmbedDateFilter from "./EmbedDateFilter";
import TempSingleSelect from "../../../components/commonComponents/Selectors/TempSingleSelect";

const EmbedImpressionFilter = ({ vizId }) => {
    const storeDetails = useSelector((state) => state.userSelections);
    const [labelSelection, setLabelSelection] = React.useState(false);
    const dispatchAction = useDispatch();
    const handleChange = (event) => {
        setLabelSelection(event.target.checked);
        dispatchAction(updateStateData({
            embedded_filter: {
                ...storeDetails?.embedded_filter,
                "absolute_val": event.target.checked,
            }
        }))
    };

    let storeList = ["Los Leones", "Consistorial", "Pedro Fontova", "Los Trapenses", "Vicente Valdés"];

    const [storeNameOptionSelected, setStoreNameOptionsSelected] = React.useState({});

    const embedStartDate = new Date("2025-02-06");

    const embedEndDate = new Date("2025-02-10");

    return (
        <>
            <StickyTopRowStack sx={{ padding: 0 }} direction={"row"} spacing={4}>
                {/* ---------- Metrics Filter ---------- */}
                <Box sx={{ width: 90, marginTop: "10px" }}>
                    <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <StyledDifferentColorSwitch checked={labelSelection} onChange={handleChange} name="label_filter_selection" size="small" />
                                }
                                label={labelSelection ? "Percentage" : "Absolute"}
                                labelPlacement="bottom"
                                sx={{ minWidth: 60, fontSize: "0.875rem", fontWeight: 400, marginTop: "10px" }}
                                disableTypography={true}
                            />
                        </FormGroup>

                    </FormControl>
                </Box>

                {/* ------- stores filter ----------- */}
                <Box sx={{ width: 220 }}>
                    <TempSingleSelect
                        enableFullWidth={false}
                        label={"Store"}
                        options={[...new Set(storeList)].map((storeName) => {
                            return { value: storeName, label: storeName };
                        })}
                        defaultVal={storeNameOptionSelected}
                        selectionCheck={(option, value) => option.label === value.label}
                        usedFor="storeFilter"
                        onChangeCall={(selectedOption) => {
                            console.log(selectedOption)
                            if (selectedOption !== null) {
                                setStoreNameOptionsSelected(selectedOption);
                                dispatchAction(updateStateData({
                                    embedded_filter: {
                                        ...storeDetails?.embedded_filter,
                                        store: selectedOption.value
                                    }
                                }))
                            } else {
                                setStoreNameOptionsSelected({});
                                dispatchAction(updateStateData({
                                    embedded_filter: {
                                        ...storeDetails?.embedded_filter,
                                        store: ""
                                    }
                                }))
                            }

                        }}
                    />
                </Box>

                {/* ------------ day timestamp filter ------------ */}
                <Box sx={{ width: 220 }}>
                    <EmbedDateFilter
                        minSelectableDate={embedStartDate}
                        maxSelectableDate={embedEndDate}
                    />
                </Box>
            </StickyTopRowStack>
        </>
    );
};

export default EmbedImpressionFilter;
