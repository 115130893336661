import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import RetailAnalyticsFilter from "./Filters/RetailAnalyticsFilter";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkUserDetailsFromCookie, checkWorkSpaceFromCookies } from "../../api_calls/userManagement";
import { resetBrandReportView, storeListOptionsForTableauFilter, findStoreIds } from "../../api_calls/utils";
import { useSelector } from "react-redux";

const Optimization = () => {
  const userStateDate = useSelector((state) => state.userSelections);
  const [userInfo, setUserInfo] = React.useState({});
  React.useEffect(() => {
    const checkUserDetails = checkUserDetailsFromCookie();
    let userDetailObj = {};
    if (checkUserDetails.success === true) {
      userDetailObj = checkUserDetails.user_detail;
    }
    setUserInfo(userDetailObj);
    resetBrandReportView();
  }, []);
  return (
    <StyledMainBox component="main">
      {/* ------------- filters -------------- */}
      <RetailAnalyticsFilter singleStore={false} tabVizId={"optimizationViz"} usedFor="retail-analytics-journey" />
      <Box height={1} />
      {/* ----------- tableau dashboard ---------- */}
      <Grid container spacing={2}>
        {typeof userInfo.root_company_id !== "undefined" && typeof userStateDate.stores_list !== "undefined" && (
          <TableauDash
            vizId="optimizationViz"
            // vizUrl={
            //   "https://dub01.online.tableau.com/t/tableaucyreende/views/" + workSpaceId + "_Retail_Analytics/Optimization"
            // }
            vizUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
              userInfo.root_company_id +
              "_master_" +
              process.env.REACT_APP_ENV_NAME +
              "/Optimization"
            }
            applyFilter={true}
            filterConfig={{
              store: {
                name: "Id Store",
                values: findStoreIds(storeListOptionsForTableauFilter("retail")),
              },
            }}
          />
        )}
      </Grid>
    </StyledMainBox>
  );
};
// remove array if all user types are allowed. if only some are allowed, add them like below
export default AccessControl(Optimization, ["analytics_can_view"], ["cyreen", "branch", "store"], ["retail_analytics_section", "optimization"]);
