import React from "react";
import { Box, Tab } from "@mui/material";
import PropTypes from "prop-types";
import TableauDash from "../components/commonComponents/TableauDashboard/TableauDash";
import { useParams } from "react-router-dom";
import { StyledMainBox, StyledTabs } from "../components/commonComponents/StyledComponents/styled";
import TestPerform from "./TestPerform";
import TestingOne from "./TestingOne";
import TestingDash from "./TestingDash";
import TestingNew from "./TestingNew";
import TestDash from "./TestDash";
import LiveCheckoutTest from "./LiveCheckoutTest";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={5}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TestNew = () => {
  const tabsConfig = [
    {
      label: "Status Monitoring",
      icon: "",
      unique_key: "TestPerform",
      dashboard: <TestPerform />,
      filter_component: <></>,
    },
    {
      label: "Live Monitoring",
      icon: "",
      unique_key: "TestingOne",
      dashboard: <TestingOne refreshInterval={"5000"} />,
      filter_component: <></>,
    },
    {
      label: "Total Monitoring",
      icon: "",
      unique_key: "TestingDash",
      dashboard: <TestingDash />,
      filter_component: <></>,
    },
    {
      label: "Store Monitoring",
      icon: "",
      unique_key: "TestingDash",
      dashboard: <TestingNew />,
      filter_component: <></>,
    },
    {
      label: "Reciept Monitoring",
      icon: "",
      unique_key: "TestDash",
      dashboard: <TestDash />,
      filter_component: <></>,
    },
    {
      label: "Single Performance",
      icon: "",
      unique_key: "singlePerformViz",
      dashboard: (
        <Box>
          <TableauDash
            vizId="singlePerformViz"
            vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/76_campaign_qa/SinglePerformance"}
            applyFilter={false}
            // filterConfig={{
            //   id_campaign: {
            //     name: "Id Campaign",
            //     values: [id],
            //   },
            // }}
          />
        </Box>
      ),
      filter_component: <></>,
    },
    {
      label: "Targeting",
      icon: "",
      unique_key: "targetingViz",
      dashboard: (
        <Box>
          <TableauDash
            vizId="targetingViz"
            vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/76_campaign_qa/Targeting"}
            applyFilter={false}
            // filterConfig={{
            //   id_campaign: {
            //     name: "Id Campaign",
            //     values: [id],
            //   },
            // }}
          />
        </Box>
      ),
      filter_component: <></>,
    },
    {
      label: "Impressions",
      icon: "",
      unique_key: "impressionsViz",
      dashboard: (
        <Box>
          <TableauDash
            vizId="impressionsViz"
            vizUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/76_campaign_qa/Impressions/1768bb94-58c1-4df8-b2e5-e79125b09821/4ea08f29-5d00-4b30-bec4-25e84b329806%27"
            }
            applyFilter={false}
            // filterConfig={{
            //   id_campaign: {
            //     name: "Id Campaign",
            //     values: [id],
            //   },
            // }}
          />
        </Box>
      ),
      filter_component: <></>,
    },
    {
      label: "Campaign Monitoring",
      icon: "",
      unique_key: "campaignMonitorViz",
      dashboard: (
        <Box>
          <TableauDash
            vizId="campaignMonitorViz"
            vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/76_campaign_qa/CampaignMonitoring%27"}
            applyFilter={false}
            // filterConfig={{
            //   id_campaign: {
            //     name: "Id Campaign",
            //     values: [id],
            //   },
            // }}
          />
        </Box>
      ),
      filter_component: <></>,
    },
    {
      label: "Live Checkout",
      icon: "",
      unique_key: "LiveCheckoutTest",
      dashboard: <LiveCheckoutTest refreshInterval={"20000"} />,
      filter_component: <></>,
    },
  ];
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const { id } = useParams();
  return (
    <StyledMainBox component="main">
      <Box
        sx={{
          display: "flex", // Make the Box a flex container
          // borderBottom: 1,
          // borderColor: "divider",
          position: "sticky",
          top: "0",
          zIndex: 1,
          backgroundColor: "#fff",
        }}
      >
        <Box component={"div"}>
          <StyledTabs value={value} onChange={handleChange} aria-label="data-exchange" textColor={"#F04B55"}>
            {tabsConfig.map((tabDetail) => {
              return <Tab key={tabDetail.unique_key} icon={tabDetail.icon} label={tabDetail.label} {...a11yProps(0)} />;
            })}
          </StyledTabs>
        </Box>
      </Box>
      {/* ============== tab contents =========== */}
      {tabsConfig.map((tabDetail, index) => {
        return (
          <CustomTabPanel value={value} index={index}>
            {tabDetail.dashboard}
          </CustomTabPanel>
        );
      })}
      {/* ============== users listing ============ */}
      <Box height={30} />
    </StyledMainBox>
  );
};

export default TestNew;
