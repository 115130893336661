import React from "react";
import { Box } from "@mui/material";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import RetailAnalyticsFilter from "./Filters/RetailAnalyticsFilter";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkUserDetailsFromCookie, checkWorkSpaceFromCookies } from "../../api_calls/userManagement";
import { resetBrandReportView, storeListOptionsForTableauFilter, yesterdayDate, findStoreIds } from "../../api_calls/utils";
import { useSelector } from "react-redux";

const GeneralKpi = () => {
  const userStateDate = useSelector((state) => state.userSelections);
  const [userInfo, setUserInfo] = React.useState({});
  const startDate =
    typeof userStateDate.date_range_start_date !== "undefined"
      ? new Date(userStateDate.date_range_start_date)
      : new Date("2023-02-01");

  React.useEffect(() => {
    const checkUserDetails = checkUserDetailsFromCookie();
    let userDetailObj = {};
    if (checkUserDetails.success === true) {
      userDetailObj = checkUserDetails.user_detail;
    }
    setUserInfo(userDetailObj);
    resetBrandReportView();
  }, []);
  return (
    <StyledMainBox component="main">
      {/* ---------- filter section ------------- */}
      <RetailAnalyticsFilter tabVizId={"generalKpiViz"} />
      <Box height={1} />
      {/* ------------ dashboard from tableau ------------ */}
      {typeof userInfo.root_company_id !== "undefined" && typeof userStateDate.stores_list !== "undefined" && (
        <Box>
          <TableauDash
            vizId="generalKpiViz"
            vizUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
              userInfo.root_company_id +
              "_master_" +
              process.env.REACT_APP_ENV_NAME +
              "/GeneralDashboard"
            }
            applyFilter={true}
            filterConfig={{
              store: {
                name: "Id Store",
                values: findStoreIds(storeListOptionsForTableauFilter("retail")),
              },
              // date_range: {
              //   name: "DAY(Timestamp)",
              //   values: [startDate, yesterdayDate()],
              // },
            }}
          />
        </Box>
      )}
    </StyledMainBox>
  );
};
// remove array if all user types are allowed. if only some are allowed, add them like below
export default AccessControl(GeneralKpi, ["analytics_can_view"], ["cyreen", "branch", "store"], ["retail_analytics_section", "general_analytics"]);
