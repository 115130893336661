import React from "react";
import { Box, Stack, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import CloseButton from "./Buttons/CloseButton";
import ActionButton from "./Buttons/ActionButton";

const CommonConfirmation = ({
  confirmationActionCall,
  closePopup,
  showConfirmationPopup,
  confirmationMessage,
  confirmTitle,
}) => {
  const performAction = async (e) => {
    e.preventDefault();
    // check if user typed yes or not
    confirmationActionCall();
    closePopup();
  };

  return (
    <Dialog
      open={showConfirmationPopup}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>{typeof confirmTitle !== "undefined" ? confirmTitle : "Confirm Action"}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body2"> {confirmationMessage} </Typography>
        </Box>
      </DialogContent>
      <Box height={2} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              closePopup();
            }}
            label={"No"}
            icon={<Close />}
          />
          <ActionButton onClick={performAction} label={"Yes"} icon={<Check />} />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default CommonConfirmation;
