import React from "react";
import { changeParameter, filterSingleValue } from "../../../api_calls/TableauVisuals";
import SelectBox from "../Selectors/SelectBox";
import { loadStores } from "../../../api_calls/campaigns";
import { useDispatch, useSelector } from "react-redux";
import { dbFormatDate, formatDate, storeListOptionsForTableauFilter } from "../../../api_calls/utils";
import { updateStateData } from "../../../Store/actions";
import { checkUserRole } from "../../../api_calls/userManagement";
import MultiSelect from "../Selectors/MultiSelect";
import { filterClips } from "../../../api_calls/clipRequests";
import { loadClips, resetClipsData } from "../../../api_calls/clipRequests";
import { clip_names_in_range, loadCampaigns } from "../../../api_calls/campaignRequests";
import { clearfilters } from "../../../api_calls/campaignRequests";
import {
  erase_duplicates,
  formatToStandardDate,
  standardFormatToday,
  getFirstDayTwoMonthsAgo,
} from "../../../api_calls/utils";
import { Box, FormControl, FormControlLabel, FormLabel, Tooltip } from "@mui/material";
import { StyledSwitch } from "../StyledComponents/styled";

const ClipNameFilter = ({ vizId, fieldName, usedFor }) => {
  const clipDetails = useSelector((state) => state.userSelections);
  const [changesMade, setChangesMade] = React.useState(false);
  const [clipSelection, setClipSelection] = React.useState([]);
  const [clipNameOptionSelected, setClipNameOptionsSelected] = React.useState([]);

  const [checked, setChecked] = React.useState(false);
  // checked ? changeParameter("Clips", "Show", vizId) : changeParameter("Clips", "Hide", vizId);

  // for clips boolean switch
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked === true) {
      changeParameter("Clips", "Show", vizId);
      if (clipSelection.length !== 0) {
        filterSingleValue(fieldName, clipSelection, vizId);
      }
    } else {
      const allClipNames = clip_names_in_range(usedFor);
      changeParameter("Clips", "Hide", vizId);
      filterSingleValue(
        fieldName,
        allClipNames.map((clipInfo) => {
          return clipInfo.id;
        }),
        vizId
      );
    }
    // checked ? changeParameter("Clips", "Show", vizId) : changeParameter("Clips", "Hide", vizId);
  };

  const clipNameFilterJsx = () => {
    return (
      <MultiSelect
        enableFullWidth={false}
        label={"Clip Name"}
        readOnlyFlag={typeof usedFor !== "undefined" && usedFor === "clip-overview" ? (checked ? false : true) : false}
        options={clip_names_in_range(usedFor)}
        defaultVal={clipNameOptionSelected}
        selectionCheck={(option, value) => option.label === value.label}
        usedFor="clipNameFilter"
        onChangeCall={(selectedOption) => {
          let userChoices = [];
          setChangesMade(true);
          selectedOption.forEach((chosen) => {
            if (chosen.label !== "(All)") {
              userChoices.push(chosen.id);
            } else {
              userChoices.push([...new Set(clipDetails.clip_names_list)]);
            }
          });
          setClipSelection(userChoices);
          setClipNameOptionsSelected(selectedOption);
          // filterSingleValue(fieldName, userChoices, vizId);
        }}
        onBlurCall={(e) => {
          if (clipSelection.length !== 0 && changesMade === true) {
            filterSingleValue(fieldName, clipSelection, vizId);
            setChangesMade(false);
          }
        }}
      />
    );
  };

  React.useEffect(() => {
    const allClipNames = clip_names_in_range(usedFor);
    setClipNameOptionsSelected(allClipNames);
  }, []);

  return (
    <>
      {typeof usedFor !== "undefined" && usedFor === "clip-overview" ? (
        <>
          <Box sx={{ alignContent: "center" }}>
            <FormControl
              component="fieldset"
              variant="standard"
              sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
            >
              <FormLabel component="legend">Clips</FormLabel>
              <Tooltip title="Show/Hide Clip Name">
                <FormControlLabel
                  control={<StyledSwitch checked={checked} name="Metric Swap" onChange={handleChange} />}
                  labelPlacement="right"
                  label={checked ? "On" : "Off"}
                  sx={{ fontSize: "12px", minWidth: 100 }}
                />
              </Tooltip>
            </FormControl>
          </Box>
          <Box sx={{ width: 250, alignContent: "center" }}>{clipNameFilterJsx()}</Box>
        </>
      ) : (
        <>{clipNameFilterJsx()}</>
      )}
    </>
  );
};

export default ClipNameFilter;
