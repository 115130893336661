import React from "react";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import {
  CheckBox,
  Devices,
  Receipt,
  SignalWifiStatusbar4Bar,
  SpaceDashboard,
  Store,
  Stream,
  Visibility,
} from "@mui/icons-material";
import TableauDash from "../../../components/commonComponents/TableauDashboard/TableauDash";
import store from "../../../Store/store";
import { checkUserDetailsFromCookie, checkWorkSpaceFromCookies } from "../../../api_calls/userManagement";
import TotalMonitoringFilter from "./filters/TotalMonitoringFilter";
import StoreMonitoringFilter from "./filters/StoreMonitoringFilter";
import StatusMonitoringFilter from "./filters/StatusMonitoringFilter";
import GraffanaDash from "./GraffanaDash";
import GraffanaFilter from "./filters/GraffanaFilter";
import { storeListOptionsForTableauFilter, findStoreIds, independentStoreListOptions } from "../../../api_calls/utils";
import RecieptMonitoringFilter from "./filters/RecieptMonitoringFilter";
import { findMinSelectableDate } from "../../../api_calls/campaigns";

// to load tab content
function CustomTabPanel(props) {
  const { children, dasboardname, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={false}
      id={`simple-tabpanel-${dasboardname}`}
      aria-labelledby={`simple-tab-${dasboardname}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

// specify mandatory props that custom tab panel will accept
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  dasboardname: PropTypes.number.isRequired,
};

function dashboardMarkup(vizId, vizUrl, applyFilter, filterConfig) {
  return (
    <Grid container spacing={2}>
      <TableauDash vizId={vizId} vizUrl={vizUrl} applyFilter={applyFilter} filterConfig={filterConfig} />
    </Grid>
  );
}

function autoRefreshdashboardMarkup(vizId, vizUrl, applyFilter, filterConfig) {
  return (
    <Grid container spacing={2}>
      <TableauDash vizId={vizId} vizUrl={vizUrl} applyFilter={applyFilter} filterConfig={filterConfig} autoRefresh={true} />
    </Grid>
  );
}

// campaign analytics tabs detail >> label, icon, dashboard and filter
export const tabs = () => {
  const storeDetails = store.getState().userSelections;
  const checkUserDetails = checkUserDetailsFromCookie();
  let independentStoreList = independentStoreListOptions(); //multiple values
  let storeList = independentStoreListOptions(true); //single value
  let storeListMultiSelect = storeListOptionsForTableauFilter("monitoring"); //for reciept monitoring
  let liveMonitoringSelectedStore =
    typeof storeDetails.live_monitoring_selected_store !== "undefined" ? storeDetails.live_monitoring_selected_store : "";
  const StoreIds = typeof storeList !== "undefined" ? findStoreIds(storeList) : [];

  if (
    checkUserDetails.success === true &&
    typeof checkUserDetails.user_detail !== "undefined" &&
    typeof checkUserDetails.user_detail.root_company_id !== "undefined"
  ) {
    const dirName = checkUserDetails.user_detail.root_company_id + "_master_" + process.env.REACT_APP_ENV_NAME;
    return [
      {
        label: "Status Monitoring",
        icon: <CheckBox />,
        unique_key: "status_monitor",
        dashboard:
          typeof independentStoreList !== "undefined" && independentStoreList.length !== 0 ? (
            dashboardMarkup(
              "status_monitorDash",
              // "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "status_monitoring/StatusMonitoring",
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "/StatusMonitoring",
              true,
              {
                status_filter: {
                  name: "AGG(Status Heartbeat)",
                  values: ["(All)", "Online", "Reconnecting", "Offline", "Closed"],
                },

                "Id Store": {
                  name: "Id Store",
                  values: typeof independentStoreList !== "undefined" ? findStoreIds(independentStoreList) : [],
                },
              }
            )
          ) : (
            <></>
          ),
        filter_component:
          typeof independentStoreList !== "undefined" && independentStoreList.length !== 0 ? (
            <StatusMonitoringFilter vizId={"status_monitorDash"} />
          ) : (
            <></>
          ),
      },
      {
        label: "Live Monitoring",
        icon: <SpaceDashboard />,
        unique_key: "live_monitor",
        dashboard: (
          <GraffanaDash
            vizImpressionId={"live_impressions_monitorDash"}
            vizCheckoutId={"live_checkout_monitorDash"}
            vizImpressionUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "/Live_monitoring_Impressions"
            }
            vizCheckoutUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "/Live_monitoring_Checkout_events"
            }
            applyFilter={true}
            refreshInterval={60000}
            filterConfig={{
              "Id Store": {
                name: "Id Store",
                values:
                  liveMonitoringSelectedStore !== ""
                    ? findStoreIds([liveMonitoringSelectedStore])
                    : findStoreIds([storeList]),
              },
            }}
          />
        ),
        filter_component: (
          <GraffanaFilter vizImpressionId={"live_impressions_monitorDash"} vizCheckoutId={"live_checkout_monitorDash"} />
        ),
      },
      {
        label: "Total Monitoring",
        icon: <Visibility />,
        unique_key: "total_monitor",
        dashboard:
          typeof independentStoreList !== "undefined" && independentStoreList.length !== 0 ? (
            dashboardMarkup(
              "total_monitorDash",
              // "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "total_monitoring/TotalMonitoring",
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "/TotalMonitoring",
              true,
              {
                // store_filter: {
                //   name: "Store Name",
                //   values: storeList,
                // },
                "Id Store": {
                  name: "Id Store",
                  values: typeof independentStoreList !== "undefined" ? findStoreIds(independentStoreList) : [],
                },
              }
            )
          ) : (
            <></>
          ),
        filter_component:
          typeof independentStoreList !== "undefined" && independentStoreList.length !== 0 ? (
            <TotalMonitoringFilter vizId={"total_monitorDash"} />
          ) : (
            <></>
          ),
      },
      {
        label: "Store Monitoring",
        icon: <Store />,
        unique_key: "store_monitor",
        dashboard:
          StoreIds.length !== 0 ? (
            dashboardMarkup(
              "store_monitorDash",
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "/StoreMonitoring",
              true,
              {
                //   store_filter: {
                //     name: "Store Name",
                //     values: storeList[1],
                //   },
                // }
                "Id Store": {
                  name: "Id Store",
                  values: StoreIds.length !== 0 ? StoreIds[0] : "",
                },
              }
            )
          ) : (
            <></>
          ),
        filter_component: StoreIds.length !== 0 ? <StoreMonitoringFilter vizId={"store_monitorDash"} /> : <></>,
      },
      {
        label: "Receipt Monitoring",
        icon: <Receipt />,
        unique_key: "reciept_monitor",
        dashboard:
          typeof storeListMultiSelect !== "undefined" && storeListMultiSelect.length !== 0 ? (
            dashboardMarkup(
              "reciept_monitorDash",
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "/Receipts_monitoring",
              true,
              {
                status_filter: {
                  name: "Status",
                  values: ["Receipts available but not yet processed", "Receipts not yet received", "Receipts processed"],
                },
                "Id Store": {
                  name: "Id Store",
                  values: typeof storeListMultiSelect !== "undefined" ? findStoreIds(storeListMultiSelect) : [],
                },
              }
            )
          ) : (
            <></>
          ),
        filter_component:
          typeof storeListMultiSelect !== "undefined" && storeListMultiSelect.length !== 0 ? (
            <RecieptMonitoringFilter vizId={"reciept_monitorDash"} storeArr={storeListMultiSelect} />
          ) : (
            <></>
          ),
      },
    ];
  } else {
    return [];
  }
};
// export const tabs = [
//   {
//     label: "Total Monitoring",
//     icon: <Visibility />,
//     unique_key: "total_monitor",
//     dashboard: dashboardMarkup(
//       "total_monitorDash",
//       "https://dub01.online.tableau.com/t/tableaucyreende/views/TotalMonitoring/TotalMonitoring/5ba7b29e-a0e8-4bb6-ae07-fdd7401ed65d/c100a4e7-0c4b-4072-86df-66daa1fa3368"
//     ),
//     filter_component: <></>,
//   },
//   {
//     label: "Store Monitoring",
//     icon: <Store />,
//     unique_key: "store_monitor",
//     dashboard: dashboardMarkup(
//       "store_monitorDash",
//       "https://dub01.online.tableau.com/t/tableaucyreende/views/ImpressionsMonitoring/StoreMonitoring"
//     ),
//     filter_component: <></>,
//   },
//   {
//     label: "Status Monitoring",
//     icon: <CheckBox />,
//     unique_key: "status_monitor",
//     dashboard: dashboardMarkup(
//       "status_monitorDash",
//       "https://dub01.online.tableau.com/t/tableaucyreende/views/CAPMonitoringStores/StatusMonitoring"
//     ),
//     filter_component: <></>,
//   },
// ];
