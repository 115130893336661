import { toast } from "react-toastify";
import { deleteItems, updateStateData } from "../Store/actions";
import store from "../Store/store";
import { sendApiRequest } from "./requestConfig";

// to save the selected filters for brand report
export const saveBrandFilters = async (campaign_id) => {
  const userStateData = store.getState().userSelections;
  // call api
  const saveFilters = await sendApiRequest(
    "filter/brand-sharing/save",
    {
      ...userStateData.brand_filters,
      campaign: campaign_id,
    },
    "POST"
  );
  //   evaluate api response
  if (typeof saveFilters.success !== "undefined" && saveFilters.success === true) {
    // notify user that filters saved successfully
    toast("Report filters saved successfully", { type: "success" });
    store.dispatch(
      updateStateData({
        report_filter_saved: true,
      })
    );
    return {
      success: true,
    };
  } else {
    // error notification
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};

const defaultTableauFilters = () => {
  const userStateDetails = store.getState().userSelections;
  return {
    impression_filter: {
      stores:
        typeof userStateDetails.campaign_detail !== "undefined" &&
          userStateDetails.campaign_detail.campaign_stores.length !== 0
          ? [...userStateDetails.campaign_detail.campaign_stores].sort()
          : [],
    },
    performance_filter: {
      stores:
        typeof userStateDetails.campaign_detail !== "undefined" &&
          userStateDetails.campaign_detail.campaign_stores.length !== 0
          ? [...userStateDetails.campaign_detail.campaign_stores].sort()
          : [],
      analysis_group:
        typeof userStateDetails.adlevel_names !== "undefined" && userStateDetails.adlevel_names.length !== 0
          ? [
            // ...["(All)"],
            ...userStateDetails.adlevel_names.map((storeInfo) => {
              return storeInfo.label;
            }),
          ]
          : [],
      weeks:
        typeof userStateDetails.campaign_weeks !== "undefined"
          ? [
            // ...["(All)"],
            ...userStateDetails.campaign_weeks
              .map((option) => {
                return parseInt(option.value);
              })
              .sort((next, prev) => {
                return next - prev;
              }),
          ]
          : [],
    },
  };
};

// to fetch the brand report filters saved by retailer/admin
export const fetchBrandFilters = async (campaignid) => {
  // const userStateDetails = store.getState().userSelections;
  const fetchFilters = await sendApiRequest("filter/brand-sharing/" + campaignid, {}, "GET");
  //   evaluate api response
  if (typeof fetchFilters.success !== "undefined" && fetchFilters.success === true) {
    let filterSelections = {};
    // set default filter values if retailer hasn't selected any filter yet
    if (
      typeof fetchFilters.response.impression_filter === "undefined" &&
      typeof fetchFilters.response.performance_filter === "undefined"
    ) {
      filterSelections = defaultTableauFilters();
      // save selected filters to redux, so it will be autofilled in the brand report popup
      store.dispatch(
        updateStateData({
          brand_filters: { ...fetchFilters.response, ...filterSelections },
          retailer_filters: { ...fetchFilters.response, ...filterSelections },
        })
      );
    } else {
      // save selected filters to redux, so it will be autofilled in the brand report popup
      store.dispatch(
        updateStateData({
          brand_filters: fetchFilters.response,
          retailer_filters: defaultTableauFilters(),
        })
      );
    }

    return {
      success: true,
    };
  } else {
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
    };
  }
};
