import { Box, Typography } from "@mui/material";
import { StyledLinearProgress } from "../StyledComponents/styled";
import { checkStatus, checkDaysInformation, checkProgress, formatDate } from "../../../api_calls/utils";

const ProgressBar = ({ startDate, endDate }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15px" }}>
      <Box sx={{ width: "100%", mb: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
          <span>
            <Typography variant="body2" color="#AF3241" sx={{ fontSize: 12, fontWeight: "bold" }}>
              {checkStatus(startDate, endDate)}
            </Typography>
          </span>
          <span>
            <Typography variant="body2" sx={{ fontSize: 12, fontWeight: "bold" }}>
              {checkDaysInformation(startDate, endDate)}
            </Typography>
          </span>
        </Box>
        {/* --------- progress bar ---------- */}
        {/* sx={{
            backgroundImage: "repeating-linear-gradient(45deg, #d9534f, #d9534f 10px, #f8d7da 10px, #f8d7da 15px)",
          }} */}
        <StyledLinearProgress variant="determinate" color="inherit" value={checkProgress(startDate, endDate)} />
        <Box height={4} />
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
          <span>
            <Typography variant="body2" sx={{ fontSize: 12, fontWeight: "bold" }}>
              {formatDate(startDate)}
            </Typography>
          </span>
          <span>
            <Typography variant="body2" sx={{ fontSize: 12, fontWeight: "bold" }}>
              {formatDate(endDate)}
            </Typography>
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressBar;
