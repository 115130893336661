import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";

const BrandAnalytics = () => {
  return (
    <StyledMainBox component="main">
      <Box height={1} />
      {/* --------- tableau dashboard ----------- */}
      <Grid container spacing={2}>
        <TableauDash
          vizId="brandAnalyticsViz"
          vizUrl="https://dub01.online.tableau.com/t/tableaucyreende/views/CAPExplorerBrandAnalyticsV3/TargetingGeneral-v2"
        />
      </Grid>
    </StyledMainBox>
  );
};

export default AccessControl(BrandAnalytics, "retail_analytics", [], ["retail_analytics_section"]);
