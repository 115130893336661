import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import { StickyTopRowStack, StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import StoreFilter from "../../components/commonComponents/Filters/StoreFilter";
import DateRangeFilter from "../../components/commonComponents/Filters/DateRangeFilter";
// ,
import { checkWorkbookFilters, filterRangeValue } from "../../api_calls/TableauVisuals";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkUserDetailsFromCookie, checkWorkSpaceFromCookies } from "../../api_calls/userManagement";
import { findStoreIds } from "../../api_calls/utils";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import { useSelector } from "react-redux";
import {
  dbFormatDate,
  formatToStandardDate,
  resetBrandReportView,
  standardFormatToday,
  storeListOptionsForTableauFilter,
  yesterdayDate,
} from "../../api_calls/utils";

const Overview = () => {
  const [userInfo, setUserInfo] = React.useState({});
  const userStateDate = useSelector((state) => state.userSelections);
  const startDate =
    typeof userStateDate.date_range_start_date !== "undefined"
      ? new Date(userStateDate.date_range_start_date)
      : new Date("2023-02-07");

  const today = standardFormatToday();
  const yesterday = formatToStandardDate(today);
  yesterday.setDate(today.getDate() - 1);

  const endDate =
    typeof userStateDate.date_range_end_date !== "undefined"
      ? new Date(userStateDate.date_range_end_date)
      : dbFormatDate(yesterday);

  React.useEffect(() => {
    resetBrandReportView();
    const checkUserDetails = checkUserDetailsFromCookie();
    let userDetailObj = {};
    if (checkUserDetails.success === true) {
      userDetailObj = checkUserDetails.user_detail;
    }
    setUserInfo(userDetailObj);
    // if (checkWorkSpaces.success === true && checkWorkSpaces.workspace_options.length > 1) {
    //   setWorkSpaceId(checkWorkSpaces?.current_workspace?.id);
    // }
  }, []);

  return typeof userInfo.root_company_id !== "undefined" ? (
    <StyledMainBox component="main">
      {/* ------------- filters -------------- */}
      <StickyTopRowStack direction={"row"} spacing={4}>
        {/* ------- stores filter ----------- */}
        <Box sx={{ width: 250 }}>
          <StoreFilter usedFor={"overview"} fieldName="Id Store" vizId={"generalKpiViz"} />
        </Box>
        {/* ------------ day timestamp filter ------------ */}
        {typeof userStateDate.date_range_start_date !== "undefined" && (
          <Box sx={{ width: 250 }}>
            <DateRangeFilter
              fieldName={"DAY(Timestamp)"}
              minSelectableDate={startDate}
              maxSelectableDate={endDate}
              onValueChange={filterRangeValue}
              vizId={"generalKpiViz"}
            />
          </Box>
        )}
      </StickyTopRowStack>
      {/* -------- tableau dashboard -------- */}
      <Box height={1} />
      <Grid container spacing={2}>
        {/*
         **** update: 09-10-2024
         ** replace values for store filter with this code and it will
         ** start sending store ids to the tableau filter on page load:
         ***** findStoreIds(storeListOptionsForTableauFilter("overview")) ****** */}
        {typeof userStateDate.stores_list !== "undefined" && typeof userStateDate.date_range_start_date !== "undefined" && (
          <TableauDash
            vizId="generalKpiViz"
            // vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/" + workSpaceId + "_Overview/Overview"}
            // test new dash
            vizUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
              userInfo.root_company_id +
              "_master_" +
              process.env.REACT_APP_ENV_NAME +
              "/Overview"
            }
            applyFilter={true}
            filterConfig={{
              store: {
                name: "Id Store",
                values: findStoreIds(storeListOptionsForTableauFilter("overview")),
              },
              // date_range: {
              //   name: "DAY(Timestamp)",
              //   values: [dbFormatDate(startDate), yesterdayDate()],
              // },
            }}
          />
        )}
      </Grid>
    </StyledMainBox>
  ) : (
    <></>
  );
};

export default AccessControl(Overview, ["overview_can_view"], [], ["overview"]);
