import React from "react";
import { Box, Chip, FormControlLabel, FormGroup, IconButton, InputAdornment, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { CustomTextInput } from "../../../components/commonComponents/TextInputs/style";
import {
  StyledCheckbox,
  StyledDarkRedInformationTypography,
} from "../../../components/commonComponents/StyledComponents/styled";
import { Check, Clear, Close, Save } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";

const AssignPermission = ({ onClose, roleInfo }) => {
  const [searchVal, setSearchVal] = React.useState({ value: "" });
  const dispatchAction = useDispatch();
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const currentStateData = useSelector((state) => state.userSelections);

  const customerList = typeof currentStateData.users_list !== "undefined" ? currentStateData.users_list : [];
  const cyreenList = typeof currentStateData.cyreen_users !== "undefined" ? currentStateData.cyreen_users : [];
  let userOptions = [...customerList, ...cyreenList];

  const applyFilter = () => {
    if (userOptions.length !== 0) {
      return userOptions.filter((user) => {
        return (
          user.username.toLowerCase().includes(searchVal.value.toLowerCase().toString()) ||
          user.email.toLowerCase().toString().includes(searchVal.value.toLowerCase().toString())
        );
      });
    } else {
      return [];
    }
  };

  let userListitems = typeof searchVal.value !== "undefined" && searchVal.value !== "" ? applyFilter() : [];

  // Function to remove selected user when clicking the cross button
  const handleRemoveUser = (user) => {
    setSelectedUsers((prev) => prev.filter((u) => u.email !== user.email));
  };

  // Function to clear search input
  const handleClearSearch = () => {
    setSearchVal({ value: "" }); // Clear search input
  };

  return (
    <>
      <Box height={690}>
        {/* ======= manage list ============ */}
        {typeof roleInfo.manage !== "undefined" && roleInfo.manage.length !== 0 && (
          <>
            <StyledDarkRedInformationTypography variant="body2">Manage</StyledDarkRedInformationTypography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {roleInfo.manage.map((item, index) => (
                <li key={index} style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                  <Check sx={{ color: "#AF3241", marginRight: "5px" }} fontSize="small" />
                  <Typography variant="body2">{item}</Typography>
                </li>
              ))}
            </ul>
          </>
        )}
        {/* --------------- view list ------------- */}

        {typeof roleInfo.view !== "undefined" && roleInfo.view.length !== 0 && (
          <>
            <StyledDarkRedInformationTypography variant="body2">View</StyledDarkRedInformationTypography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {roleInfo.view.map((item, index) => (
                <li key={index} style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
                  <Check sx={{ color: "#AF3241", marginRight: "5px" }} fontSize="small" />
                  <Typography variant="body2">{item}</Typography>
                </li>
              ))}
            </ul>
          </>
        )}
      </Box>
      <Box
        sx={{ position: "fixed", bottom: 10 }}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mt="auto"
      >
        <Box display="flex" alignItems="center">
          <CloseButton onClick={onClose} label="Cancel" icon={<Close />} />
        </Box>
      </Box>
    </>
  );
};

export default AssignPermission;
