import React from "react";
import { filterSingleValue } from "../../../api_calls/TableauVisuals";
import { useSelector } from "react-redux";
import MultiSelect from "../Selectors/MultiSelect";
import { picture_names_in_range } from "../../../api_calls/campaignRequests";

const PictureNameFilter = ({ vizId, fieldName, usedFor }) => {
  const clipDetails = useSelector((state) => state.userSelections);
  const [changesMade, setChangesMade] = React.useState(false);
  const [clipSelection, setClipSelection] = React.useState([]);
  const [clipNameOptionSelected, setClipNameOptionsSelected] = React.useState([]);

  React.useEffect(() => {
    const allClipNames = picture_names_in_range(usedFor);
    setClipNameOptionsSelected(allClipNames);
  }, []);

  return (
    <MultiSelect
      enableFullWidth={false}
      label={"Picture Name"}
      options={picture_names_in_range()}
      defaultVal={clipNameOptionSelected}
      selectionCheck={(option, value) => option.label === value.label}
      usedFor="pictureNameFilter"
      onChangeCall={(selectedOption) => {
        let userChoices = [];
        setChangesMade(true);
        selectedOption.forEach((chosen) => {
          if (chosen.label !== "(All)") {
            userChoices.push(chosen.id);
          } else {
            userChoices.push([...new Set(clipDetails.pic_names_list)]);
          }
        });
        setClipSelection(userChoices);
        setClipNameOptionsSelected(selectedOption);
      }}
      onBlurCall={(e) => {
        if (clipSelection.length !== 0 && changesMade === true) {
          filterSingleValue(fieldName, clipSelection, vizId);
          setChangesMade(false);
        }
      }}
    />
  );
};

export default PictureNameFilter;
