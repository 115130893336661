import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, standardFormatToday } from "../../../api_calls/utils";
import { updateStateData } from "../../../Store/actions";

const epochDate = new Date("1970-01-01");

function fromNumberToDate(dateSerialNum) {
    return new Date(epochDate.getTime() + dateSerialNum * 24 * 60 * 60 * 1000);
}

const EmbedDateFilter = ({ minSelectableDate, maxSelectableDate }) => {
    const dispatchAction = useDispatch();
    const storeDetails = useSelector((state) => state.userSelections);
    const startDate =
        typeof minSelectableDate !== "undefined" && minSelectableDate !== ""
            ? new Date(minSelectableDate)
            : new Date("2022-10-01");

    // end date will always be (today - 1day)
    const today = standardFormatToday();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    // set end date
    const endDate =
        typeof maxSelectableDate !== "undefined" && maxSelectableDate !== "" ? new Date(maxSelectableDate) : yesterday;

    const dateToValue = (date) => {
        // console.log(date, Math.floor((date - epochDate) / (24 * 60 * 60 * 1000)))
        return Math.floor((date - epochDate) / (24 * 60 * 60 * 1000));
    };

    const [range, setRange] = useState([dateToValue(startDate), dateToValue(endDate)]);

    const handleChange = (event, newRange) => {
        setRange(newRange);

    };

    const handleCommittedChange = () => {
        setRange((prevRange) => {
            const startDate = fromNumberToDate(prevRange[0]);
            const endDate = fromNumberToDate(prevRange[1]);

            // Use the functional form of state update to ensure the latest state values
            dispatchAction(updateStateData({
                embedded_filter: {
                    ...storeDetails?.embedded_filter,
                    from: startDate,
                    to: endDate
                }
            }))
            return prevRange; // Return the same range to avoid unnecessary re-renders
        });

    };



    useEffect(() => {
        setRange([dateToValue(startDate), dateToValue(endDate)]);

    }, []);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 0, fontSize: "11px", color: "#AF3241" }}>
                <span>Period</span>
            </Box>

            <Slider
                getAriaLabel={() => "Date range"}
                value={range}
                min={dateToValue(startDate)}
                max={dateToValue(endDate)}
                step={1}
                onChange={handleChange}
                onChangeCommitted={handleCommittedChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(values) => {
                    const date = fromNumberToDate(values);
                    return formatDate(date);
                }}
                sx={{ color: "#AF3241", position: "sticky" }}
                size="small"
            />

            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
                <span>
                    <Stack direction={"row"} spacing={1}>
                        {/* <DateRange sx={{ fontSize: "15px" }} /> */}
                        <Typography variant="body2">{formatDate(fromNumberToDate(range[0]))}</Typography>
                    </Stack>

                </span>
                <span>
                    {/* .toISOString().split("T")[0] */}
                    <Stack direction={"row"} spacing={1}>
                        {/* <DateRange sx={{ fontSize: "15px" }} /> */}
                        <Typography variant="body2">{formatDate(fromNumberToDate(range[1]))}</Typography>
                    </Stack>

                </span>
            </Box>
        </>
    );
};

export default EmbedDateFilter;
