import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Tab, Typography } from "@mui/material";
import { StyledMainBox, StyledRolesDrawer, StyledTabs } from "../../../components/commonComponents/StyledComponents/styled";
import PropTypes from "prop-types";
import AssignUser from "./AssignUser";
import AssignPermission from "./PermissionList";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box mt={10}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RoleDetail = ({ showSection, closeSection, roleInfo }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let tabsConfig = [
    {
      label: "Permissions",
      icon: "",
      unique_key: "CustomersList",
      dashboard: <AssignPermission roleInfo={roleInfo} onClose={closeSection} />,
      filter_component: <></>,
    },
    {
      label: "Assign",
      icon: "",
      unique_key: "cyreenUsersList",
      dashboard: <AssignUser roleInfo={roleInfo} onClose={closeSection} />,
      filter_component: <></>,
    },
  ];

  const DrawerList = (
    <StyledMainBox sx={{ width: 350, overflowY: "hidden" }} role="presentation">
      <Box height={20} />
      <Typography variant="h6">
        <strong>{roleInfo.role_name}</strong>
      </Typography>
      <Box height={25} />
      <Box
        sx={{
          display: "flex", // Make the Box a flex container
          position: "sticky",
          top: "0",
          zIndex: 1,
          backgroundColor: "#fff",
          overflowY: "hidden"
        }}
      >
        <Box component={"div"}>
          <StyledTabs value={value} onChange={handleChange} aria-label="data-exchange" textColor={"#F04B55"} sx={{ position: "fixed" }}>
            {tabsConfig.map((tabDetail) => {
              return (
                <Tab
                  key={tabDetail.unique_key}
                  aria-label={"tab for " + tabDetail.label}
                  icon={tabDetail.icon}
                  label={tabDetail.label}
                  {...a11yProps(0)}
                />
              );
            })}
          </StyledTabs>
        </Box>
      </Box>
      {/* ============== tab contents =========== */}
      {tabsConfig.map((tabDetail, index) => {
        return (
          <CustomTabPanel value={value} index={index}>
            {tabDetail.dashboard}
          </CustomTabPanel>
        );
      })}
    </StyledMainBox>
  );

  return (
    <>
      {/* onClose={closeSection(false)} */}
      <StyledRolesDrawer
        variant="permanent"
        open={showSection}
        anchor="right"
        sx={{
          zIndex: "9999",
        }}
      >
        {DrawerList}
      </StyledRolesDrawer>
    </>
  );
};

export default RoleDetail;
