import * as React from "react";
import { AccountCircle, Help, Logout, MoreVert, Workspaces } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Typography,
  Popover,
  Button,
  Stack,
  styled,
  Link,
} from "@mui/material";
import { logout } from "../../../api_calls/requestConfig";
import ResetPassword from "./Modal/ResetPassword";
import WorkSpace from "../../../pages/Login/modalContent/WorkSpace";
import { checkUserDetailsFromCookie, checkWorkSpaceFromCookies } from "../../../api_calls/userManagement";
import {
  HtmlTooltipLightRed,
  HtmlTooltipLightWhite,
  StyledInformationSpan,
  StyledLightRedInformationTypography,
  StyledMainBox,
} from "../StyledComponents/styled";
import LogoRed from "../../../images/cyreen_vertical.png";

const LogoImage = styled("img")({
  maxWidth: "100%",
  height: "auto",
  paddingLeft: "0",
  paddingTop: "15px",
  paddingRight: "15px",
  paddingBottom: "15px",
});

export default function BottomNav({ open }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isWorkSpaceModalOpen, setWorkSpaceModal] = React.useState(false);
  const [showWorkSpaceIcon, setShowWorkSpaceIcon] = React.useState(false);
  const [workSpaceName, setWorkSpaceName] = React.useState("");
  const [userType, setUserType] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navIcons = () => {
    return (
      <>
        {/* ----------- about --------- */}
        <HtmlTooltipLightWhite
          title={
            <Box width={240}>
              <StyledMainBox>
                <Typography variant="h6" sx={{ color: "#F04B55" }}>
                  <strong>CAP Explorer</strong>
                </Typography>

                <Box height={10} />
                <Typography variant="p">Version: 3.0.7</Typography>
                <Box height={10} />
                <Typography variant="p">
                  <a href="mailto:support@cyreen.de?subject=CAP Explorer - Report a Problem">Report Problem</a>
                </Typography>
                {/* <Typography variant="p">Terms of Use</Typography> */}
                <Box height={15} />
                <LogoImage src={LogoRed} alt="Cyreen" width={"100"} />
                <Typography variant="p" sx={{ fontSize: "10px" }}>
                  Developed and maintained by:
                </Typography>
                <Box height={10} />
                <Typography variant="p" sx={{ fontSize: "10px" }}>
                  Cyreen GmbH
                </Typography>
                <Box height={2} />
                <Typography variant="p" sx={{ fontSize: "10px" }}>
                  Schaumainkai 87-91
                </Typography>
                <Box height={2} />
                <Typography variant="p" sx={{ fontSize: "10px" }}>
                  Frankfurt am Main
                </Typography>
                <Box height={2} />
                <Typography variant="p" sx={{ fontSize: "10px" }}>
                  Germany
                </Typography>
                <Box height={2} />
                <Typography variant="p" sx={{ fontSize: "10px" }}>
                  <a target="_blank" href="https://cyreen.de/">
                    www.cyreen.de
                  </a>
                </Typography>
                <Box height={12} />
                <Typography variant="p" sx={{ fontSize: "10px" }}>
                  CAP Explorer, Cyreen and Cyreen logos are copyright protected.
                </Typography>
              </StyledMainBox>
            </Box>
          }
        >
          <BottomNavigationAction
            onClick={(e) => {
              e.preventDefault();
            }}
            sx={{ color: "rgba(240, 75, 85, 0.89)", minWidth: "65px" }}
            label="User"
            icon={<Help />}
          />
        </HtmlTooltipLightWhite>
        {/* -------- logout ------- */}
        <HtmlTooltipLightRed title="Logout">
          <BottomNavigationAction
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
            sx={{ color: "rgba(240, 75, 85, 0.89)", minWidth: "65px" }}
            label="User"
            icon={<Logout />}
          />
        </HtmlTooltipLightRed>
        {/* ------- account settings --------- */}
        <HtmlTooltipLightRed title="Account Details">
          <BottomNavigationAction
            onClick={(e) => {
              e.preventDefault();
              openModal(true);
            }}
            sx={{ color: "rgba(240, 75, 85, 0.89)", minWidth: "65px" }}
            label="User"
            icon={<AccountCircle />}
          />
        </HtmlTooltipLightRed>

        {/* ------- work space change option ----------- */}
        {showWorkSpaceIcon && (
          <HtmlTooltipLightRed title="Switch Workspace">
            <BottomNavigationAction
              onClick={(e) => {
                e.preventDefault();
                setWorkSpaceModal(true);
              }}
              sx={{ color: "rgba(240, 75, 85, 0.89)", minWidth: "65px" }}
              label="WorkSpace"
              icon={<Workspaces />}
            />
          </HtmlTooltipLightRed>
        )}
      </>
    );
  };

  React.useEffect(() => {
    const checkWorkSpaces = checkWorkSpaceFromCookies();
    const checkUserDetails = checkUserDetailsFromCookie();
    if (checkWorkSpaces.success === true) {
      if (checkWorkSpaces.workspace_options.length > 1) {
        setShowWorkSpaceIcon(true);
      }
      setWorkSpaceName(checkWorkSpaces?.current_workspace?.name);
    }
    if (checkUserDetails.success === true) {
      setUserType(checkUserDetails.user_detail);
    }
  }, []);

  return (
    <>
      {/* ----------- bottom navigation ------------ */}
      <Paper sx={{ position: "fixed", width: open ? 260 : 63, bottom: 0, left: 0, right: 0 }} elevation={2}>
        {open && workSpaceName !== "" && (
          <StyledLightRedInformationTypography
            variant="body2"
            style={{ textAlign: "center", justifyContent: "center", marginLeft: "0px" }}
          >
            <StyledInformationSpan style={{ fontSize: "15px", overflowWrap: "anywhere" }}>
              {typeof userType.user_type !== "undefined" && userType.user_type === "cyreen"
                ? workSpaceName + " - Cyreen Access"
                : workSpaceName}
              {/* {workSpaceName} */}
            </StyledInformationSpan>
          </StyledLightRedInformationTypography>
        )}
        <Box height={6} />

        {open ? (
          <BottomNavigation sx={{ backgroundColor: "rgba(240, 75, 85, 0.09)" }}>
            {navIcons()}
          </BottomNavigation>
        ) : (
          <BottomNavigation sx={{ backgroundColor: "rgba(240, 75, 85, 0.09)" }}>
            <BottomNavigationAction
              aria-describedby={id}
              onClick={handleClick}
              sx={{ color: "rgba(240, 75, 85, 0.89)" }}
              label="Popover"
              icon={<MoreVert />}
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              sx={{ marginTop: "0.8em" }}
            >
              {/* -------- POPOVER CONTENT ------- */}
              {navIcons()}
            </Popover>
          </BottomNavigation>
        )}
      </Paper>
      {/* ============ modal popup for reset password ========== */}
      {isModalOpen && <ResetPassword open={isModalOpen} onClose={closeModal} size={"xs"} />}
      {/* -------- change workspace modal -------- */}
      {isWorkSpaceModalOpen === true && (
        <WorkSpace
          open={isWorkSpaceModalOpen}
          onClose={() => {
            setWorkSpaceModal(false);
          }}
          size={"xs"}
          usedFor={"update"}
        />
      )}
    </>
  );
}
